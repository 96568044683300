@import '../../assets/styles/variables';

.rowOneImage {
  right: 0;
  top: 10px;
  position: absolute;
  z-index: -1;
}

.header {
  h1 {
    font-weight: 400;
  }

  p {
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      font-weight: 600;
    }
  }
}

.requestForm {
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.hsContactUsPage {
  display: block;
  align-items: left;
  justify-content: center;
  height: 100%;

  .hsContactUsContainer {
    max-width: 680px;
    width: 680px;
    background: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 52px 75px 75px 75px;
    border-radius: 40px;
    position: relative;

    h1 {
      font-weight: 600;
      font-size: 40px;
      text-align: center;
      color: $text-dark-grey;
      padding: 0 29px;
      line-height: 48px;

      @media screen and (max-width: 768px) {
        padding: 0px;
      }

      span {
        display: block;

        + {
          span {
            margin-top: 40px;
          }
        }
      }
    }

    label {
      display: block;
    }

    select {
      display: block;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 13px 15px;
      border: 2px solid #ece7dc;
      color: #828282;
      margin-top: 16px;
      width: 100%;
    }

    button {
      float: right;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      line-height: 24px;

      &:first-of-type {
        margin-top: 10px;
      }

      + {
        p {
          margin-top: 24px;
        }
      }
    }
  }
}

.inputContainer {
  margin-top: 30px;
}

.submitButtonContainer {
  margin-top: 30px;
}

.successContainer {
  min-height: calc(100vh - 356px);

  h2 {
    margin-bottom: 40px;
    color: $text-dark-grey;
    font-weight: 600;
  }

  p {
    color: $text-dark-grey;
  }
}

.checkboxGrid {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
}
