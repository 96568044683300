@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

$actionCardColors: (
  skyBlue: $color-sky-blue-medium,
  calmLilac: $color-calm-lilac-medium,
  sunYellow: $color-sun-yellow-medium,
  zingyGreen: $color-zingy-green-medium,
  aquaGreen: $color-aqua-green-medium,
  warmOrange: $color-warm-orange-medium,
  torquoise: $color-turquoise-medium,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

.deepDiveContainer {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
  height: 500px;

  @include gammaSize {
    height: auto;
    padding-bottom: 40px;
  }

  @include alphaSize {
    padding: 20px !important;
  }

  .topSection {
    display: flex;
    justify-content: space-between;

    h5 {
      color: $text-dark-grey;
    }
    p {
      color: $text-dark-grey;
    }

    @include gammaSize {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

.focusSelectionGrid {
  text-align: center !important;
  margin-top: -5px !important;

  @include gammaSize {
    margin-top: 0 !important;
  }
}

.subLoad {
  margin-top: 10px !important;
}

.gridItem {
  margin-bottom: 10px !important;
}
