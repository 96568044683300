@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.chartSection {
  width: 100%;
  background: $white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 30px 50px;
  border-radius: 40px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }

  .questionsContainer {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 24px;

    @media screen and (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .questionItem {
    margin-left: 20px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  .timeFrames {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 45px;
    gap: 10px;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .timeFrameButton {
      border: 3px solid $color-aqua-green-dark;
      background-color: $white;
      border-radius: 30px;
      padding: 5px 20px;
      width: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.timeFrameButtonSelected {
        background-color: $color-aqua-green-dark;
      }

      .btnText {
        color: #069b7b;
        font-size: 12px;
        margin: 0;
        font-family: 'Titillium Web', sans-serif;

        &.selectedText {
          color: $white;
        }
      }
    }
  }
}
