@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.scoresContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 30px;

  @include gammaSize {
    flex-direction: column;
  }

  .scoreCard {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    width: 100%;

    @include gammaSize {
      padding: 10px;
    }

    .scoreCardHeader {
      display: flex;
      flex-direction: row;
      gap: 10px;

      h6 {
        font-weight: 600;
        color: $text-dark-grey;
      }

      p {
        font-weight: 300;
        color: $text-dark-grey;
        font-size: 14px;
      }

      .helpIcon {
        margin-top: -16px;
      }

      .helpIcon2 {
        margin-top: -8px;
      }
    }

    .scoresTable {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
    }

    .scoresTable th,
    .scoresTable td {
      padding: 10px;
      vertical-align: middle;
    }

    .scoresTable th {
      font-weight: 600;
      color: $text-dark-grey;

      @include gammaSize {
        font-size: 12px;
      }
    }

    .scoresTable td {
      white-space: nowrap;
    }

    .catDate {
      font-size: 14px;
      font-weight: 300;
      color: $text-dark-grey;

      @include gammaSize {
        font-size: 9px;
      }
    }

    .trend {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      cursor: pointer;

      .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: $white;

        @include gammaSize {
          width: 30px;
          height: 30px;
          font-size: 14px;
          padding: 12px;
        }
      }

      .trendIcon {
        width: 20px;
      }

      .highScore {
        background-color: $color-go-green;
        border-radius: 100px;
      }

      .mediumScore {
        background-color: $color-warm-orange-medium;
      }

      .lowScore {
        background-color: $error-red;
      }

      .noScore {
        background-color: $text-deactivated;
      }
    }

    .trend div:first-child {
      font-weight: 400;
    }

    .roundedRectangle {
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      .highDifference {
        background-color: $color-go-green;
        border-radius: 6px;
        width: 120px;
        height: 40px;

        @include gammaSize {
          width: 65px;
          height: 30px;
        }

        p {
          font-size: 16px;
          color: $white;
          padding-top: 8px;

          @include gammaSize {
            font-size: 12px;
            padding-top: 6px;
          }
        }
      }

      .mediumDifference {
        background-color: $color-warm-orange-medium;
        border-radius: 6px;
        width: 120px;
        height: 40px;

        @include gammaSize {
          width: 65px;
          height: 30px;
        }

        p {
          font-size: 16px;
          color: $white;
          padding-top: 8px;

          @include gammaSize {
            font-size: 12px;
            padding-top: 6px;
          }
        }
      }

      .lowDifference {
        background-color: $error-red;
        border-radius: 6px;
        width: 120px;
        height: 40px;

        @include gammaSize {
          width: 65px;
          height: 30px;
        }

        p {
          font-size: 16px;
          color: $white;
          padding-top: 8px;

          @include gammaSize {
            font-size: 12px;
            padding-top: 6px;
          }
        }
      }

      .neutralDifference {
        background-color: $text-deactivated;
        border-radius: 6px;
        width: 120px;
        height: 40px;

        @include gammaSize {
          width: 65px;
          height: 30px;
        }

        p {
          font-size: 16px;
          color: $white;
          padding-top: 8px;

          @include gammaSize {
            font-size: 12px;
            padding-top: 6px;
          }
        }
      }
    }

    .roundedRectangle:hover {
      transform: translateY(-2px) scale(1.1);
      transition: transform 0.3s ease;
    }
  }
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
  margin-top: 20px !important;
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  .blueTrayImg {
    height: 94px;
  }

  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}
