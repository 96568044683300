@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.focusAreasAndProgressCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 30px;

  .cardTitle {
    color: $text-dark-grey;
    font-weight: 600;
    margin-top: 10px;
  }
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .blueTrayImg {
    height: 94px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}
