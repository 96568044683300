@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.bookingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .card {
    text-align: center;
    padding: 20px;
    width: 50%;
    border: 1px solid $color-calm-lilac-medium;
    border-radius: 20px;

    h5 {
      font-weight: 600;
    }

    @include gammaSize {
      width: 100%;
    }

    .bookingInfoSection {
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .bookingInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $color-sun-yellow-medium;
        align-content: center;
        align-items: center;
        width: 60%;
        padding: 1px 20px 1px 20px;

        @include gammaSize {
          width: 100%;
        }

        .icon {
          width: 25px;

          @include gammaSize {
            width: 20px;
          }
        }

        p {
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: -1px;
          color: $text-dark-grey;
          text-transform: capitalize;
        }
      }
    }

    .btnContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      width: 100%;

      .btns {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: auto;

        @include gammaSize {
          width: 100%;
        }
      }

      .btnAddToCalendar {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        margin-bottom: 20px;
        align-items: center;

        @include gammaSize {
          width: 100%;
        }
      }
    }
  }
}

.successMessage {
  color: $text-dark-grey;
  margin-top: 40px;
}
.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color-go-green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  background-color: $color-go-green;
}
.checkmark {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px $color-go-green;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  margin-top: 10px;
}
.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    background-color: $color-go-green;
  }
}

.checkContainer {
  margin-top: 20px;
}

.sessionDurationsSelection {
  margin-top: 30px;

  .sessionDurationTopic {
    color: $text-dark-grey;
  }

  .bookingSessionDurations {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px;

    @include gammaSize {
      gap: 10px;
    }

    .bookingSessionDuration {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $color-putty-dark;
      padding: 5px 5px 5px 5px;
      cursor: pointer;
      align-items: center;
      border-radius: 4px;
      width: 110px;
      height: auto;
      box-sizing: border-box;

      @include gammaSize {
        padding: 5px 5px 5px 5px;
        gap: 5px;
        width: 110px;
        height: auto;
        max-height: 100px;
      }

      &.selected {
        background-color: $color-sun-yellow-medium;
      }

      p {
        color: $text-dark-grey;
        line-height: 4px;
        font-size: 14px;
        margin-top: 8px;

        @include gammaSize {
          line-height: 2px;
          margin-top: 6px;
        }
      }

      .checkTick {
        width: 20px;
        margin-top: auto;
      }
    }
  }

  .bookingSessionTypesInfo {
    margin-top: 30px;
    p {
      color: $text-dark-grey;
    }

    .creditsInfo {
      line-height: 1px;

      @include gammaSize {
        line-height: unset;
      }
    }
  }
}

.sessionTypesSection {
  margin-top: 30px;
  h5 {
    color: $text-dark-grey;
  }

  .bookingSessionTypes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px;

    @include gammaSize {
      gap: 5px;
    }

    .bookingSessionType {
      display: flex;
      flex-direction: column;
      background-color: $color-putty-dark;
      padding: 5px 5px 5px 5px;
      cursor: pointer;
      align-items: center;
      border-radius: 4px;
      width: 110px;
      height: auto;
      box-sizing: border-box;

      @include gammaSize {
        padding: 10px;
        gap: 5px;
        width: 110px;
        max-height: 100px;
      }

      &.selected {
        background-color: $color-sun-yellow-medium;
      }

      .typeIcon {
        width: 20px;
      }

      p {
        color: $text-dark-grey;
        font-size: 14px;

        @include gammaSize {
          line-height: 12px;
        }
      }

      .checkTick {
        width: 20px;

        @include gammaSize {
          margin-top: -12px;
        }
      }
    }
  }
}

.checkTickNullImage {
  height: 20px;
}

.noSessionsAvailable {
  margin-top: 40px;
  p {
    color: $text-dark-grey;
  }
}

.bookingUnavailableSection {
  .bookingUnavailableSectionContent {
    text-align: center;
    margin-top: 40px;

    .warningIcon {
      width: 50px;
      margin-bottom: 40px;
    }

    .content {
      margin-bottom: 60px;
    }
  }
}

.sessionLoading {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disclaimerText {
  width: 100%;
  margin-top: -10px;
  color: $text-dark-grey;
}

.disclaimerTextPolicyTopic {
  font-weight: 600;
}

.policiesContainer {
  text-align: center;

  p {
    color: $text-dark-grey;
  }

  span {
    font-weight: 600;
  }
}

.textDivider {
  border: none;
  height: 1px;
  background-color: $text-dark-grey;
  width: 100%;
}

.disclaimerTextCompleteBooking {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  color: $text-dark-grey;

  span {
    font-weight: 600;
  }

  .terms {
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;
    color: $color-sky-blue-medium;
  }

  .terms:hover {
    color: $color-sky-blue-light;
  }
}

.modalRoot {
  position: fixed;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 90%;
  height: 95%;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.547);
  box-sizing: border-box;

  @include gammaSize {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }

    @include gammaSize {
      margin-bottom: 20px;
    }
  }

  .btnCloseIcon {
    font-size: 25px;
  }

  .modalContent {
    width: 100%;
    margin-bottom: 60px;
    cursor: default;

    h5 {
      margin-top: 20px;
      font-weight: 600;
      color: $text-dark-grey;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      margin-bottom: 15px;
      color: $text-dark-grey;
      font-size: 1rem;
      font-weight: 400;
    }

    .sectionTitle {
      font-size: 18px;
      font-weight: 600;
      color: #222;
      margin-top: 20px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    a {
      color: $color-sky-blue-medium;
      text-decoration: underline;
      font-weight: 500;
      &:hover {
        color: $color-sky-blue-light;
      }
    }

    .highlight {
      background-color: $color-putty-medium;
      color: $text-dark-grey;
      padding: 10px;
      border-left: 3px solid $color-warm-orange-medium;
      margin-top: 20px;
      border-radius: 4px;

      p {
        font-weight: 600;
      }
    }
  }

  .footerImageV1 {
    position: fixed;
    bottom: auto;
    width: 100%;
    height: 150px;
    background-image: url('../../../../assets/images/modal-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom left;
    margin-left: -30px !important;
    z-index: -1;

    @include mediaBetween(6200px, 2400px) {
      bottom: 0 !important;
    }
  }
}

.modalRoot::-webkit-scrollbar {
  width: 0;
}

.errorMessage {
  span {
    color: $error-red;
    font-weight: 600;
    font-size: 18px;
  }
  padding-bottom: 30px;
}
