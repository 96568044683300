@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  margin-bottom: 100px;

  @include betaSize {
    margin-bottom: 75px;
    .topBox {
      @include flexContainer(column, nowrap, space-between, normal, 0);
      min-height: 260px;
      padding: 20px 10px 0 10px;
      height: auto;

      .content {
        margin-top: 10px;
        .middleText {
          @include bodyCopy;
          color: $white;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .titleSection {
    padding-left: 20px;
    padding-right: 20px;
    h1 {
      font-weight: 600;
      color: $text-dark-grey;
    }
  }

  .expertsContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.expertGrid {
  padding-top: 20px;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include gammaSize {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: inherit;
  }

  .filterBox {
    margin-top: 30px;
    @include flexContainer(row, nowrap, flex-start, normal, 10px);

    p {
      color: $color-sky-blue-medium;
      font-weight: 600;
    }

    @include gammaSize {
      @include flexContainer(column, nowrap, flex-start, normal, 0);
    }
  }

  .bookingHistoryBtn {
    margin-top: 15px;

    @include gammaSize {
      margin-top: 0;
    }

    .hsSecondary {
      background: $white;
      border: 3px solid $color-sky-blue-medium;
      color: $color-sky-blue-medium;
      display: inline-flex;
      background: transparent;
      justify-content: center;
      border-radius: 24px;
      padding: 7px 24px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
      height: 42px;

      @include gammaSize {
        width: 100%;
      }

      &:hover {
        border-color: $color-sky-blue-light;
        color: $color-sky-blue-light;
      }

      &:disabled {
        border-color: $text-deactivated;
        color: $text-deactivated;
        opacity: 0.3;
      }
    }
  }
}

.itemLoading {
  border-radius: 20px !important;
}

.noExperts {
  text-align: center;
  padding: 100px 100px 0 100px;

  @include gammaSize {
    padding: 80px 20px 0 20px;
  }

  p {
    font-size: 20px;

    @include gammaSize {
      font-size: 18px;
    }
  }

  span {
    color: $color-sky-blue-medium;
    cursor: pointer;
  }

  span:hover {
    color: $color-sky-blue-light;
  }
}

.pagination {
  text-align: center;
  margin-top: 60px;
}

.expertSessionContainer {
  margin-bottom: 36px;

  @include gammaSize {
    margin-bottom: 20px;
  }
}

.expertSessionHistoryContainer {
  margin-bottom: 50px;
  opacity: 0;
  animation: fadeIn 0.7s ease-in forwards;

  @include gammaSize {
    margin-bottom: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.autoComplete2 {
  @include gammaSize {
    padding-top: 30px;
  }
}
