@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

$actionCardColors: (
  skyBlue: $color-sky-blue-medium,
  calmLilac: $color-calm-lilac-medium,
  sunYellow: $color-sun-yellow-medium,
  zingyGreen: $color-zingy-green-medium,
  aquaGreen: $color-aqua-green-medium,
  warmOrange: $color-warm-orange-medium,
  torquoise: $color-turquoise-medium,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

$darkColors: (
  skyBlue: $color-sky-blue-dark,
  calmLilac: $color-calm-lilac-dark,
  sunYellow: $color-sun-yellow-dark,
  zingyGreen: $color-zingy-green-dark,
  aquaGreen: $color-aqua-green-dark,
  warmOrange: $color-warm-orange-dark,
  torquoise: $color-turquoise-dark,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-dark,
  white: $white,
);

.card {
  border-radius: 20px;
  border: 1px solid $color-putty-dark;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  background-color: $white;
  padding: 40px;

  @include gammaSize {
    margin-top: 20px;
    padding: 20px;
  }

  h3 {
    color: $text-dark-grey;
    font-weight: 600;
  }

  .actionSection {
    margin-top: 30px;
    margin-bottom: 40px;
    .actionCard {
      border-radius: 20px;
      padding: 20px 20px 20px 20px;
      margin-bottom: 30px;
      overflow: hidden;
      height: 150px;

      @each $color, $borderColor in $actionCardColors {
        &.#{$color} {
          border: 3px solid $borderColor;
        }
      }

      @include gammaSize {
        height: auto;
      }

      .container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        @include gammaSize {
          flex-direction: column;
        }
      }

      .cardActionImageCont {
        width: 150px;
        max-width: 150px;
        background-color: $color-putty-light;
        margin: -20px !important;
        background-position: center;
        background-size: contain;
        object-fit: fill;
        background-repeat: no-repeat;
        flex-basis: 20%;
        height: 150px;

        @include gammaSize {
          display: none;
        }
      }

      .planName {
        color: $text-dark-grey;
        font-size: 20px;
        font-weight: 600;
      }

      .middleCont {
        display: flex;
        flex-direction: row;
        flex-basis: 50%;
        gap: 60px;

        @include gammaSize {
          flex-direction: column;
          gap: 20px;
          flex-basis: 100%;
        }

        .dates {
          color: $text-dark-grey;
          text-align: center;
          font-size: 18px;
          margin-left: 2%;
          flex-basis: 20%;
        }
      }

      .btnCont {
      }
    }
  }
}

.hsPrimaryBtn {
  background: $color-sky-blue-medium;
  color: $white;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;

  &:hover {
    background: $color-sky-blue-light;
    border: 2px solid $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.focusCardContainer {
  cursor: not-allowed;
  pointer-events: none !important;
  .focusCard {
    width: 100%;
    background: #ffffff;
    position: relative;
    z-index: 2;

    .focusCardHeader {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .cardTitle {
        @include heading3;
        font-weight: 500;
        font-size: 30px;
        font-weight: 600;
      }

      .cardHeaderBtns {
        display: flex;
        flex-direction: row;
        gap: 10px;

        span {
          font-size: 16px;
          font-weight: 500;
          color: $color-sky-blue-medium;
          cursor: pointer;
          margin-top: 5px;

          &:hover {
            color: $color-sky-blue-light;
          }
        }
      }
    }

    .focusAreaDescription {
      margin-top: 20px;
      color: $text-dark-grey;
      font-weight: 300px;
      font-size: 17px;
      opacity: 0.8;
    }

    .focussingTextContainer {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 20px;
      align-items: center;

      span {
        font-weight: 600;
        color: $text-dark-grey;
      }

      .focusRoundedItem {
        border-radius: 40px;
        padding: 10px;
        color: $white;
        font-size: 14px;
        height: 40px;
        text-align: center;

        @each $color, $bg in $darkColors {
          &.#{$color} {
            background: $bg;
          }
        }
      }
    }
  }

  .progressSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    .progressBar {
      width: 100%;
      margin-top: 5px;
    }

    span {
      font-weight: 600;
      color: $text-dark-grey;
    }
  }
}

.completedDate {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
}

.backBtn {
  cursor: pointer;
  pointer-events: all !important;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
