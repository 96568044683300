@import '../../../../assets/styles/variables';

.hsScoreCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 23px;
  border: 3px solid transparent;
  background: $white;
  border-radius: 30px;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  + {
    .hsScoreCard {
      margin-top: 10px;
    }
  }

  p {
    margin: 0;
    color: $text-dark-grey;

    &:first-of-type {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;

      @media screen and (max-width: 768px) {
        margin-bottom: 5px;
      }
    }

    &:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    .scoreSpan {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 3px solid transparent;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-left: 10px;
    }
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    span {
      border-color: $color-calm-lilac-medium;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    span {
      border-color: $color-sky-blue-medium;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    span {
      border-color: $color-sun-yellow-medium;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    span {
      border-color: $color-zingy-green-medium;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    span {
      border-color: $color-aqua-green-medium;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    span {
      border-color: $color-warm-orange-medium;
    }
  }
}
