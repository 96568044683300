@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.mainContainer {
  padding: 49px 68px 40px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .mainTitle {
    color: $text-dark-grey;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }

  .regUsersCountText {
    color: $text-dark-grey;
  }
}

.blueContainer {
  background-color: $color-sky-blue-medium;

  h3 {
    color: $white;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }
}
