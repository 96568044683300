@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.container {
  .tabsContainer {
    position: relative;
    z-index: 1;
    margin-top: 30px;

    @include gammaSize {
      display: none;
    }
  }

  .starLoadingContainer {
    display: flex !important;
    justify-content: center !important;
    margin-top: -50px;
    padding-bottom: 200px;

    .starLoadingItem {
      display: flex !important;
      justify-content: center !important;
      text-align: center !important;
    }

    .starLoadingSquareItem {
      display: flex !important;
      justify-content: center !important;
      text-align: center !important;
      margin-left: 50px;

      .radarChartSkeleton {
        width: 222px !important;
        height: 222px !important;
        transform: rotate(45deg) !important;
      }
    }
  }

  .hsSubNavigation {
    display: none;

    @include gammaSize {
      display: block;

      ul {
        list-style-type: none;
        flex-direction: column;
        padding: 37px 30px;
        background: $color-sky-blue-dark;
        position: absolute;
        width: 95%;
        z-index: 12;
        margin: 0;
        display: flex;
        align-items: center;
        padding: 15px;

        .show {
          opacity: 0;
        }

        li {
          width: 100%;
          color: $white;
          border-bottom: none;
          cursor: pointer;
          transition: all 0.5s;

          a {
            display: inline-block;
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            font-family: 'Titillium Web', sans-serif;
          }

          a {
            padding: 14px 0;
          }

          &.active {
            background: transparent;
            border-bottom: none;

            a {
              position: relative;

              &:after {
                content: '';
                width: 100%;
                height: 4px;
                background: $color-sun-yellow-medium;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .tabContent {
    background-color: $white;
    border-radius: 0 30px 30px 30px;
    padding-top: 40px;
    position: relative;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
  }

  .deepDiveRadarContainer {
    margin-top: 40px;
    @include gammaSize {
      border-radius: 0 0 30px 30px;
    }
  }

  .circleScoreCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    margin-bottom: 100px;
  }

  .noDataCont {
    color: black;
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    height: 100vh;
  }
}

.areaContainer {
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
}

.fullWidth {
  width: 100% !important;
  max-width: 100% !important;
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  padding-bottom: 30px;

  .blueTrayImg {
    height: 94px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}

.loadingSkeleton {
  margin-top: 20px;
  padding: 10px;
}
