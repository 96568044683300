@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixns';

.feelsImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -30px;

  .feelsImage {
    width: 50px;
    cursor: pointer;
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: wave 2s infinite;
  }
}

.sidesTextContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  color: $text-dark-grey;
  font-weight: 400;

  @media only screen and (max-device-width: 667px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
    margin-top: 10px !important;
  }

  @media only screen and (max-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    margin-top: 20px !important;
  }

  @include alphaSize {
    margin-top: 20px;
  }

  .textLeft {
    text-align: left;
  }

  .textRight {
    text-align: right;
  }
}

.modalTitle {
  @include subHeading1;
  font-weight: 600;
  text-align: center;
  font-size: 28px;
  color: $text-dark-grey;
  margin-top: 20px !important;
  margin: 0 auto;

  @include alphaSize {
    white-space: inherit;
    font-size: 20px;
    margin-top: 10px !important;
  }

  @media only screen and (max-device-width: 667px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
    margin-top: 10px !important;
  }

  @media only screen and (max-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    margin-top: 20px !important;
  }
}

.happinessSliderItem {
  @include mediaBetween(3000px, 2000px) {
    padding-bottom: 20px;
  }
}

.footerImgContainer {
  position: fixed;
  bottom: 10;
  left: -30px;

  @include mediaBetween(3000px, 1800px) {
    margin-top: 10% !important;
  }
  .modalImage {
    width: 40%;
    height: auto;

    @include alphaSize {
      width: 40%;
    }
  }
}

.continueBtnContainer {
  text-align: center;
  margin-top: 40px;

  @media only screen and (max-device-width: 667px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
    margin-top: 20px !important;
  }

  @media only screen and (max-device-width: 450px) and (max-device-height: 1000px) {
    margin-top: 30px !important;
  }

  @include alphaSize {
    margin-top: 20px !important;
  }

  .continueBtnPrimary {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;
    width: 60%;

    &:hover {
      background: $color-sky-blue-light;
      border: 2px solid $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }
  }
}

.focusSelectionSection {
  padding: 40px;
}

.letsFocusText {
  font-weight: 400;
  color: $text-dark-grey;
  margin-top: 20px;
  font-size: 22px;

  span {
    font-weight: 600;
  }
}

.selectSomethingElseText {
  font-weight: 400;
  color: $text-dark-grey;
  margin-top: 40px;
  font-size: 22px;
}

.focusModesSection {
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  .focusModeSecondaryBtn {
    background: $white;
    border: 3px solid $color-sky-blue-medium;
    color: $color-sky-blue-medium;
    display: inline-flex;
    background: transparent;
    justify-content: center;
    border-radius: 24px;
    padding: 9px 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    width: 60%;

    &:hover {
      border-color: $color-sky-blue-light;
      color: $color-sky-blue-light;
    }

    &:disabled {
      border-color: $text-deactivated;
      color: $text-deactivated;
      opacity: 0.3;
    }
  }
}

.modalRoot::-webkit-scrollbar {
  width: 0;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $color-sky-blue-medium;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
