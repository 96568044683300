@import '../../../assets/styles/variables';

.hsSidenav {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 0 !important;
  min-width: 0 !important;
  height: calc(100vh) !important;

  img {
    position: absolute;
    bottom: 0;
    left: 240px;
  }

  & {
    + {
      .hsSidenavOverlay {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        min-height: 100vh;
        height: fit-content;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: width, left, right, 300ms;
        transition: width, left, right, 300ms;
        z-index: 9999;
      }
    }
  }

  &.Toggled {
    width: 430px !important;
    min-width: 430px !important;

    + {
      .hsSidenavOverlay {
        width: 100%;
      }
    }
  }

  nav {
    text-align: right;

    ul {
      text-align: left;
      margin: 0;
      padding: 20px 70px;
      list-style: none;

      li {
        color: $white;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 24px;
        transition: transform 0.3s ease-in-out;
        animation: fadeIn 0.5s ease-out;

        a {
          color: $white;
          text-decoration: none;
          cursor: pointer;
        }

        &.menuTitle {
          font-size: 24px;
        }

        &.divider {
          border: 1px solid $white;
          margin-top: 15px;
          margin-bottom: 45px;

          &.dividerSm {
            margin-bottom: 27px;
          }
        }
      }

      li:hover {
        transform: translateY(1px);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .hsSidenav {
    position: fixed;
    top: 0 !important;
    width: 0 !important;
    min-width: 0 !important;
    height: calc(100% - 68.8px) !important;

    &.Toggled {
      top: 0 !important;
      width: 100% !important;
      min-width: 100% !important;
      height: calc(100% - 68.8px) !important;
      z-index: 9999;
    }

    & {
      + {
        .hsSidenavOverlay {
          display: none;
        }
      }
    }

    nav {
      ul {
        padding: 70px 30px 0;
      }

      ul {
        + {
          ul {
            padding-top: 0;
            margin-top: 50px;
          }
        }
      }
    }
  }
}
