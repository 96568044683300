@import '../../assets/styles/variables';

.hsStepWizardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 70px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);

  &SelectedFocusAreas {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      margin: 0;
      color: $text-dark-grey;

      &:first-child {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }

      &:last-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  &Inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
  }

  button {
    min-width: 200px;
    justify-content: center;
  }
}

.hsStepWizardStartFooter {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 10;
}
