@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.reflectionContainer {
  background-color: $white;
  padding: 20px;
  border-radius: 30px;
  height: 430px;

  .bodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
  }

  .top {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;

    .titleLength {
      margin-right: 50px !important;
    }

    h5 {
      @include subHeading2;
      font-weight: 400;
      color: $text-dark-grey;
      justify-self: center;
      width: 100%;
      text-align: center;
      margin-left: 50px;
    }
    .questionmark {
      top: 25%;
      right: 40%;
      cursor: pointer;
      z-index: 1;
      display: none;

      .queMarkicon {
        height: 18px;
      }

      @media screen and (max-width: 992px) {
        right: 45%;
      }

      @media screen and (max-width: 768px) {
        top: 23%;
        right: 42%;
      }
    }
  }

  .body {
    .reflectionBody {
      display: flex;
      flex-direction: row;

      .quoteLeft {
        align-self: flex-start;
        width: 18px;
      }
      .quoteRight {
        align-self: flex-end;
        width: 18px;
      }

      .readMoreBtn {
        color: $color-sky-blue-medium;
        font-size: 16px;
        cursor: pointer;
      }
    }
    p {
      color: $text-dark-grey;
      width: 100%;
      line-height: 24px;
      font-size: 16px;
      font-weight: 300;
      margin-top: 10px;

      span {
        font-size: 40px;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .historyBtn {
      color: $color-sky-blue-medium;
      cursor: pointer;

      &:hover {
        color: $color-sky-blue-light;
      }

      &:disabled {
        color: $text-deactivated;
      }
    }
  }

  .dateContainer {
    display: flex;
    justify-content: center;
    h5 {
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 400;
      font-style: italic;
      color: $text-dark-grey;
    }
  }
}

.noReflections {
  display: flex;
  flex-direction: column;
  margin-top: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  opacity: 0.5;
}

.footerBtns {
  margin-top: 15px;

  @include gammaSize {
    margin-top: 60px;
  }
}

.loadBtn {
  display: inline-flex !important;
  justify-content: center !important;
  border-radius: 24px !important;
  min-width: 148px !important;
  min-height: 40px !important;
  margin-top: 60px !important;
}

.buttons2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 90px;

  @include gammaSize {
    margin-top: 70px;
  }

  .historyBtn {
    color: $color-sky-blue-medium;
    cursor: pointer;

    &:hover {
      color: $color-sky-blue-light;
    }

    &:disabled {
      color: $text-deactivated;
    }
  }
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  justify-content: flex-end;
  margin-right: -10px;

  .navigationBtn {
    background-color: transparent;
    border: none;
    padding: 5px;

    &:disabled {
      opacity: 0.3;
    }

    .navigationIcon {
      width: 25px;
      height: 25px;
    }
  }
}
