.card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 30px 50px;
  border-radius: 40px;

  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }
}
