@import '../../../assets/styles/variables';

.hsHeader {
  position: relative;
  background: $white;
  padding: 15px 70px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.5s;

  .hsNav {
    display: flex;
    align-items: center;

    .hsLogoHolder {
      display: flex;
      gap: 30px;
      align-items: center;
      flex: 1;
      justify-content: flex-start;

      .companyLogo {
        height: 44px;
        cursor: pointer;
      }

      .companyFull {
        height: 44px;
        cursor: pointer;
      }

      .img {
        height: 44px;
      }
    }

    .menuContainer {
      display: flex;
      flex: 5;
      margin: 0px 20px 0px 20px;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 66px;

        li {
          a {
            border-radius: 24px;
            transform: rotate(0.43deg);
            padding: 9px 20px;
            color: $text-dark-grey;
            text-decoration: none;

            &.active {
              background: $color-sun-yellow-medium;
              color: $text-dark-yellow;
            }
          }
        }
      }
    }

    div.notificationsHamburgerContainer {
      display: flex;
      align-items: center;
      gap: 30px;
      flex: 1;
      justify-content: flex-end;

      i {
        font-size: 25px;
        color: $text-darker-grey;
      }
    }
  }

  &.hsDark {
    background: $text-dark-grey;

    .hsNav {
      ul {
        li {
          a {
            color: $white;
            &.active {
              color: $text-dark-yellow;
            }
          }
        }
      }

      div.notificationsHamburgerContainer {
        i {
          color: $white;
        }
      }
    }
  }

  @media screen and (max-width: 1450px) {
    .hsNav {
      .hsLogoHolder {
        gap: 20px;

        .companyLogo {
          height: 30px;
        }

        .img {
          height: 30px;
        }
      }

      .menuContainer {
        ul {
          gap: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .hsNav {
      .hsLogoHolder {
        gap: 10px;

        .companyLogo {
          height: 25px;
        }

        .img {
          height: 25px;
        }
      }

      .menuContainer {
        ul {
          gap: 10px;
        }
      }
    }
  }

  .logo {
    cursor: pointer;
  }
}
