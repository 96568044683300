@import '../../../assets/styles/variables';

.deleteModal {
  .bodyMessage {
  }

  .name {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    .confirmBtn {
      background-color: #dc3545;
      border-radius: 0.375rem;
      border: none;
      color: $white;
      padding: 10px 20px 10px 20px;
      &:hover {
        background-color: #bb2d3b;
      }
    }

    .cancelBtn {
      background-color: #6c757d;
      border-radius: 0.375rem;
      border: none;
      color: $white;
      padding: 10px 20px 10px 20px;
      &:hover {
        background-color: #5c636a;
      }
    }
  }
}
