@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixns';

.step1Container {
  margin-top: 40%;
  min-height: 55vh;

  @include gammaSize {
    min-height: 70vh;
  }

  .titleSection {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;

    h3 {
      @include subHeading1;
      font-size: 24px;
    }
  }

  .feelsSelectionSection {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    .imgBtn {
      width: 100px;
      height: 100px;
      cursor: pointer;
      transition: transform 0.3s ease, filter 0.3s ease;
    }
  }

  .imgBtn:hover {
    animation: wave 2s infinite;
  }

  .sidesTextContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 16px;
    color: $text-dark-grey;
    font-weight: 400;

    .textLeft {
      text-align: left;
    }

    .textRight {
      text-align: right;
    }
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  25% {
    transform: rotate(5deg) scale(1.2);
  }
  50% {
    transform: rotate(0deg) scale(1.2);
  }
  75% {
    transform: rotate(-5deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1.2);
  }
}

.bottomCloseBtnCont {
  position: fixed;
  bottom: 20%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 999;

  .hsSecondary {
    background: $white;
    border: 3px solid $color-sky-blue-medium;
    color: $color-sky-blue-medium;
    display: inline-flex;
    background: transparent;
    justify-content: center;
    border-radius: 24px;
    padding: 9px 24px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;

    @include betaSize {
      font-size: 14px;
    }

    &:hover {
      border-color: $color-sky-blue-light;
      color: $color-sky-blue-light;
    }

    &:disabled {
      border-color: $text-deactivated;
      color: $text-deactivated;
      opacity: 0.3;
    }
  }
}

.welcomeMessage {
  min-height: 75vh;
  .welcomeText {
    margin-top: 20px !important;
    text-align: center;
    color: $text-dark-grey;
  }

  @include gammaSize {
    min-height: 85vh;
  }
}
.starForceSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
  text-align: center;

  .hsStarImage {
    width: 100px;
  }
}

.btnContainer {
  margin-bottom: 20px !important;
  left: 50%;
  text-align: center;
  width: 100%;

  .continuePrimary {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;
    width: 70%;
    text-transform: uppercase;

    &:hover {
      background: $color-sky-blue-light;
      border: 2px solid $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }
  }
}
