@import '../../assets/styles/variables';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  button::after {
    display: none;
  }
}
.btnAction {
  background-color: $color-sky-blue-medium !important;
  border-radius: 4px !important;
  padding: 4px !important;

  .icon {
    color: $white !important;
  }
}

.btnAction:hover {
  background-color: $color-sky-blue-light !important;
}
