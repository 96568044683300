@import "../../../assets/styles/variables";

.hsRadarChart {
  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: $text-dark-grey;
  }
}
