@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.topSection {
  padding-left: 20px;
  padding-right: 20px;
  h1 {
    font-weight: 600;
    color: $text-dark-grey;
  }

  .userDetailsSection {
    margin-top: 20px;
    h2 {
      font-size: 18px;
      color: $text-dark-grey;
    }

    .detailsContainer {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      margin-top: 20px;
      padding: 20px;
      background-color: $color-putty-medium;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .detailItem {
        display: flex;
        flex-direction: column;

        strong {
          color: $text-dark-grey;
          font-weight: 600;
        }

        span {
          margin-top: 5px;
          color: $text-dark-grey;
        }

        .infoImportant {
          color: $color-sky-blue-dark;
          font-weight: 600;
        }
      }
    }

    hr {
      margin-top: 20px;
      border: 0;
      border-top: 1px solid #e0e0e0;
    }
  }
}
