@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.learningItem {
  width: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
  background-color: $white;
  border-radius: 20px;
  margin-bottom: 10px;
  border: 1px solid $color-putty-medium;
  animation: fadeIn 0.5s ease-out;

  .learningItemInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;

    .learningItemInnerImage {
      height: 200px;
      position: relative;

      .image {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        height: 200px !important;
        width: 100% !important;
        object-fit: cover !important;
        object-position: center !important;
      }
    }

    .learningItemInnerContent {
      height: 75px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 10px;
      font-weight: 600;
      color: $text-dark-grey;
      font-size: 16px;
    }
  }
}

.learningItem:hover {
  transform: translateY(-2px);
}
