@import '../../../assets/styles/variables';

.link {
  color: $color-sky-blue-medium;
  text-decoration: none;
}

.disableLink {
  color: #828282;
  text-decoration: none;
  cursor: default;
}
