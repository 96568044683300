@import '../../../assets/styles/variables';

.titleRoot {
  margin: 2rem 4.5rem;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    margin: 10px 10px;
  }
}

.titleRootInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 4rem;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
  }
}

.title {
  font-weight: 600;
  font-size: 40px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.btnGroup {
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.btn {
  height: 30px;
}

.downloadTemplate {
  text-decoration: none;
}

.error {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.userResultSection {
  width: 100%;
}

.addEmpBtn {
  margin-bottom: 25px;
}

.fullWidth {
  max-width: 100%;
}

.mb25 {
  margin-bottom: 25px;
}

.hsPrimaryBtn {
  background-color: $color-sky-blue-medium;
  color: $white;
  padding: 9px 44px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;
  height: 46px;

  &:hover {
    background: $color-sky-blue-light;
    border: 2px solid $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}
