@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';
.scrollContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;
  height: 600px;
  overflow-y: scroll;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.scrollContainerSpinner {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
  height: 600px;
  overflow-y: scroll;
  margin-bottom: 40px;

  .spinner {
    color: $color-sky-blue-medium;
  }
}

.scrollContainerSpinner::-webkit-scrollbar {
  display: none;
}

.container {
  margin: 2rem 4.5rem;
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  min-height: calc(100vh - 326.8px);
}

.timeframes {
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin-top: 45px;
  gap: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.mainTitle {
  font-weight: 600;
  font-size: 40px;
}

.timeframeItem {
  border: 3px solid #069b7b;
  cursor: pointer;
  border-radius: 7px;
  padding: 5px 10px;
  width: 10rem;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: #069b7b;
  &.active {
    background-color: #069b7b;
    color: white;
  }
}

.highlightsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.tabsContainer {
  position: relative;
  z-index: 1;

  @include gammaSize {
    display: none;
  }
}

.selectComponent {
  display: none;
  z-index: inherit;

  @include gammaSize {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.questionairesContainer {
  position: relative;
  z-index: 2;
  border-radius: 0 30px 30px 30px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  @include gammaSize {
    z-index: 0;
    margin-top: 5px;
  }
}

.fullWidthContainer {
  display: flex;
  width: 100%;
  padding: 0 20px;
  flex-direction: column;
}

.rightContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: start;
  margin-top: 20px;
  margin-right: 210px;
  @include gammaSize {
    justify-content: unset;
    flex-wrap: wrap;
    gap: 2px;
  }
  .expandSelectedBtnSection {
    flex: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    margin: 0;
    align-items: center;
    margin-left: 15px;
    border: 3px solid #0072b0;
    border-radius: 25px;
    width: 210px;

    @include gammaSize {
      width: 90%;
      margin-top: 70px;
    }

    .sortButton {
      padding: 8px 20px;
      background-color: white;
      color: #0072b0;
      display: flex;
      flex-direction: row;
      border: none;
      margin-left: 0;
      flex: 1;
      p {
        color: #0072b0;
        font-weight: 700;
        margin: 0;
      }
      i {
        font-size: 20px;
        margin-left: 10px;
        font-weight: 600;
      }
      @include gammaSize {
        min-width: 100% !important;
        margin-left: 0;
        margin-top: 10px;
        justify-content: center;
      }
    }
    .expandBtnContainer {
      background-color: #0072b0;
      display: flex;
      flex-direction: column;
      padding: 18px 10px;
      width: 100%;
      .expandButtonDefault {
        margin-top: 10px;
        border: 0;
        background-color: transparent;
        color: white;
        padding: 5px 10px;
        font-size: 15px;
      }
      .selectedSortItem {
        background-color: #004b74;
      }
    }
  }
}

.questionaireItem {
  margin-left: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.sortButton1 {
  padding: 8px 20px;
  background-color: #0072b0;
  width: 210px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 50px;
  margin-left: 15px;
  p {
    color: white;
    font-weight: 700;
    margin: 0;
  }
  i {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    justify-content: center;
  }
}

.spanButton {
  font-weight: 600;
  color: white;
  margin-right: 5px;
}

.commentsTab {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: #505050;
  margin-bottom: 20px;
}

.fullWidth {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.thoughtsContainer {
  display: flex;
  flex-direction: column;
  .awaitingResContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    flex: 1;
    .commentCard {
      flex: 1;
      max-width: 100%;
      @include gammaSize {
        max-width: 100% !important;
      }
    }
    @include gammaSize {
      flex-direction: column;
      width: 100%;
      flex-wrap: nowrap;
    }
  }
  .thoughtsTab1 {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .blueTrayImg {
      height: 94px;
    }
    p {
      font-size: 24px;
      font-weight: 600;
      margin-top: 40px;
      color: black;
      text-align: center;
    }
  }
  .thoughtsTab2 {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .blueTrayImg {
      height: 94px;
    }
    p {
      font-size: 24px;
      font-weight: 600;
      margin-top: 40px;
      color: black;
      text-align: center;
    }
  }
}

.halfWidth {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.responsesTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #505050;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
}

.responsesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  .responsesList {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
    color: black;
    flex-direction: column;
    align-items: center;
    .blueTrayImg {
      height: 94px;
    }
    p {
      font-size: 24px;
      font-weight: 600;
      margin-top: 40px;
      color: black;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.paginationBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  margin-top: -10px;
  font-weight: 600;
}
