.cardContainer {
  width: 730px;
  margin: 20px auto;

  .checkInboxContentContainer {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    align-content: center;
    justify-content: center;

    .checkInboxContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .token {
          margin-bottom: 1rem;
        }
      }

      .organizationLogoHolder {
        width: 100%;
        padding: 10px 20px;
      }

      h1 {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        color: #505050;
        margin-bottom: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 95%;

    .checkInboxContentContainer {
      padding: 0;

      .checkInboxContent {
        width: 100%;

        .organizationLogoHolder {
          width: 60%;
          text-align: center;
          margin: auto;
        }

        h1 {
          font-size: 34px;
        }

        .checkInboxDetails {
          width: 95%;
        }
      }
    }
  }
}

.img {
  width: 157px;
  height: 157px;
  margin-bottom: 1rem;
}
