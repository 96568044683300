@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.recommendationsCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 30px;

  .cardTitle {
    color: $text-dark-grey;
    font-weight: 600;
    margin-top: 10px;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 16px;
    margin-top: 20px;

    @include gammaSize {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  .gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $color-putty-medium;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }

  .gridItem:hover {
    transform: translateY(-5px);
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
  }

  .value {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: $text-dark-grey;
  }

  .label {
    font-size: 16px;
    color: $text-dark-grey;
  }
}
