@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 380px) and (min-device-width: 100px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) and (min-device-width: 381px) {
    margin-bottom: 10px;
  }
}

.title {
  margin-top: 20px;
  @include subHeading2;
  font-size: 18px;

  @media only screen and (max-width: 380px) and (min-device-width: 100px) {
    margin-top: -10px;
    font-size: 16px;
  }

  @media only screen and (max-width: 767px) and (min-device-width: 381px) {
    margin-top: -10px;
    font-size: 16px;
  }
}

.thoughtButtonContainer {
  @media only screen and (max-width: 380px) and (min-device-width: 100px) {
    margin-bottom: 10px;
  }
}

.sliderContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 10px;
  margin-top: 5px;

  @media only screen and (max-width: 380px) and (min-device-width: 100px) {
    margin-top: -20px;
  }

  @media only screen and (max-width: 428px) and (min-device-width: 381px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 767px) and (min-device-width: 429px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &.sliderContainerNull {
    @media only screen and (max-width: 380px) and (min-device-width: 100px) {
      margin-bottom: 7vh;
      margin-top: 10px;
    }

    @media only screen and (max-width: 767px) and (min-device-width: 381px) {
      margin-bottom: 6vh;
      margin-top: 20px;
    }
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    margin-top: 2px;
    color: $color-sky-blue-medium;
  }

  .sliderWrapper {
    width: 100%;
  }
}
