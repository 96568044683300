@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.newsContainer {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 24px;
  margin-top: 36px;

  h5 {
    font-weight: 600;
    font-size: 20px;
    color: $text-dark-grey;
  }

  @include gammaSize {
    margin-top: 26px;
  }

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    .navigationBtn {
      background-color: transparent;
      border: none;
      padding: 5px;
      cursor: pointer;
      margin-top: -15px;
      margin-bottom: 10px;

      &:disabled {
        opacity: 0.3;
        cursor: default;
      }

      .navigationIcon {
        width: 25px;
        height: 25px;
      }
    }
  }

  .learningItemsContainer {
  }

  .learningLoadingItem {
    border-radius: 20px !important;
    margin-bottom: 10px !important;
    height: 277px !important;
  }
}
