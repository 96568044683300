@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.card {
  padding: 40px;

  @include gammaSize {
    padding: 10px;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;

    .emptyPlansImg {
      width: 40%;
      margin-top: -40px;
    }

    .texts {
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        text-align: center;
        color: $text-dark-grey;
        font-size: 18px;
        font-weight: 600;
        margin-top: -20px;
      }

      span {
        text-align: center;
        color: $text-dark-grey;
        margin-top: -10px;
      }
    }

    .texts2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        text-align: center;
        color: $text-dark-grey;
        font-size: 18px;
        font-weight: 600;
        margin-top: -20px;
      }

      span {
        text-align: center;
        color: $text-dark-grey;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    .button {
      margin-top: 20px;
      text-align: center;
    }

    .checkInButton {
      text-align: center;
    }
  }
}

$duration: 2000;

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 200px;
  overflow: hidden;
  margin-top: -40px;
}

.confettiPiece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $color-sun-yellow-medium;
  top: 0;
  opacity: 0;

  @for $i from 1 through 13 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $duration * 0.5)}ms;
      animation-duration: #{randomNum($duration * 0.7, $duration * 1.2)}ms;
    }
  }

  &:nth-child(odd) {
    background: $color-sky-blue-medium;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: $duration * 2ms;
  }

  &:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: $duration * 2.5ms;
    animation-delay: $duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $color-calm-lilac-medium;
    height: 32px;
  }

  &:nth-child(5n) {
    background: $color-zingy-green-medium;
  }

  &:nth-child(6n) {
    background: $color-aqua-green-medium;
  }

  &:nth-child(7n) {
    background: $color-warm-orange-medium;
  }
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(200px);
  }
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color-go-green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  background-color: $color-go-green;
}
.checkmark {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px $color-go-green;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  margin-top: 10px;
}
.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    background-color: $color-go-green;
  }
}

.checkContainer {
  margin-top: 20px;
}

.roundedCheckbox {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: $color-putty-dark;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-top: -4px;
}

.roundedCheckbox:before {
  content: '\2713';
  font-size: 16px;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roundedCheckbox:checked {
  clip-path: circle(50% at 50% 50%);
  background-color: $color-sky-blue-medium;
}

.roundedCheckbox:checked:before {
  color: $white;
}

.checkBoxTitle {
  @include bodyCopyLarge;
  margin-left: 10px;
}
