@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.chatBotModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
}

.chatContainer {
  width: 500px;
  background: white;
  border-top-left-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 90vh;
  display: flex;
  flex-direction: column;

  @include gammaSize {
    height: 640px;
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-sky-blue-medium;
  color: #fff;
  padding: 10px;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-left: 10px;
  }

  .icons {
    display: flex;
    gap: 10px;
  }
}

.focusTitle {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: -10px;
  font-weight: 600;
  margin-top: 20px;
  color: $text-dark-grey;
}

.radioGroup {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.radioOption {
  margin-bottom: 10px;

  .MuiRadio-root {
    color: #3f51b5;
  }

  .MuiRadio-root.Mui-checked {
    color: #673ab7;
  }
}

.chatBox {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.changeFocusArea {
  color: $color-sky-blue-medium;
  font-weight: 600;
  cursor: pointer;
}

.message {
  display: flex;
  align-items: flex-start;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: default;

  .roleIcon {
    padding-right: 10px;
    width: 30px;
  }

  &.user {
    align-self: flex-end;
    background-color: #0072b0;
    color: $white;
    border-top-right-radius: 0;

    .messageContent {
      color: $white;
    }
  }

  &.assistant {
    align-self: flex-start;
    background-color: #019ef22d;
    color: #0072b0;
    border-top-left-radius: 0;

    .messageContent {
      color: $text-dark-grey;
    }
  }

  strong {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}

.loading {
  display: block;
  margin: 10px auto;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: relative;
  margin-top: auto;
}

.messageInput {
  flex-grow: 1;
  margin-right: 10px;

  .sendButton {
    padding: 10px 20px;
    background-color: #3f51b5;
    color: #fff;
    min-width: 90px;
    font-size: 0.9rem;

    &:hover {
      background-color: #303f9f;
    }
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 600px;
}

.skeleton {
  width: 250px !important;

  @include gammaSize {
    width: 120px !important;
  }
}

.voiceIcon.active {
  color: #3f51b5;
  animation: pulse 1s infinite;
}

.speakerIcon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.speakerIcon.active {
  animation: pulse 1s infinite;
  color: #4caf50;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.focusLoaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
