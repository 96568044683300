@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.scoreGaugeContainer {
  background-color: $color-sky-blue-dark;
  padding: 20px;
  border-radius: 30px;
  height: 430px;

  &.exploreSectionEnabled {
    height: 530px;

    @include gammaSize {
      height: 100%;
      margin-bottom: 60px;
    }
  }

  @include gammaSize {
    height: 430px;
  }

  .top {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    h5 {
      @include subHeading2;
      font-weight: 400;
      color: $white;
    }
    .questionmark {
      top: 25%;
      right: 40%;
      cursor: pointer;
      z-index: 1;

      .queMarkicon {
        height: 18px;
      }

      @media screen and (max-width: 992px) {
        right: 45%;
      }

      @media screen and (max-width: 768px) {
        top: 23%;
        right: 42%;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .hsPrimaryWhite {
    background: $white;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    color: $color-sky-blue-medium;
    border: none;
    min-width: 148px;

    &:hover {
      background: $color-putty-dark;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }
  }
}

.mobileExploreSection {
  display: none;

  @include gammaSize {
    display: block;
    background-color: $white;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: -50px;
  }
}

.mobileExploreSectionEmpty {
  display: none;

  @include gammaSize {
    cursor: pointer;
    display: block;
    background-color: $white;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: -60px;
  }
}

.topContent {
  display: none;

  @include gammaSize {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    p {
      color: $white;
      text-align: center;
    }

    .tipsIcon {
      width: 30px;
      margin-top: -10px;
    }
  }
}

.loadBtn {
  display: inline-flex !important;
  justify-content: center !important;
  border-radius: 24px !important;
  min-width: 148px !important;
  min-height: 40px !important;
}

.loadPie {
  display: flex !important;
  justify-content: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 40px !important;
  margin-top: 10px;
}

.loadSkeletonText {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-top: 20px !important;
}

.emptyDataBox {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: 30px 0 40px 0;
  display: flex;
}

.emptyCircle {
  display: flex;
  justify-content: center;
  width: 160px;
  height: 160px;
  padding: 15px;
  border: solid 20px #fcfbf6;
  border-radius: 50%;
}

.emptyText {
  display: flex;
  color: $white;
  font-family: Titillium Web;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.emptyChart {
  padding: 20px;

  h3 {
    font-size: 20px;
    text-align: center;
    color: $text-dark-grey;
    span {
      color: $color-sky-blue-medium;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .graphImage {
    width: 100%;
    margin-top: 10%;
  }
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trackText {
  margin-top: 20px;
  p {
    color: $white;
    text-align: center;
  }
}
.areaChartContainer {
  display: none;

  @include gammaSize {
    display: block;
    background-color: $white;
    padding: 20px;
    border-radius: 30px;
    height: 430px;
    max-height: 100%;
  }
}
