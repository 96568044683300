@import '../../assets/styles/variables';

.hsShareThoughts {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 25px;
    height: 90%;
    justify-content: inherit;
  }

  &CloseButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      flex-direction: row;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: $text-dark-grey;
    margin-bottom: 16px;
  }

  &Label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    p {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      color: $text-dark-grey;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: $text-dark-grey;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
  }

  &DetailsContainer {
    //margin-top: -100px;
  }

  textarea {
    width: 100%;
    border: 2px solid $color-putty-dark;
    border-radius: 10px;
    resize: none;
    height: 200px;
    margin-top: 20px;
    padding: 13px 15px;
  }

  p.hsCharCounter {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: $text-dark-grey;
    margin: -4px 6px 0 0;
  }

  &ShareBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    margin-top: 30px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      width: auto;
      gap: 10px;
    }
  }

  &ShareButtonContainer {
    padding: 10px 0;
    button {
      width: 100%;
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 20px;
      width: 100%;
      background-color: white;
      text-align: center;
    }
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-sky-blue-medium;
      }
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-zingy-green-medium;
      }
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-aqua-green-medium;
      }
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsShareThoughtsLabel {
      span {
        border-color: $color-warm-orange-medium;
      }
    }
  }
}

.emptyBox {
  min-height: 144px;
  color: #505050;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    min-height: 110px;
    font-size: 13px;
    margin-bottom: 20px;
  }
}
