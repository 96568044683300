.tableContainer {
  overflow-x: scroll;
  max-width: 100%;
}
.tableRoot {
  max-width: 100%;
  width: 100%;
  max-height: 800px;
  position: relative;

  thead {
    height: 49px;
    background-color: #eee;

    tr {
      th {
        padding: 14px;
        background-color: #eee;
        border: 1px solid #dee2e6;
      }
    }

    .tableBodyRow {
      padding: 0.5rem;
      box-shadow: inset 0 0 0 9999px;
    }
  }

  tbody {
    tr {
      height: 49px;

      td {
        border: 1px solid #dee2e6;
        padding: 10px;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
}
