@import "../../assets/styles/variables";

.hsScoreFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $text-deactivated;
  margin-bottom: 96px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

  span {
    line-height: 1.5;
  }

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $text-dark-grey;
  }

  div {
    display: flex;
    align-items: center;
    gap: 28px;
    font-weight: 600;
    font-size: 20px;
    line-height: 48px;
    color: $text-dark-grey;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 23px;

      li {
        display: flex;
        align-items: center;
        gap: 5px;

        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
        }

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label
        {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #666;
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: 18px;
          height: 18px;
          border: 1.5px solid rgba(102, 112, 128, 0.3);;
          border-radius: 100%;
          background: #fff;
        }

        [type="radio"]:checked + label:before {
          border: 1.5px solid $color-turquoise-medium;
        }

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          content: '';
          width: 12px;
          height: 12px;
          background: $color-turquoise-medium;
          position: absolute;
          top: 5px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        [type="radio"]:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hsScoreFilter {
    margin-bottom: 25px;

    p {
      display: none;
    }

    div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;
      width: 100%;

      ul {
        gap: 20px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
