@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  background-color: $white;
  border-radius: 20px;
  border: 2px solid $color-putty-medium;
  padding: 40px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .subContainer {
    flex: 1;
    cursor: pointer;
    text-align: center;
    align-items: center;

    .expertProfileImg {
      border-radius: 100px;
      width: 80px;
      height: 80px;
      background-color: $color-putty-dark;
    }

    .name {
      @include subHeading2;
      color: $color-sky-blue-medium;
      margin-top: 20px;
    }

    .experience {
      color: $color-warm-orange-medium;
    }

    .description {
      color: $text-dark-grey;
      font-size: 14px;
      flex-grow: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      line-height: 1.5;
      max-height: calc(1.5em * 5);
    }
  }

  .viewProfile {
    color: $color-sky-blue-medium;
    cursor: pointer;
  }

  .bookButtonContainer {
    display: flex;
    justify-content: center;
  }
}

.modalRoot {
  z-index: 99999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 95%;
  max-width: 100%;
  min-height: 50%;
  max-height: 95%;
  overflow-y: auto;
  background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 20px;
    min-width: 90%;
    overflow-x: hidden;
    max-height: 100%;
  }

  .modalBody {
    .modalTitle {
      @include bodyCopyLarge;
      margin-top: -25px;
      font-weight: 500;
      font-size: 24px;
    }
    .btnArea {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }

    @include gammaSize {
      margin-bottom: 20px;
    }
  }

  .btnCloseIcon {
    font-size: 25px;
  }
}

.leftSideExpertDetailsDesktop {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @include gammaSize {
    display: none;
  }

  .expertProfileImg {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: $color-putty-dark;
  }

  .expertDetails {
    h5 {
      font-weight: 600;
      color: $text-dark-grey;
    }

    p {
      margin-top: -5px;
      color: $text-dark-grey;
    }
  }
}

.leftSideExpertDetailsGamma {
  display: none;
  @include gammaSize {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .row {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;

      h5 {
        font-weight: 600;
        font-size: 28px;
        color: $text-dark-grey;
      }
    }

    .expertProfileImg {
      width: 100px;
      height: 100px;
      border-radius: 100px;
      background-color: $color-putty-dark;
    }

    p {
      margin-top: -5px;
      color: $text-dark-grey;
    }
  }
}
