@import '../../../assets/styles/variables';

.hsHeader {
  position: relative;
  background: $white;
  padding: 15px 70px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.5s;

  .hsNav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .img {
      height: 43px;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}
