@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.hsFooter {
  background-image: url('../../../assets/images/footer.svg');
  max-height: 212px;
  width: 100%;
  padding-top: 102px;
  position: relative;
  bottom: 0;
}

.innerRootDesktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  min-height: 90px;
  padding: 0 29px;

  img {
    height: 33px;
  }

  .navRoot {
    display: flex;
    gap: 10px;
    margin-top: 15px;

    a {
      color: $color-sky-blue-medium;
      font-weight: 600;

      &:after {
        content: ' | ';
        color: #a4a7ae;
        display: inline-flex;
        padding: 0 0 0 10px;
      }

      &:last-of-type {
        &:after {
          content: '';
        }
      }
    }
  }

  @include betaSize {
    display: none;
  }
}

.innerRootMobile {
  display: none;

  @include betaSize {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;
    min-height: 90px;
    padding: 0 39px;
    width: 100%;
    padding-top: 10px;

    .sections {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;

      .top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        display: none;

        a {
          color: $color-sky-blue-medium;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          color: $text-dark-grey;
          margin-top: 5px;
        }

        img {
          height: 36px;
        }
      }
    }
  }
}
