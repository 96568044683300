@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.actionNotificationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 85px;
  margin-top: 20px;

  @include alphaSize {
    margin-top: 20px;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    background-color: $color-warm-orange-medium;
    padding: 10px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    .notificationIcon {
      width: 50px;
    }

    @include alphaSize {
      display: none;
    }
  }

  .bodyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 20px;

    @include alphaSize {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      gap: 10px;
      flex-wrap: wrap;
    }

    .notificationMobile {
      display: none;

      @include alphaSize {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-warm-orange-medium;
        padding: 10px;
        border-radius: 100px;
        margin-top: 10px;

        .notificationIcon {
          width: 40px;
        }
      }
    }

    .text {
      flex: 2;
      font-size: 18px;

      @include alphaSize {
        flex: 1 !important;
        flex-basis: 70% !important;
      }
    }

    .button {
      flex: 1;
      text-align: right;
      margin-right: 20px;

      @include alphaSize {
        margin-top: 10px;
        width: 100% !important;
        margin-left: 70px;
        text-align: left;
      }

      div {
        animation: grow 0.2s ease-out;
      }
    }
  }
}

.loadBtn {
  display: inline-flex !important;
  justify-content: center !important;
  border-radius: 24px !important;
  min-width: 148px !important;
  min-height: 40px !important;
}

.noBtn {
  @include gammaSize {
    margin-top: 20px;
  }
}
