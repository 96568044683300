@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.helpAndSpeakupContainer {
  display: flex;
  flex-direction: column;

  .topContainer {
    display: flex;
    flex-direction: row;

    .hsTitle {
      margin-right: auto;
      @include heading2;
    }

    .hsSkipButton {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .hsBoxContainer {
    padding: 20px;

    .speakUpBox {
      border: 3px solid $color-sky-blue-light;
      display: flex;
      width: 100%;
      padding: 34px 30px;
      flex-direction: column;
      border-radius: 50px;
      flex-basis: 50%;
      background-color: $white;

      .speakUpBoxTopContainer {
        display: flex;
        flex-direction: row;
        height: 140px;
        gap: 10px;

        .titleContainer {
          display: flex;
          flex-direction: column;

          .title {
            @include heading3;
          }
          .description {
            @include bodyCopyLarge;
            margin-top: 30px;
            text-align: left;
          }
        }

        .chatIcon {
          background-image: url('../../../../assets/images/earlyWarning/chat-icon.svg');
          width: 240px;
          height: 240px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top;
        }
      }

      .speakUpBoxTopContainer2 {
        display: flex;
        flex-direction: row;
        height: 140px;
        width: 100%;
        justify-content: space-between;

        .titleContainer {
          .title {
            @include heading3;
          }
        }

        .chatIcon {
          background-image: url('../../../../assets/images/earlyWarning/chat-icon.svg');
          width: 150px;
          height: 150px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top;
          align-content: flex-end;
          align-self: baseline;

          @include betaSize {
            width: 100px;
            height: 100px;
          }
        }
      }

      .expressDescriptionContainer {
        .expressDescription1 {
          @include bodyCopyLarge;
          width: 100%;
          margin-top: -80px;
          @include betaSize {
            @include bodyCopy;
            margin-top: -80px;
          }
        }
        .expressDescription2 {
          @include bodyCopyLarge;
          width: 100%;
          margin-top: 20px;
          @include betaSize {
            @include bodyCopy;
          }

          span {
            color: $color-sky-blue-medium;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;
          }
        }
        .expressDescription3 {
          @include bodyCopyLarge;
          width: 100%;
          margin-top: 20px;
          @include betaSize {
            @include bodyCopy;
          }

          span {
            color: $color-sky-blue-medium;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }

      .formSection {
        margin-top: 10px;
        textarea {
          width: 100%;
          border-radius: 10px;
          padding: 10px;
          resize: none;

          .textArea {
            padding: 10px;
            border: 1px solid $color-putty-dark;
            font-size: 14px;
          }

          .textArea::placeholder {
            color: $color-putty-dark;
          }
        }
      }

      .middleDescriptionContainer {
        margin-top: 20px;
        .middleDescription {
          @include bodyCopyLarge;
          span {
            font-weight: 600;
          }
        }
      }

      .checkBoxWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
      }
      .checkmarkCircle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $color-go-green;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        background-color: $color-go-green;
      }
      .checkmark {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px $color-go-green;
        animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
      }
      .checkmarkCheck {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      }

      @keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes scale {
        0%,
        100% {
          transform: none;
        }
        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
      @keyframes fill {
        100% {
          background-color: $color-go-green;
        }
      }

      .checkContainer {
        margin-top: 20px;
      }

      .roundedCheckbox {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        vertical-align: middle;
        background-color: $color-putty-dark;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        position: relative;
        margin-top: -4px;
      }

      .roundedCheckbox:before {
        content: '\2713';
        font-size: 16px;
        color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .roundedCheckbox:checked {
        clip-path: circle(50% at 50% 50%);
        background-color: $color-sky-blue-medium;
      }

      .roundedCheckbox:checked:before {
        color: $white;
      }

      .checkBoxTitle {
        @include bodyCopyLarge;
        margin-left: 10px;
      }

      .hsGetHelpButton {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .defaultButton {
          background-color: $color-sky-blue-medium;
          border-radius: 24px;
          display: flex;
          height: 45px;
          padding: 9px 44px;
          justify-content: center;
          align-items: center;
          border: none;
          color: #fff;
          @include bodyCopyLarge;
          text-align: center;
          font-feature-settings: 'calt' off;
          font-weight: 600;
          letter-spacing: -0.36px;
          color: $white;
          transition: background-color 0.5s ease;
          width: 50%;

          @include betaSize {
            width: 80%;
          }

          &:hover {
            background-color: $color-sky-blue-light;
          }

          &:disabled {
            background: $text-deactivated;
            opacity: 0.3;
            border: 2px solid $text-deactivated;
          }
        }
      }
    }
  }
}

@include betaSize {
  .helpAndSpeakupContainer {
    width: 100%;

    .hsDescription {
      @include bodyCopy;
    }

    .hsBoxContainer {
      padding: 20px;

      .speakUpBox {
        border-radius: 20px;
        flex-basis: 100%;
        padding: 16px 12px;

        .speakUpBoxTopContainer {
          display: flex;
          flex-direction: row;
          height: 140px;

          .titleContainer {
            display: flex;
            flex-direction: column;

            .title {
              @include subHeading1;
            }
            .description {
              @include bodyCopy;
              width: 130%;
            }
          }
        }

        .middleDescriptionContainer {
          text-align: left;
          .middleDescription {
            @include bodyCopy;
          }
        }

        .checkBoxTitle {
          @include bodyCopy;
        }
      }
    }
  }
}

@include mediaBetween($gamma, $beta) {
  .helpAndSpeakupContainer {
    width: 100%;

    .hsBoxContainer {
      padding: 20px;

      .speakUpBox {
        border-radius: 30px;
        flex-basis: 100%;
        padding: 16px 12px;
        flex-basis: 50%;

        .speakUpBoxTopContainer {
          display: flex;
          flex-direction: row;
          height: 140px;
          gap: 140px;

          .titleContainer {
            display: flex;
            flex-direction: column;

            .description {
              width: 130%;
            }
          }
        }
      }
    }
  }
}

.noDataContainer {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  h3 {
    @include heading2;
    text-align: center;
  }
}

.modalRoot {
  z-index: 999999 !important;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  max-width: 100%;
  // min-height: 450px;
  // max-height: 510px;
  overflow-y: auto;
  background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 10px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 0px;
    min-width: 100%;
    overflow-x: hidden;
    max-height: 100%;
  }
}

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
  padding-top: 10px;
  padding-right: 20px;
  background-color: transparent;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseLabel {
  margin-right: 15px;
  color: $text-dark-grey;

  &:hover {
    font-weight: 600;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $text-dark-grey;
}

.subHeadingForSelect {
  margin-top: -90px;
  margin-bottom: 10px;
  color: $text-dark-grey;
  @include subHeading3;
}

.select {
  margin-bottom: 20px;
  .selectComponent {
    [class$='-control'] {
      width: 100%;
      border: 2px solid $color-putty-dark;
      border-radius: 24px;
      min-height: 38px;
      padding: 4px 15px;

      &:focus {
        border: 2px solid #3fb9fc;
      }

      &:focus-visible {
        outline: 0;
      }

      &:hover {
        border: 2px solid $color-putty-dark;
      }

      &:active {
        border: 2px solid $color-putty-dark;
        box-shadow: none;
      }
    }
  }
}

.placeholders {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  @include subHeading3;
}

.responseLimitExceededContainer {
  margin-top: -70px;
  padding: 20px;
  width: 95%;
  height: 140px;

  p {
    color: $text-dark-grey;
    text-align: center;

    span {
      color: $color-sky-blue-medium;
      cursor: pointer;
      font-weight: 600;
    }

    span:hover {
      color: $color-sky-blue-light;
    }
  }
}
