.hsError {
  color: red;
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}

.inputHolder {
  margin: 20px 0;
}

.buttonHolder {
  margin: 20px 40px;
  button {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .buttonHolder {
    margin: 20px 0px;
  }
}
