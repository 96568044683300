@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

@mixin roundedSqureButton($theme-light-color, $theme-dark-color) {
  border: 1px solid $color-putty-dark;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: white;
  text-align: center;
  min-width: 175px;
  height: 145px;
  transition: transform 0.3s ease-in-out;

  @include alphaSize {
    min-width: 120px;
    height: 125px;
  }

  .topSection {
    margin-bottom: 20px;
    p {
      color: $text-dark-grey;
      @include bodyCopyLarge;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-top: -20px;
    }

    @include alphaSize {
      margin-bottom: 0px;
    }
  }

  .scoreSection {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid $theme-light-color;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: $text-dark-grey;
  }

  &.selected {
    background-color: $theme-light-color;
    border: 1px solid $theme-dark-color;

    .scoreSection {
      background-color: $theme-dark-color;
      border: 3px solid $theme-dark-color;
      color: $white;
    }
  }

  .circularProgress {
    margin-top: -20px;
    // color: $theme-light-color;
  }
}

.roundedSqureButton {
  @include roundedSqureButton($color-putty-light, $color-putty-dark);

  &.sunYellow {
    @include roundedSqureButton($color-sun-yellow-light, $color-sun-yellow-dark);
  }

  &.aquaGreen {
    @include roundedSqureButton($color-aqua-green-light, $color-aqua-green-dark);
  }

  &.calmLilac {
    @include roundedSqureButton($color-calm-lilac-light, $color-calm-lilac-dark);
  }

  &.warmOrange {
    @include roundedSqureButton($color-warm-orange-light, $color-warm-orange-dark);
  }

  &.zingyGreen {
    @include roundedSqureButton($color-zingy-green-light, $color-zingy-green-dark);
  }

  &.skyBlue {
    @include roundedSqureButton($color-sky-blue-light, $color-sky-blue-dark);
  }
}

.roundedSqureButton:hover {
  transform: translateY(-5px);
}
