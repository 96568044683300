@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.loadSpiline {
  height: 380px !important;
  margin-top: 5px !important;
}

.emptyChart {
  padding: 20px;
  cursor: pointer;

  h3 {
    font-size: 20px;
    text-align: center;
    color: $text-dark-grey;
    span {
      color: $color-sky-blue-medium;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .graphImage {
    width: 100%;
    margin-top: 10%;
  }
}

.eChart {
  min-width: 95%;
  max-width: 100%;
  height: 380px !important;

  @include betaSize {
    min-width: 95%;
    max-width: 100%;
    height: 350px !important;
  }
}
