@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsActionsCard {
  display: flex;
  gap: 10px;
  background: #ffffff;
  border: 2px solid transparent;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  align-items: center;
  min-height: 167px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  + {
    .hsActionsCard {
      margin-top: 17px;
    }
  }
  p {
    margin: 0;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    p {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      color: $text-dark-grey;

      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &Content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-grey;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      span {
        display: inline-flex;
        padding: 0 10px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 22px;
        line-height: 20px;
        color: $white;
      }
    }

    &Checkbox {
      width: 100px;
    }
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-calm-lilac-medium;
        }
      }
    }

    .lineBetween {
      background-color: $color-calm-lilac-medium;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sky-blue-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sky-blue-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-sky-blue-medium;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sun-yellow-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-sun-yellow-medium;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-zingy-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $go-green;
        }
      }
    }
    .lineBetween {
      background-color: $color-zingy-green-medium;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-aqua-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-aqua-green-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-aqua-green-medium;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-warm-orange-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-warm-orange-medium;
        }
      }
    }
  }
  .lineBetween {
    background-color: $color-warm-orange-medium;
  }
}

.inputContainer {
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.viewMore {
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
}

.tabContent {
  width: 100%;
  padding: 20px;

  .dateContainer {
    .formattedDate {
      background-color: #828282;
      border-radius: 4px;
      padding: 0 10px;
      margin: 0;
      font-size: 14px;
      color: white;
      width: 100px;
      justify-content: center;
      display: flex;
    }
    .focusArea {
      font-size: 20px;
      font-weight: 600;
      margin-top: 15px;
    }
  }

  .categorySection {
    p {
      font-weight: 400;
    }
  }

  .categorySection2 {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      font-weight: 400;
    }
  }

  .truncateSection {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 16px;
    word-break: break-all;
    -ms-word-break: break-all;
    margin-top: 10px;
    display: inline;
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.lineBetween {
  height: 120px;
  width: 1px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 1px;
  }

  @include mediaBetween(2800px, 1350px) {
    margin-right: -20%;
  }
}
