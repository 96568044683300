@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  min-height: 70vh;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include gammaSize {
      flex-direction: column;
    }

    .pageTitle {
      font-weight: 600;
      color: $text-dark-grey;
      margin-bottom: 40px;
    }

    .showHideCheckbox {
      font-weight: 600;
      color: $color-sky-blue-medium;
    }

    .showHideCheckbox:hover {
      color: $color-sky-blue-light;
    }
  }

  .successStatus,
  .notSuccessStatus {
    width: 120px;
    display: inline-block;
    text-align: center;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
  }

  .successStatus {
    background-color: #04bc00;
    color: $white;
  }

  .notSuccessStatus {
    background-color: $color-warm-orange-medium;
    color: $white;
  }
}

.modalRoot {
  z-index: 99999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 95%;
  max-width: 100%;
  min-height: 50%;
  max-height: 95%;
  overflow-y: auto;
  background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 20px;
    min-width: 90%;
    overflow-x: hidden;
    max-height: 100%;
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }

    @include gammaSize {
      margin-bottom: 20px;
    }
  }

  .btnCloseIcon {
    font-size: 25px;
  }
}
