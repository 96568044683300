.cardContainer {
  width: 730px;
  margin: 20px auto;

  .registrationContentContainer {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    align-content: center;
    justify-content: center;

    .registrationContent {
      width: 100%;

      .organizationLogoHolder {
        width: 100%;
        padding: 10px 20px;
      }

      h1 {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        color: #505050;
        margin-bottom: 40px;
      }

      .registrationDetails {
        width: 100%;
        margin: 0 auto;

        .hsError {
          color: red;
          margin-bottom: 0;
        }

        p {
          margin-bottom: 20px;
        }

        .inputHolder {
          margin: 20px 0;
        }

        .buttonHolder {
          margin: 20px 40px;
          button {
            width: 100%;
            justify-content: center;
          }
        }

        .gotAccountHolder {
          text-align: center;

          p {
            font-weight: 600;
            font-size: 16px;
            color: #505050;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    margin: 20px auto;

    .registrationContentContainer {
      padding: 0;

      .registrationContent {
        .organizationLogoHolder {
          width: 60%;
          text-align: center;
          margin: auto;
        }

        h1 {
          font-size: 32px;
        }

        .registrationDetails {
          width: 95%;
          margin-top: -20px;
        }
      }
    }
  }
}
