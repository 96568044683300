@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.overallDetailsContainer {
  margin-top: 40px;

  .subTitle {
    font-weight: 600;
    color: $text-dark-grey;
    font-family: 'Titillium Web', sans-serif;
  }
  .subDescription {
    color: $text-dark-grey;
    font-size: 14px;
    font-family: 'Titillium Web', sans-serif;
  }

  .actionsCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .actionsSetSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-aqua-green-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }

      .actionsCompletedSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-aqua-green-medium;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          width: 75%;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .learningAndCoachingCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .learningHoursSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-calm-lilac-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }

      .coachingSessionsSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-sky-blue-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .wellbeingHubCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .accessedResourcesSection {
        border-radius: 100px;
        width: 200px;
        height: 200px;
        background-color: $color-warm-orange-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-top: 40px;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 44px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .earlyWarningCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .urgentHelpSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $text-dark-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
        }
      }

      .speakUpSectionSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-turquoise-medium;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          width: 75%;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }
}
