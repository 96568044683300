@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 20px;

  .cardTitle {
    color: $text-dark-grey;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .requestContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    cursor: default;

    .requestItem {
      border: 2px solid $color-sky-blue-medium;
      padding: 20px 20px 20px 20px;
      border-radius: 24px;

      .requestItemHeader {
        display: flex;
        flex-direction: row;
        gap: 30px;

        @include gammaSize {
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .date {
          background-color: $text-deactivated;
          padding: 5px;
          border-radius: 4px;
          width: 100px;
          height: 30px;

          p {
            color: $white;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .name {
          color: $text-dark-grey;
          font-weight: 600;
          align-self: center;
          font-size: 18px;
        }
      }

      .requestItemBody {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 30px;
        flex-wrap: wrap;

        .item {
          display: flex;
          align-items: center;

          .circle {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 3px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $text-dark-grey;
            font-weight: 600;
            margin-right: 10px;
          }

          .text {
            font-size: 16px;
            color: $text-dark-grey;
          }
        }
      }
    }
  }
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .blueTrayImg {
    height: 94px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;

  .loadingItem {
    border-radius: 24px !important;
  }
}
