@import '../../assets/styles/variables';

.hsCircleScoreCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border: 1px solid $text-deactivated;
  border-radius: 30px;
  width: 310px;
  position: relative;

  span.score {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -10px;
    width: 45px;
    height: 45px;
    background: $white;
    color: $text-deactivated;
    border: 1px solid $text-deactivated;
    border-radius: 50%;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Titillium Web', sans-serif;
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;
    border-width: 3px;

    span.score {
      border-color: $color-calm-lilac-dark;
      background: $color-calm-lilac-dark;
      color: $white;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;
    border-width: 3px;

    span.score {
      border-color: $color-sky-blue-dark;
      background: $color-sky-blue-dark;
      color: $white;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;
    border-width: 3px;

    span.score {
      border-color: $color-sun-yellow-dark;
      background: $color-sun-yellow-dark;
      color: $white;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;
    border-width: 3px;

    span.score {
      border-color: $color-zingy-green-dark;
      background: $color-zingy-green-dark;
      color: $white;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;
    border-width: 3px;

    span.score {
      border-color: $color-aqua-green-dark;
      background: $color-aqua-green-dark;
      color: $white;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;
    border-width: 3px;

    span.score {
      border-color: $color-warm-orange-dark;
      background: $color-warm-orange-dark;
      color: $white;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-grey;

    &.content {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 35px;
      font-family: 'Titillium Web', sans-serif;

      i {
        border-radius: 50%;
        color: $white;
      }
    }
  }

  &.up {
    div {
      &.content {
        i {
          background: $go-green;
        }
      }
    }
  }

  &.right {
    div {
      &.content {
        i {
          background: #828282;
        }
      }
    }
  }

  &.down {
    div {
      &.content {
        i {
          background: $error-red;
        }
      }
    }
  }
}
