@import '../../assets/styles/variables';

.container {
  position: relative;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ask {
      cursor: pointer;
    }
  }

  .card {
    padding: 30px;
    width: 437px;
    background: rgba($white, 90%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    position: absolute;
    bottom: -150px;
    z-index: 999;

    .icons {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer;
    }

    .close {
      margin-right: 11px;
    }

    .titleCard {
      font-weight: 600;
      font-size: 24px;
    }
    .descriptionCard {
      font-size: 18px;
      font-weight: 400;
      margin-top: 10px;
    }
    .space {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .empty {
        display: flex;
        flex: 1;
      }
      .button {
        border: 3px solid transparent;
        background: $color-sky-blue-medium;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 50px;
        margin-top: 50px;
        cursor: pointer;
        border-radius: 24px;
        font-size: 18px;
        font-weight: 600;
        transition: all 0.5s;
        line-height: 22px;
        text-decoration: none;
      }
    }
  }

  label {
    margin-bottom: 16px;
  }

  .labelWhite {
    color: #fff;
  }

  .inputPrimary {
    border: 3px solid $color-sky-blue-light;
    font-weight: 600;
    color: $color-sky-blue-light;
    border-radius: 53px;
    width: 100%;
    height: 39px;
    appearance: none;
    background-image: url('../../assets/images/icons/arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    padding-left: 20px;
    cursor: pointer;
  }

  .inputDefault {
    border: 2px solid #ece7dc;
    font-weight: 500;
    color: #505050;
    border-radius: 10px;
    width: 100%;
    height: 55px;
    appearance: none;
    background-image: url('../../assets/images/icons/arrow-dark.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    padding-left: 10px;
    cursor: pointer;
  }

  .error {
    border: 2px solid red;
    font-weight: 400;
    color: gray;
  }
}
