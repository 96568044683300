.hsSingleQuestion {
  text-align: center;
  &Title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;

    span {
      cursor: pointer;
    }
  }
}
