@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: $color-putty-light;
  color: $text-dark-grey;
  padding: 20px;
  cursor: default;
}

.logoWrapper {
  margin-bottom: 20px;

  .logo {
    max-width: 150px;
    width: 100%;
    height: auto;
  }
}

.content {
  .errorCode {
    font-size: 8rem;
    font-weight: bold;
    color: $error-red;
    margin: 0;
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 10px 0;
  }

  .message {
    font-size: 1.2rem;
    color: $text-dark-grey;
    margin: 10px 0 30px;
  }

  .homeLink {
    display: inline-block;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
}
