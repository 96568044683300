@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.hsStepWizardFooterDesktop {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 70px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);

  &SelectedFocusAreas {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      margin: 0;
      color: $text-dark-grey;

      &:first-child {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }

      &:last-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .hsStepWizardFooterInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
  }

  .singleBtnContainer {
    width: 100%;
    text-align: right;
  }

  button {
    min-width: 200px;
    justify-content: center;
  }

  @include gammaSize {
    display: none;
  }
}

.hsStepWizardFooterGamma {
  display: none;
  @include gammaSize {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 10px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    flex-direction: row;

    .hsStepWizardFooterInner {
      width: 90%;
      display: flex;
      flex-direction: row;
    }

    &SelectedFocusAreas {
      display: flex;
      flex-direction: column;
      width: 100%;

      p {
        margin: 0;
        color: $text-dark-grey;

        &:first-child {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }

        &:last-child {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    &Inner {
      display: flex;
      align-items: center;
      width: 100%;
    }

    button {
      display: flex;
      margin: 0 5px;
      align-items: center;
      padding: 10px 50px;
      width: 100%;
      justify-content: center;

      @media screen and (max-width: 768px) {
        padding: 10px;
      }
    }

    .commonBackContainerStyle {
      padding: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .backIconContainerGray {
      border: 3px solid gray;
    }

    .backIconContainerBlue {
      border: 3px solid $color-sky-blue-medium;
    }

    .flexContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .flexColumn {
      flex-direction: column;
    }
  }
}

.hsStepWizardStartFooter {
  background-image: url('../../../../assets/images/footer.svg');
  min-height: 212px;
  width: 100%;
  position: fixed;
  bottom: -120px;
  left: 0;
  padding: 15px 70px;

  @include gammaSize {
    display: none;
  }
}

.focusAreaFooterSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;

    .selected {
      color: $text-dark-grey;
      margin-top: 10px;
      font-size: 18px;

      @include gammaSize {
        font-size: 14px;
      }
    }

    .count {
      color: $text-dark-grey;
      font-weight: 600;
      font-size: 24px;
      margin-top: -15px;

      @include gammaSize {
        font-size: 18px;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @include gammaSize {
      flex-direction: column;
      gap: 10px;
    }
  }
}

.hsStepWizardFooterStart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}
