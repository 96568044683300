@import '../../assets/styles/variables';

.hsCustomCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 5px 8px 5px 16px;
  border: 3px solid transparent;
  border-radius: 30px;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  span {
    margin-left: auto;
    font-size: 14px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-calm-lilac-dark;
        }

        &:checked {
          + {
            label {
              background-color: $color-calm-lilac-dark;
              border-color: $color-calm-lilac-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-sky-blue-dark;
        }
        &:checked {
          + {
            label {
              background-color: $color-sky-blue-dark;
              border-color: $color-sky-blue-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-sun-yellow-dark;
        }
        &:checked {
          + {
            label {
              background-color: $color-sun-yellow-dark;
              border-color: $color-sun-yellow-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-zingy-green-dark;
        }

        &:checked {
          + {
            label {
              background-color: $color-zingy-green-dark;
              border-color: $color-zingy-green-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-aqua-green-dark;
        }
        &:checked {
          + {
            label {
              background-color: $color-aqua-green-dark;
              border-color: $color-aqua-green-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $color-warm-orange-dark;
        }
        &:checked {
          + {
            label {
              background-color: $color-warm-orange-dark;
              border-color: $color-warm-orange-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.grey {
    border-color: $text-dark-grey;

    .round {
      input[type='checkbox'] {
        visibility: hidden;

        + label {
          border-width: 2px;
          border-color: $text-dark-grey;
        }
        &:checked {
          + {
            label {
              background-color: $text-dark-grey;
              border-color: $text-dark-grey;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  label {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:first-child {
      padding-left: 10px;
    }
  }

  .round {
    position: relative;
    width: 20px;
    height: 20px;

    label {
      background-color: $white;
      border: 1px solid $text-deactivated;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      &:after {
        content: '';
        width: 12px;
        height: 6px;
        position: absolute;
        top: 5px;
        left: 3px;
        transform: rotate(-45deg);
        opacity: 0;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
      }
    }
  }
}

.hsCustomCheckbox > .round {
  margin-left: auto;
}
