@charset "UTF-8";

h1,
h2,
h3,
h4 {
  font-style: normal;
  line-height: 1.2;
}

h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  h2 {
    margin-bottom: 30px;
    font-size: 28px;
  }
}

@media (min-width: 1300px) {
  h2 {
    font-size: 34px;
  }
}

.news-main-content {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .container .row {
    flex-direction: column;
  }

  .container .news-main-content {
    column-count: 1 !important;
  }

  .container .col-2,
  .container .col-10 {
    width: 100%;
  }
}

p {
  font-weight: 400;
  color: #a4a7ae;
  font-size: 16px;
}

p strong {
  font-weight: 600;
  color: #000000;
}

.section-arrow {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  z-index: 10;
  cursor: pointer;
}

.section-arrow img {
  display: block;
  height: auto;
  width: 24px;
}

@media (min-width: 768px) {
  .section-arrow img {
    bottom: 40px;
  }
}

@media (min-width: 1300px) {
  .section-arrow img {
    width: 30px;
    bottom: 60px;
  }
}

@media (min-width: 1600px) {
  .section-arrow img {
    bottom: 70px;
  }
}

.logo-bg {
  position: absolute;
  z-index: -1;
}

.logo-bg.left-side {
  top: 30%;
  left: 0;
}

@media (min-width: 992px) {
  .logo-bg.left-side {
    top: 100%;
  }
}

.logo-bg.right-side {
  left: auto;
  right: 0;
  top: 0;
}

.logo-bg img {
  height: 330px;
  width: auto;
}

@media (min-width: 992px) {
  .logo-bg img {
    height: 660px;
  }
}

.bg-content {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.content {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 auto;
  width: 100%;
}

.next-prev {
  display: flex;
  gap: 10px;
}

.blog-nav-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 23px;
  margin-left: auto;
}

@media (min-width: 992px) {
  .blog-nav-toggle {
    display: none;
  }
}

.blog-nav-toggle span {
  display: block;
  width: 100%;
  height: 4px;
  margin-top: -1px;
  background-color: #019cf2;
}

.blog-banner {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

@media (min-width: 992px) {
  .blog-banner.mobile {
    display: none;
  }
}

.blog-banner.padding-bottom {
  padding-bottom: 80px;
}

.blog-banner--transition .banner-image {
  transform: scale(1.1);
}

.blog-banner.text-banner .banner-inner.inner-wrap .banner-content {
  padding: 0;
  height: auto;
}

@media (min-width: 992px) {
  .blog-banner.text-banner .banner-inner.inner-wrap .banner-content {
    height: 200px;
  }
}

.blog-banner.text-banner .banner-inner.inner-wrap .banner-content .text-column {
  width: 100%;
}

@media (min-width: 992px) {
  .blog-banner.text-banner .banner-inner.inner-wrap .banner-content .text-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.blog-banner.text-banner .banner-inner.inner-wrap .banner-content .text-column h1 {
  margin-bottom: 0;
}

.blog-banner.text-banner .banner-inner.inner-wrap .banner-content .text-column p {
  margin-bottom: 15px;
}

.blog-banner .banner-media {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.blog-banner .banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.blog-banner .banner-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-main-wrap {
  margin-top: 40px;
}

.blog-banner .banner-overlay:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
  mix-blend-mode: multiply;
}

.blog-banner .banner-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.blog-banner .banner-inner.inner-wrap .banner-content {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 300px;
  padding: 50px 30px;
}

@media (min-width: 768px) {
  .blog-banner .banner-inner.inner-wrap .banner-content {
    height: 300px;
    padding: 50px 30px;
  }
}

@media (min-width: 992px) {
  .blog-banner .banner-inner.inner-wrap .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0 60px;
    height: 480px;
  }
}

.blog-banner .banner-inner.inner-wrap .banner-content .text-column {
  z-index: 3;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .blog-banner .banner-inner.inner-wrap .banner-content .text-column {
    margin-bottom: 0;
  }
}

.title-cont {
  background-color: rgba(80, 80, 80, 0.5);
  padding: 15px;
  border-radius: 20px;
  min-width: 100%;
  margin-bottom: 20px;
}

.title-cont h1 {
  min-width: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 10px;
}

.blog-banner .banner-inner.inner-wrap .banner-content .text-column h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  transform: translateY(0px);
  color: #ffffff;
  text-align: left;
}

@media (min-width: 992px) {
  .blog-banner .banner-inner.inner-wrap .banner-content .text-column h1 {
    font-size: 50px;
  }
}

.blog-banner .banner-inner.inner-wrap .banner-content .text-column p {
  font-size: 14px;
  font-weight: 400;
  transform: translateY(0px);
  color: #ffffff;
  text-align: left;
  width: 100%;
}

@media (min-width: 992px) {
  .blog-banner .banner-inner.inner-wrap .banner-content .text-column p {
    width: 30%;
  }
}

.blog-banner .banner-inner.inner-wrap .banner-content .text-column p br {
  display: none;
}

@media (min-width: 768px) {
  .blog-banner .banner-inner.inner-wrap .banner-content .text-column p br {
    display: block;
  }
}

.blog-banner .banner-inner.inner-wrap .banner-content .text-column .down-arrow {
  position: relative;
}

.blog-banner .banner-inner.inner-wrap .banner-content .contact-buttons {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.blog-banner .banner-inner.inner-wrap .banner-content .contact-buttons .button {
  background-color: #ffffff;
  border: 0;
}

.blog-banner .banner-inner.inner-wrap .banner-content .contact-buttons .button:first-of-type {
  margin-bottom: 10px;
}

.blog-banner .banner-inner.inner-wrap .banner-content .contact-buttons .button .button-arrow {
  width: 21px;
  height: 19px;
  margin-left: 60px;
}

.blog-banner .banner-inner.inner-wrap .banner-content.news-content {
  border: 3px solid #ffffff;
  align-items: flex-end;
}

.blog-banner .banner-inner.inner-wrap .banner-content.news-content h1 {
  width: 100%;
}

@media (min-width: 1300px) {
  .blog-banner .banner-inner.inner-wrap .banner-content.news-content h1 {
    width: 80%;
  }
}

.blog-banner .banner-content {
  display: block;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .blog-banner .banner-content--narrow {
    width: 83.3334%;
    margin-left: 8.3334%;
  }
}

@media (min-width: 992px) {
  .blog-banner .banner-content--narrow {
    width: 75%;
    margin-left: 12.5%;
  }
}

@media (min-width: 1300px) {
  .blog-banner .banner-content--narrow {
    width: 60%;
    margin-left: 20%;
  }
}

@media (min-width: 1600px) {
  .blog-banner .banner-content--narrow {
    width: 50%;
    margin-left: 25%;
  }
}

.blog-banner .banner-content--dark h1 {
  color: #06bc02 !important;
}

.blog-banner .banner-content--dark p {
  color: #06bc02 !important;
}

.blog-banner .banner-content h1 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.1;
  text-transform: none;
}

@media (min-width: 768px) {
  .blog-banner .banner-content h1 {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .blog-banner .banner-content h1 {
    font-size: 36px;
  }
}

@media (min-width: 1300px) {
  .blog-banner .banner-content h1 {
    font-size: 40px;
  }
}

.blog-banner .banner-content h1 strong {
  font-weight: 600;
}

.blog-banner .banner-content h2 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
}

@media (min-width: 992px) {
  .blog-banner .banner-content h2 {
    font-size: 24px;
  }
}

@media (min-width: 1300px) {
  .blog-banner .banner-content h2 {
    font-size: 28px;
  }
}

.blog-banner .banner-content p {
  margin-top: 15px;
  font-size: 16px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .blog-banner .banner-content p {
    font-size: 18px;
  }
}

.blog-banner .banner-logo {
  display: block;
  width: 70%;
  max-width: 553px;
  height: auto;
  margin: 0 auto;
}

.news {
  position: relative;
  padding: 100px 0 50px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .news {
    padding: 120px 0 80px;
  }
}

@media (min-width: 1300px) {
  .news {
    padding: 120px 0;
  }
}

.news .container {
  position: relative;
  z-index: 2;
}

.news.members-page {
  background-image: none;
  padding: 0 0 60px;
}

@media (min-width: 992px) {
  .news.members-page {
    padding: 0;
  }
}

.news.members-page .heading-wrapper {
  align-items: flex-start;
  padding: 40px;
}

@media (min-width: 992px) {
  .news.members-page .heading-wrapper {
    border-bottom: 1px solid #06bc02;
    padding: 0;
    align-items: center;
  }
}

.news.members-page .heading-wrapper h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  color: #06bc02;
  text-align: left;
}

@media (min-width: 992px) {
  .news.members-page .heading-wrapper h2 {
    font-size: 50px;
  }
}

@media (min-width: 1300px) {
  .news.members-page .heading-wrapper h2 {
    font-size: 60px;
  }
}

.news.members-page .members-news-text {
  width: 100%;
}

@media (min-width: 992px) {
  .news.members-page .members-news-text {
    width: 60%;
  }
}

@media (min-width: 1300px) {
  .news.members-page .members-news-text {
    width: 40%;
  }
}

.news.members-page .members-news-text p {
  color: #06bc02;
  margin: 0;
}

@media (min-width: 992px) {
  .news.members-page .news-items {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: absolute;
    padding-right: 30px;
    left: 120px;
  }
}

.news.members-page .news-items .news-item {
  flex: 0 0 auto;
}

.news.members-page .scroll-text {
  flex: 0 0 120px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  color: #06bc02;
}

@media (min-width: 992px) {
  .news.members-page .scroll-text {
    display: flex;
  }
}

.news.members-page .scroll-text img {
  margin-top: 15px;
  width: 18px;
  height: 18px;
}

@media (min-width: 992px) {
  .news.members-page .scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .news.members-page .scroll::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .news.members-page .scroll::-webkit-scrollbar-thumb {
    background-color: #484c52;
  }

  .news.members-page .scroll::-webkit-scrollbar {
    height: 0;
    background-color: #f5f5f5;
  }
}

.news.members-page .outer-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 400px;
  align-items: center;
}

.news-filters {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

@media (min-width: 768px) {
  .news-filters {
    justify-content: space-between;
    padding: 20px 15px;
  }
}

@media (min-width: 992px) {
  .news-filters {
    padding: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1300px) {
  .news-filters {
    padding: 25px 15px;
  }
}

.news-filter-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.news-filter-main span {
  display: block;
  margin-right: 15px;
  font-size: 14px;
  color: #606262;
  transform: translateY(10px);
}

@media (min-width: 768px) {
  .news-filter-main span {
    margin-right: 20px;
    font-size: 16px;
  }
}

@media (min-width: 1300px) {
  .news-filter-main span {
    margin-right: 30px;
    font-size: 18px;
  }
}

.news-filter-main ul {
  width: 100%;
}

@media (min-width: 992px) {
  .news-filter-main ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.news-filter-main ul li {
  position: relative;
  display: block;
  margin-right: 10px;
  padding-right: 10px;
  transform: translateY(10px);
}

@media (min-width: 768px) {
  .news-filter-main ul li {
    margin-right: 15px;
    padding-right: 15px;
  }
}

.news-filter-main ul li:last-of-type {
  margin-right: 0;
  padding-right: 0;
}

.news-filter-main ul li a {
  font-size: 14px;
  font-weight: 400;
  color: white;
  transition: color 0.3s;
  display: flex;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  transition: color 0.2s;
  padding: 3px 0;
  line-height: normal;
  padding-left: 14px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.news-filter-main ul li a:before {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  display: inline-flex;
  border-radius: 100%;
  margin-top: 0;
  left: 0;
  opacity: 0;
  background: #ffffff;
  transition: opacity 0.4s ease;
  z-index: -1;
}

.news-filter-main ul li a:after {
  display: block;
  content: attr(title);
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.news-filter-main ul li a:hover:before {
  opacity: 1;
}

@media (min-width: 1300px) {
  .news-filter-main ul li a {
    font-size: 16px;
  }
}

.news-filter-main ul li a:hover {
  color: #ffffff;
  font-weight: 700;
}

.news-filter-main ul li a.active,
.news-filter-main ul li a:hover {
  color: #ffffff;
  font-weight: 700;
}

.news-filter-main ul li a.active:before,
.news-filter-main ul li a:hover:before {
  opacity: 1;
}

.news-filter-reset {
  display: block;
  margin-left: 20px;
}

.news-filter-reset a {
  display: block;
  font-size: 14px;
  color: #606262;
  opacity: 0;
  transform: translateY(10px);
}

@media (min-width: 768px) {
  .news-filter-reset a {
    font-size: 16px;
  }
}

@media (min-width: 1300px) {
  .news-filter-reset a {
    font-size: 18px;
  }
}

.news-items {
  display: block;
  width: 100%;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .news-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 10px;
  }
}

@media (min-width: 1300px) {
  .news-items {
    margin-top: 20px;
  }
}

.share-icon {
  width: 23px;
  height: 23px;
  right: 45px;
  top: 30px;
  z-index: 3;
  cursor: pointer;
}

@media (min-width: 768px) {
  .share-icon {
    top: 45px;
    width: 23px;
    height: 23px;
    transition: top 0.3s;
  }
}

.share-icon img {
  width: 23px;
  height: 23px;
}

@media (min-width: 768px) {
  .share-icon img {
    width: 23px;
    height: 23px;
  }
}

.share-icon.hidden {
  opacity: 0;
  z-index: auto;
}

.share-close {
  display: block;
  position: absolute;
  top: 30px;
  width: 28px;
  height: 28px;
  z-index: 11;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.share-close span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 66%;
  height: 2px;
  background-color: #ffffff;
}

.share-close span:first-of-type {
  transform: translate(-50%, -50%) rotate(45deg);
}

.share-close span:last-of-type {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.share {
  display: none;
  opacity: 0;
  transition: opacity 1.2s;
}

.share.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0px;
  bottom: 0;
  left: 0px;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: opacity 1.2s;
}

.share.active h2 {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 16px;
}

.share.active .icon-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.share a {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 11px;
  color: #fff;
  opacity: 1;
  transition: opacity 0.15s linear;
}

@media (min-width: 768px) {
  .share a {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}

.share .fa,
.share .fab,
.share .fad,
.share .fal,
.share .far,
.share .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: normal;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.news-item {
  display: block;
  width: 100%;
  margin-top: 15px;
  padding: 0 15px;
  text-align: left;
  transform: translateY(10px);
  position: relative;
}

@media (min-width: 768px) {
  .news-item {
    width: 50%;
    margin-top: 0;
    padding: 15px;
  }
}

@media (min-width: 992px) {
  .news-item {
    width: 33.3334%;
  }
}

.news-item.hidden {
  display: none;
}

.view-authors a {
  display: block;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  transition: color 0.2s;
  padding: 5px 20px;
  font-weight: 700;
  background-color: #0072b0;
  border: 2px solid #0072b0;
  color: #ffffff;
  border-radius: 200px;
}

@media (min-width: 992px) {
  .view-authors a {
    font-size: 15px;
  }
}

@media (min-width: 1300px) {
  .view-authors a {
    font-size: 18px;
  }
}

.view-authors a span {
  margin-right: 30px;
}

.news-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 300px;
  width: 100%;
  padding: 30px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  overflow: hidden;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  z-index: 1;
  border: 3px solid #ffffff;
}

@media (min-width: 768px) {
  .news-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .news-inner {
    padding: 30px;
  }
}

@media (min-width: 1300px) {
  .news-inner {
    padding: 30px;
  }
}

@media (min-width: 1600px) {
  .news-inner {
    padding: 30px;
    min-height: 300px;
  }
}

.news-inner .news-button,
.news-inner .news-text {
  position: relative;
  z-index: 4;
}

.news-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  transform: scale(1.1);
}

.news-image:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  mix-blend-mode: multiply;
}

.news-border {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 120px 120px 0;
  border-color: transparent #484c52 transparent transparent;
  z-index: 2;
  transform: translate(100%, -100%);
}

@media (min-width: 1300px) {
  .news-border {
    border-width: 0 140px 140px 0;
  }
}

.news-text {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .news-text {
    flex: 1;
  }
}

.news-text > * {
  transform: translateY(10px);
}

.news-heading {
  margin-top: 25px;
}

.news-text h3 {
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 2px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

.news-text h2 {
  margin-bottom: 30px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.1;
}

.news-text p {
  margin-top: 15px;
  font-size: 15px;
  color: #484c52;
}

@media (min-width: 768px) {
  .news-text p {
    font-size: 16px;
  }
}

.news-text p:first-of-type {
  margin-top: 0;
}

.news-button {
  display: block;
  width: 87px;
  height: 72px;
  opacity: 1;
  transform: translateY(0px);
  position: absolute !important;
  right: 0;
  bottom: 0;
}

.news-button svg {
  width: 87px;
  height: 72px;
}

.news-button.active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1.4s, transform 1.4s, border-color 0.3s, background-color 0.3s;
}

.news-item:nth-child(6n + 1) .blog-arrow-bg {
  fill: #019df2 !important;
}

.news-item:nth-child(6n + 2) .blog-arrow-bg {
  fill: #fcd300 !important;
}

.news-item:nth-child(6n + 3) .blog-arrow-bg {
  fill: #06bf97 !important;
}

.news-item:nth-child(6n + 4) .blog-arrow-bg {
  fill: #ff8a4a !important;
}

.news-item:nth-child(6n + 5) .blog-arrow-bg {
  fill: #8eea36 !important;
}

.news-item:nth-child(6n + 6) .blog-arrow-bg {
  fill: #beaaf3 !important;
}

.news-load {
  display: block;
  width: 100%;
  margin-top: 50px;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .news-load {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .news-load {
    margin-top: 120px;
  }
}

.news-load .button {
  padding: 10px 30px;
  background-color: #ffffff;
}

.news-intro.image-caption {
  font-size: 13px;
  text-align: left;
  font-weight: 300;
  padding: 15px 0;
}

@media (min-width: 768px) {
  .news-intro.image-caption {
    padding: 25px 0;
  }
}

@media (min-width: 1300px) {
  .news-intro.image-caption {
    padding: 40px 0;
  }
}

.news-intro-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 30px 0;
  border-bottom-right-radius: 100px;
}

.content-wrapper {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px;
}

.flexible-content-area {
  padding: 20px;
}

@media (min-width: 992px) {
  .news-intro-content {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  .news-intro-content img {
    max-width: -webkit-fill-available;
    height: auto;
  }
}

.video-content {
  width: 100%;
  margin-top: 40px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-intro-aside {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 992px) {
  .news-intro-aside {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 20%;
    text-align: left;
    height: 100%;
  }
}

.news-intro-aside .button {
  width: 100%;
  margin-top: 100px;
}

.news-intro-aside .button-arrow {
  width: 20px;
  height: 20px;
}

.news-intro-aside .top-wrapper {
  width: 100%;
}

.news-intro-aside .team-blog-list {
  width: 100%;
  margin-bottom: 50px;
}

.news-intro-aside .team-blog-list .author {
  width: 70%;
}

@media (min-width: 992px) {
  .news-intro-aside .team-blog-list .author {
    width: 100%;
  }
}

@media (min-width: 1300px) {
  .news-intro-aside .team-blog-list .author {
    width: 80%;
  }
}

.news-intro-text .button {
  margin-top: 30px;
}

.navigation-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 15px;
}

.card-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 768px) {
  .card-section {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 20px !important;
  }
}

.card-section-v2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

@media (max-width: 768px) {
  .card-section-v2 {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 20px !important;
  }
}

.primary-action-btn {
  background: #019df2;
  color: white;
  padding: 9px 44px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid #019df2;
  margin: 10px 0 20px 0;
}

.primary-action-btn:hover {
  background: #3fb9fc;
  border: 2px solid #3fb9fc;
}

.primary-action-btn:disabled {
  background: #828282;
  opacity: 0.3;
  border: 2px solid #828282;
  cursor: not-allowed;
}

.video-content {
  margin-top: -40px !important;
  margin-bottom: 40px !important;
}

@media (max-width: 768px) {
  .video-content {
    margin-top: 0px !important;
    margin-bottom: 40px !important;
  }
}

.content-style-01 {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
  column-count: 1;
  column-gap: 30px;
  line-height: 26px;
}

.content-style-01 strong {
  font-weight: 500;
}

@media (max-width: 768px) {
  .content-style-01 {
    column-count: 1 !important;
    column-gap: 0px !important;
  }
}

.content-style-02 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.content-style-02 .item-01 {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

.content-style-02 .item-02 {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

.content-style-base {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media (max-width: 768px) {
  .content-style-base {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 0 !important;
  }
}

.content-style-base .flex-col-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .content-style-base .flex-col-section {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.content-style-base .flex-row-section {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .content-style-base .flex-row-section {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.content-style-base-02 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 768px) {
  .content-style-base-02 {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 0 !important;
  }
}

.content-style-base-02 .flex-col-section1,
.flex-col-section2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .content-style-base-02 .flex-col-section1 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@media (max-width: 768px) {
  .content-style-base-02 .flex-col-section2 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.content-style-base-02 .flex-row-section {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .content-style-base-02 .flex-row-section {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.loading-learn-text {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.loading-post-card {
  border-radius: 24px !important;
}
