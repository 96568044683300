.hsStar {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .hsStar {
    justify-content: center;
  }
}
