.root {
  padding: 50px 0;
}

.title {
  margin-bottom: 50px;
}

.btnFooter {
  margin-top: 50px;
}

.successAlertContainer {
  background-color: #d1e7dd;
  border: 1px solid #a3cfbb;
  border-radius: 0.375rem;
  padding: 15px;
  margin-bottom: 15px;
}

.errorAlertContainer {
  background-color: #f8d7da;
  border: 1px solid #f1aeb5;
  border-radius: 0.375rem;
  padding: 15px;
  margin-bottom: 15px;
}
