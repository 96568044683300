@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

@mixin roundedSqureButton($theme-light-color, $theme-medium-color, $theme-dark-color) {
  border: 1px solid $color-putty-dark;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: $color-putty-dark;
  text-align: center;
  min-width: 175px;
  height: 165px;
  transition: transform 0.3s ease-in-out;

  @include mediaBetween(1399px, 1200px) {
    min-width: 150px;
  }

  @include mediaBetween(1199px, 900px) {
    min-width: 130px;
  }

  @include alphaSize {
    min-width: 150px;
    height: auto;
  }

  .topSection {
    margin-bottom: 20px;
    p {
      color: $text-dark-grey;
      @include bodyCopyLarge;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-top: -20px;
    }

    @include alphaSize {
      margin-bottom: 0px;
    }
  }

  .scoreSection {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid $theme-light-color;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: $text-dark-grey;
  }

  &.selected {
    background-color: $theme-light-color;
    border: 1px solid $theme-dark-color;

    .scoreSection {
      background-color: $theme-dark-color;
      border: 3px solid $theme-dark-color;
      color: $white;
    }
  }

  .circularProgress {
    margin-top: -20px;
  }

  .progressCircle {
    display: inline-block;
    position: relative;
    margin-top: -30px;

    @include alphaSize {
      margin-top: 5px;
    }

    &Svg {
      // transform: rotate(-90deg);
    }

    &Bg {
      fill: none;
      stroke: #ffffff;
      stroke-width: 3;
    }

    &Fg {
      fill: none;
      stroke: $theme-dark-color;
      stroke-width: 3;
      transition: stroke-dashoffset 0.3s;
    }

    &Text {
      font-size: 14px;
      fill: #333;
    }
  }

  .playContainer {
    margin-top: -45px;
    border: 2px solid $theme-medium-color;
    width: 30px;
    border-radius: 100px;
    margin-left: 52px;

    @include mediaBetween(1399px, 1200px) {
      margin-left: 40px;
    }

    @include mediaBetween(1199px, 900px) {
      margin-left: 30px;
    }

    @include alphaSize {
      margin-bottom: 16px;
      margin-left: 40px;
    }

    .playIcon {
      color: $theme-medium-color;
    }
  }
}

.roundedSqureButton {
  @include roundedSqureButton($color-putty-light, $color-putty-medium, $color-putty-dark);

  &.sunYellow {
    @include roundedSqureButton(
      $color-sun-yellow-light,
      $color-sun-yellow-medium,
      $color-sun-yellow-dark
    );
  }

  &.aquaGreen {
    @include roundedSqureButton(
      $color-aqua-green-light,
      $color-aqua-green-medium,
      $color-aqua-green-dark
    );
  }

  &.calmLilac {
    @include roundedSqureButton(
      $color-calm-lilac-light,
      $color-calm-lilac-medium,
      $color-calm-lilac-dark
    );
  }

  &.warmOrange {
    @include roundedSqureButton(
      $color-warm-orange-light,
      $color-warm-orange-medium,
      $color-warm-orange-dark
    );
  }

  &.zingyGreen {
    @include roundedSqureButton(
      $color-zingy-green-light,
      $color-zingy-green-medium,
      $color-zingy-green-dark
    );
  }

  &.skyBlue {
    @include roundedSqureButton(
      $color-sky-blue-light,
      $color-sky-blue-medium,
      $color-sky-blue-dark
    );
  }
}

.roundedSqureButton:hover {
  transform: translateY(-5px);
}
