@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsActionsCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: #ffffff;
  border: 3px solid transparent;
  border-radius: 30px;

  .hsButtonContainer {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: center;

    button {
      width: 40%;
      min-width: max-content;
      padding: 10px 20px;
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
      }
    }
  }

  + {
    .hsActionsCard {
      margin-top: 17px;
    }
  }
  p {
    margin: 0;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $text-dark-grey;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &Content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-grey;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      span {
        display: inline-flex;
        padding: 0 10px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $white;
      }
    }

    &Checkbox {
      width: 100px;
    }
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-calm-lilac-medium;
        }
      }
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sky-blue-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sky-blue-medium;
        }
      }
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sun-yellow-medium;
        }
      }
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-zingy-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $go-green;
        }
      }
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-aqua-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-aqua-green-medium;
        }
      }
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-warm-orange-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-warm-orange-medium;
        }
      }
    }
  }
}

.speakupContainer {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: flex-end;

  .isSpeakupText {
    flex-basis: 49%;
    @include subHeading2;
    margin-top: 5px;

    @include gammaSize {
      @include subHeading3;
    }
  }

  .chatIcon {
    flex-basis: 49%;
    max-width: 60px;

    @include gammaSize {
      max-width: 50px;
    }
  }

  @include gammaSize {
    width: 150px;
  }
}

.hsActionsCardContent {
  width: 100%;
  word-break: break-all;
}

.cardQuestName {
  padding: 0px 10px !important;
  gap: 10px !important;
  background-color: #0072b0;
  border-radius: 4px !important;
  color: white !important;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.cardFontSettings {
  font-family: 'Titillium Web' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100% !important;
  word-break: break-all !important;
}

.mainTitleX {
  font-weight: 600 !important;
  color: #505050 !important;
  font-size: 20px !important;
}

.cardCommentSectionX {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 20px !important;
  padding: 21px 27px !important;
  border: 3px solid #505050 !important;
  margin: 15px 0 !important;
}

.cardResponseSection {
  margin-top: 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #505050 !important;
  word-break: break-all !important;
}

p .speakupContainerStar {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: flex-end;

  .chatIcon {
    flex-basis: 49%;
    max-width: 60px;

    @include gammaSize {
      max-width: 50px;
    }
  }

  @include gammaSize {
    width: 150px;
  }
}
