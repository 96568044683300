@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.sessions {
  display: flex;
  flex-direction: column;
}

.sessionInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;

  @include gammaSize {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 30%;

    @include gammaSize {
      flex-direction: column;
      gap: 20px;
      width: auto;
    }

    .expertImage {
      width: 70px;
      border-radius: 100px;
    }

    span {
      color: $color-sky-blue-medium;
      font-weight: 600;
    }
  }

  .middle {
    margin-left: -100px;
    padding-top: 15px;
    @include gammaSize {
      text-align: left;
      margin-left: 0;
      padding-top: 0;
    }

    .bookingContainer {
      display: table;
      border-radius: 4px;
      width: fit-content;
    }

    .row {
      display: table-row;
    }

    .cell {
      display: table-cell;
      padding: 2px 20px;
      border: 1px solid $color-putty-dark;
      vertical-align: middle;
      min-width: 200px;
      max-width: 220px;

      span {
        color: $text-dark-grey;
        text-transform: capitalize;
        font-size: 14px;

        @include gammaSize {
          font-size: 12px;
        }
      }

      @include gammaSize {
        width: 300px;
        min-width: 100%;
        max-width: 100%;
        padding: 2px 10px;
      }
    }

    .icon {
      margin-right: 10px;
      width: 20px;
      vertical-align: middle;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    gap: 10px;

    @include gammaSize {
      margin-top: -20px;
      margin-right: 0;
      justify-content: center;
    }

    .sessionCompleted {
      color: $go-green;
    }

    .sessionPending {
      color: $color-warm-orange-medium;
    }

    .sessionFailure {
      color: $error-red;
    }
    .icon {
      width: 20px;
      margin-top: -15px;

      @include gammaSize {
        margin-top: -17px;
      }
    }
  }
}

.divider {
  margin-bottom: 20px;
  margin-top: -5;
  border: none;
  height: 2px;
  background-color: $color-putty-dark;

  @include gammaSize {
    margin-bottom: 30px;
    margin-top: -40px;
  }
}

.skeleton {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  border-radius: 20px !important;
  width: 100% !important;
  height: 120px !important;

  @include gammaSize {
    height: 300px !important;
  }
}

.sessionHistoryContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 36px;
  margin-bottom: -56px;

  @include gammaSize {
    max-width: 100%;
    padding: 20px;
    margin-top: 25px;
    margin-bottom: 0;
  }

  .historyHeader {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include gammaSize {
      align-items: center;
      flex-direction: column-reverse;
      gap: 10px;
    }

    h5 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 600;
      color: $text-dark-grey;

      @include gammaSize {
        font-size: 1.1rem;
        margin-top: -20px;
      }
    }

    .btnClose {
      width: 100%;
      display: inline-flex;
      justify-content: end;
      align-items: center;
      max-width: 100px;
      button {
        border: none;
        padding: 0;
        display: inline-flex;
        background-color: transparent;
      }
      :hover {
        font-weight: 600;
      }

      @include gammaSize {
        align-self: flex-end;
        margin-bottom: 20px;
      }
    }

    .btnCloseIcon {
      font-size: 25px;
    }
  }

  p {
    color: $text-dark-grey;
    text-align: center;
    font-size: 16px;
    margin-bottom: 60px;
    margin-top: 40px;
  }
}
