@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsAuthLayout {
  position: relative;

  .hsAuthLayoutContent {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    min-height: calc(100vh - 266px);
    display: flex;
    justify-content: center;
    align-items: center;

    @include betaSize {
      min-height: calc(100vh - 270px);
    }

    @media (max-width: 375px) and (max-height: 667px) {
      min-height: calc(100vh - 170px) !important;
    }
  }
}

.hsFooterMobile {
  background-color: #d4c6f9;
}
