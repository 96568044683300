@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.overallDetailsContainer {
  margin-top: 40px;

  .subTitle {
    font-weight: 600;
    color: $text-dark-grey;
    font-family: 'Titillium Web', sans-serif;
  }
  .subDescription {
    color: $text-dark-grey;
    font-size: 14px;
    font-family: 'Titillium Web', sans-serif;
  }

  .actionsCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .actionsSetSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-aqua-green-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }

      .actionsCompletedSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-aqua-green-medium;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          width: 75%;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .learningAndCoachingCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .learningHoursSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-calm-lilac-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }

      .coachingSessionsSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-sky-blue-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .wellbeingHubCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .accessedResourcesSection {
        border-radius: 100px;
        width: 200px;
        height: 200px;
        background-color: $color-warm-orange-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-top: 40px;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 44px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }

  .earlyWarningCardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 380px;

    .roundedCountSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .urgentHelpSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $text-dark-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          text-align: center;
          width: 75%;
          font-family: 'Titillium Web', sans-serif;
        }
      }

      .speakUpSectionSection {
        border-radius: 100px;
        width: 130px;
        height: 130px;
        background-color: $color-turquoise-medium;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        h5 {
          color: $white;
          font-weight: 600;
          font-size: 34px;
          margin-top: 10px;
          font-family: 'Titillium Web', sans-serif;
        }

        p {
          color: $white;
          width: 75%;
          text-align: center;
          font-family: 'Titillium Web', sans-serif;
        }
      }
    }
  }
}

.tabsContainer {
  position: relative;
  z-index: 1;
  margin-top: -20px;

  @include gammaSize {
    display: none;
  }
}

.starLoadingContainer {
  display: flex !important;
  justify-content: center !important;
  margin-top: -50px;
  padding-bottom: 200px;

  .starLoadingItem {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .starLoadingSquareItem {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    margin-left: 50px;

    .radarChartSkeleton {
      width: 222px !important;
      height: 222px !important;
      transform: rotate(45deg) !important;
    }
  }
}

.hsSubNavigation {
  display: none;

  @include gammaSize {
    display: block;

    ul {
      list-style-type: none;
      flex-direction: column;
      padding: 37px 30px;
      background: $color-sky-blue-dark;
      position: absolute;
      width: 95%;
      z-index: 12;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 15px;

      .show {
        opacity: 0;
      }

      li {
        width: 100%;
        color: $white;
        border-bottom: none;
        cursor: pointer;
        transition: all 0.5s;

        a {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          font-family: 'Titillium Web', sans-serif;
        }

        a {
          padding: 14px 0;
        }

        &.active {
          background: transparent;
          border-bottom: none;

          a {
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: $color-sun-yellow-medium;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.deepDiveRadarContainer {
  background-color: $white;
  border-radius: 0 30px 30px 30px;
  padding-top: 80px;
  position: relative;
  z-index: 2;

  @include gammaSize {
    border-radius: 0 0 30px 30px;
  }
}

.circleScoreCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-bottom: 100px;
}

.noDataCont {
  color: black;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  height: 100vh;
}

.regUsersCount {
  margin-top: 20px;
  margin-bottom: -20px;

  h6 {
    color: $white;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }
}
