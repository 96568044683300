@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixns';

.feelsImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -30px;

  .feelsImage {
    width: 50px;
    cursor: default;
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: wave 2s infinite;
  }
}

.reflectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .considerText {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: $text-dark-grey;
  }

  .awesomeOutlined {
    width: 30px;
  }

  .description {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    color: $text-dark-grey;

    @media only screen and (max-device-width: 399px) and (max-device-height: 990px) {
      margin-top: 5px;
    }

    @media only screen and (min-device-width: 400px) and (max-device-height: 990px) {
      margin-top: 5px;
    }

    @include alphaSize {
      margin-top: 5px;
    }
  }

  .inputContainer {
    margin-top: 20px;
    width: 100%;

    @media only screen and (max-device-width: 399px) and (max-device-height: 990px) {
      margin-top: 10px;
    }

    @media only screen and (min-device-width: 400px) and (max-device-height: 990px) {
      margin-top: 10px;
    }

    @include alphaSize {
      margin-top: 10px;
    }
  }

  .continueBtn {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .hsPrimaryButton {
      background: $color-sky-blue-medium;
      color: $white;
      padding: 9px 44px;
      display: inline-flex;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
      border: 2px solid $color-sky-blue-medium;
      width: 70%;
      text-transform: uppercase;

      &:hover {
        background: $color-sky-blue-light;
        border: 2px solid $color-sky-blue-light;
      }

      &:disabled {
        background: $text-deactivated;
        opacity: 0.3;
        border: 2px solid $text-deactivated;
      }

      @include alphaSize {
        width: 100%;
      }
    }
  }

  .skipBtn {
    color: $color-sky-blue-medium;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
      color: $color-sky-blue-light;
    }
  }

  .helpText {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    color: $text-dark-grey;

    @media only screen and (max-device-width: 399px) and (max-device-height: 990px) {
      margin-top: 20px;
    }

    @media only screen and (min-device-width: 400px) and (max-device-height: 990px) {
      margin-top: 20px;
    }

    @include alphaSize {
      margin-top: 20px;
    }
  }

  .helpBtn {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .hsHelpButton {
      background: $color-warm-orange-medium;
      color: $white;
      padding: 9px 44px;
      display: inline-flex;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
      border: 2px solid $color-warm-orange-medium;
      width: 70%;
      text-transform: uppercase;

      &:hover {
        background: $color-warm-orange-light;
        border: 2px solid $color-warm-orange-light;
      }

      &:disabled {
        background: $text-deactivated;
        opacity: 0.3;
        border: 2px solid $text-deactivated;
      }

      @include alphaSize {
        width: 100%;
      }
    }
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

p.hsCharCounter {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: $text-dark-grey;
  margin: 2px 6px 0 0;
}
