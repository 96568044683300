@import '../../../../../assets/styles/variables';

.hsHeader {
  position: relative;
  background: $white;
  padding: 15px 70px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.5s;

  .hsNav {
    display: flex;
    justify-content: center;

    .cont {
      display: flex;
      width: 100%;

      .navItemContainer {
        cursor: pointer;
      }

      .hsImgContainer {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;

        .img {
          height: 44px;
        }

        .imgFull {
          height: 44px;
          cursor: pointer;
        }
      }

      .orgImgContainer {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;

        .img {
          height: 44px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 66px;
      @media screen and (max-width: 768px) {
        margin: 0;
        justify-content: center;
      }

      li {
        a {
          color: $text-dark-grey;
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .hsNav {
      .cont {
        .hsImgContainer {
          .img {
            height: 30px;
          }
        }

        .orgImgContainer {
          .img {
            height: 30px;
          }
        }
      }

      ul {
        margin: 0;
        gap: 30px;
      }
    }
  }

  @media screen and (max-width: 835px) {
    padding: 15px 50px;

    .hsNav {
      ul {
        margin: 0;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hsHeader {
    .cont {
      display: flex;
      justify-content: center;
    }
    .hsNav {
      flex-direction: column;
      ul {
        gap: 20px;
      }
    }
  }
}
