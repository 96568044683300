@import "../../assets/styles/variables";

.hsStarCompleted {
  .hsStarCompletedCol {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
      h2 {
        margin-bottom: 20px;
      }
    }


    &:last-of-type {
      position: relative;
      align-items: center;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 64px;
        line-height: 72px;
        color: $text-dark-grey;

        @media screen and (max-width: 768px) {
          font-size: 40px;
          line-height: 40px;
        }
      }

      img {
        width: 500px;
      }
    }
  }
}
