.mainLayout {
  background: transparent;

  @media screen and (max-width: 992px) {
    padding-bottom: 75px;
  }

  .boxed {
    width: 1440px;
    max-width: 1440px;
    margin: 0 auto;

  }
}


@media screen and (max-width: 768px) {
  .mainLayout {
    main {
      padding: 0;
    }
  }
}
