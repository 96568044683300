@import '../../assets/styles/variables';

.hsHeaderWizardContainer {
  display: flex;
  margin: 0 auto;
}

.hsHeaderWizard {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 30px;
  li {
    a {
      display: flex;
      align-items: center;
      gap: 18px;
      color: $text-deactivated;
      text-decoration: none;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        font-size: 24px;
        background: $color-putty-dark;
        border-radius: 50%;

        &:after {
          content: attr(data-step);
        }
      }

      &.active {
        color: $text-dark-grey;

        span {
          color: $text-dark-yellow;
          background: $color-sun-yellow-medium;
        }
      }

      &.completed {
        color: $text-dark-grey;

        span {
          color: $text-dark-yellow;
          background: $go-green;
          position: relative;

          &:after {
            content: '';
            width: 20px;
            height: 10px;
            transform: rotate(-45deg);
            border: 3px solid #fff;
            border-top: none;
            border-right: none;
            position: absolute;
            top: -4px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .hsHeaderWizardContainer {
      height: 30px;
    }

    .hsHeaderWizard {
      gap: 30px;
      li {
        a {
          span {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hsHeaderWizardContainer {
    position: unset;
    margin: 0;
    display: flex;
    width: 100px;
  }

  .hsHeaderWizard {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 10px;
    li {
      a {
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        color: $text-deactivated;
        text-decoration: none;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          font-size: 14px;
          background: $color-putty-dark;
          border-radius: 50%;

          &:after {
            content: attr(data-step);
          }
        }

        &.active {
          color: $text-dark-grey;

          span {
            color: $text-dark-yellow;
            background: $color-sun-yellow-medium;
          }
        }

        &.completed {
          color: $text-dark-grey;

          span {
            color: $text-dark-yellow;
            background: $go-green;
            position: relative;

            &:after {
              content: '';
              width: 20px;
              height: 10px;
              transform: rotate(-45deg);
              border: 3px solid #fff;
              border-top: none;
              border-right: none;
              position: absolute;
              top: -4px;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
}
