@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.boostersAndDrainersCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;

  .cardTitle {
    color: $text-dark-grey;
    font-weight: 600;
    margin-top: 10px;
  }
}

.mainContainer {
  margin-top: 40px;
  margin-bottom: 40px;

  .cardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 470px;

    .subTitle {
      font-weight: 600;
      color: $text-dark-grey;
      font-family: 'Titillium Web', sans-serif;
    }
    .subDescription {
      color: $text-dark-grey;
      font-size: 14px;
      height: 20px;
      font-family: 'Titillium Web', sans-serif;
    }

    .boostersList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
      width: 100%;

      .itemContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $color-calm-lilac-dark;
        padding: 10px 20px 10px 20px;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
        height: 45px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        p {
          color: $white;
        }

        .circle {
          background-color: $white;
          border-radius: 100px;
          padding: 2px;
          width: 26px;
          text-align: center;

          p {
            margin-top: -4px;
            font-weight: 600;
          }
        }

        .liliac {
          border: 2px solid $color-calm-lilac-medium;

          p {
            color: $color-calm-lilac-dark;
            margin-top: -4px;
            font-weight: 600;
          }
        }

        .orange {
          border: 2px solid $color-warm-orange-medium;

          p {
            color: $color-warm-orange-dark;
            margin-top: -4px;
            font-weight: 600;
          }
        }
      }

      .orange {
        background-color: $color-warm-orange-dark;
      }
    }

    .drainersList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;

      .itemContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $color-sun-yellow-dark;
        padding: 10px 20px 10px 20px;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
        height: 45px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        p {
          color: $white;
        }

        .circle {
          background-color: $white;
          border-radius: 100px;
          padding: 2px;
          width: 26px;
          text-align: center;

          p {
            margin-top: -4px;
            font-weight: 600;
          }
        }

        .yellow {
          border: 2px solid $color-sun-yellow-medium;

          p {
            color: $color-sun-yellow-dark;
            margin-top: -4px;
            font-weight: 600;
          }
        }
      }
    }

    .careerBoostersList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      p {
        background-color: $color-warm-orange-dark;
        padding: 10px 20px 10px 20px;
        color: $white;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
      }
    }

    .careerDrainersList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;

      .itemContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $color-sun-yellow-dark;
        padding: 10px 20px 10px 20px;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
        height: 45px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        p {
          color: $white;
        }

        .circle {
          background-color: $white;
          border-radius: 100px;
          padding: 2px;
          width: 26px;
          text-align: center;

          p {
            margin-top: -4px;
            font-weight: 600;
          }
        }

        .yellow {
          border: 2px solid $color-sun-yellow-medium;

          p {
            color: $color-sun-yellow-dark;
            margin-top: -4px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.loadingItem {
  border-radius: 24px !important;
}

.emptyState {
  text-align: center;
  margin-top: 50%;
  font-size: 20px;
  color: $text-dark-grey;
}

.itemContent:hover {
  transform: translateY(-2px) scale(1.1);
  transition: transform 0.3s ease;
}
