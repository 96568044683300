@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixns';

.step3Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .titleContainer {
    position: relative;
    text-align: center;
    margin-top: -120px;
  }

  .predictSection {
    margin-top: -80px;
    text-align: center;
    margin-bottom: 40px;
    h5 {
      span {
        font-weight: 600;
      }
    }
  }

  .continueBtn {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .hsPrimaryButton {
      background: $color-sky-blue-medium;
      color: $white;
      padding: 9px 44px;
      display: inline-flex;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
      border: 2px solid $color-sky-blue-medium;
      width: 70%;
      text-transform: uppercase;

      &:hover {
        background: $color-sky-blue-light;
        border: 2px solid $color-sky-blue-light;
      }

      &:disabled {
        background: $text-deactivated;
        opacity: 0.3;
        border: 2px solid $text-deactivated;
      }
    }
  }

  .helpBtn {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .hsHelpButton {
      background: $color-warm-orange-medium;
      color: $white;
      padding: 9px 44px;
      display: inline-flex;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
      border: 2px solid $color-warm-orange-medium;
      width: 70%;
      text-transform: uppercase;

      &:hover {
        background: $color-warm-orange-light;
        border: 2px solid $color-warm-orange-light;
      }

      &:disabled {
        background: $text-deactivated;
        opacity: 0.3;
        border: 2px solid $text-deactivated;
      }
    }
  }
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 60px;

  .checkmarkCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $color-go-green;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    background-color: $color-go-green;
  }
  .checkmark {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px $color-go-green;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  }
  .checkmarkCheck {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      background-color: $color-go-green;
    }
  }
}

.questionsCardSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  .card {
    border-radius: 24px !important;
    overflow: hidden;
    position: relative;
    height: 100px !important;
    border: none !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out;
    text-decoration: none;
    background-color: $white;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: default;
  }

  .card:hover {
    transform: translateY(-10px);
  }

  .card .title {
    background-color: #fff;
    text-align: left;
  }

  .card .title h2 {
    font-size: 16px;
    font-weight: 600;
    color: $text-dark-grey;
  }

  .circledScore {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 3px solid $color-calm-lilac-medium;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .score {
    font-size: 16px;
    font-weight: 600;
    color: $color-calm-lilac-medium;
  }
}

$duration: 2000;

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.confettiPiece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $color-sun-yellow-medium;
  top: 0;
  opacity: 0;

  @for $i from 1 through 13 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $duration * 0.5)}ms;
      animation-duration: #{randomNum($duration * 0.7, $duration * 1.2)}ms;
    }
  }

  &:nth-child(odd) {
    background: $color-sky-blue-medium;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: $duration * 2ms;
  }

  &:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: $duration * 2.5ms;
    animation-delay: $duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $color-calm-lilac-medium;
  }

  &:nth-child(5n) {
    background: $color-zingy-green-medium;
  }

  &:nth-child(6n) {
    background: $color-aqua-green-medium;
  }

  &:nth-child(7n) {
    background: $color-warm-orange-medium;
  }
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(200px);
  }
}
