.hsQuestionsContainer {
  padding-bottom: 100px;

  .hsQuestionsContainerSidebar {
    position: fixed !important;
    top: 74px;
    right: 0;
    height: calc(100vh - 150px);
    z-index: 9;
  }

  .hsQuestionsContainerSidebar {
    + {
      .hsQuestionsContainerSidebarOverlay {
        display: block;
        position: absolute;
        left: 0;
        top: 74px;
        height: calc(100% - 150px);
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: width, left, right, 300ms;
        transition: width, left, right, 300ms;
      }
    }

    &.Collapsed {
      + {
        .hsQuestionsContainerSidebarOverlay {
          display: none;
        }
      }
    }
  }
}
