@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

$selectedColor: $white;
$unselectedColor: #ebe7dd;
$circleColor: $color-putty-light;

.tabsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: calc(100% - 40px);

  .tabItem {
    padding: 15px 25px;
    border-radius: 30px 30px 0 0;
    background-color: $unselectedColor;
    color: $text-darker-grey;
    border: none;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    font-size: 18px !important;
    font-family: 'Titillium Web', sans-serif;

    @include betaSize {
      font-size: 16px;
      padding: 15px 30px;
    }
  }

  .selectedTabItem {
    padding: 15px 25px;
    border-radius: 30px 30px 0 0;
    background-color: $selectedColor;
    color: $text-dark-grey;
    border: none;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    box-shadow: 0px 0px 8px rgba($black, 0.15);
    font-size: 18px !important;
    font-family: 'Titillium Web', sans-serif;
    @include betaSize {
      font-size: 16px;
      padding: 15px 30px;
    }
  }
  .tabItem1 {
    z-index: 2;
    @include bodyCopyLarge;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;

    &.mobileWrapped {
      @include bodyCopy;

      @media screen and (min-width: 601px) and (max-width: 1480px) {
        font-size: 12px;
      }
    }

    .overlapElement {
      height: 15px;
      width: 100%;
      position: absolute;
      bottom: -10px;
    }
    .overlapCircle {
      position: absolute;
      height: 30px;
      width: 55px;
      border-radius: 50%;
      right: -40px;
      z-index: 1;
      top: 30px;
      background-color: $unselectedColor;

      @media screen and (min-width: 601px) and (max-width: 1480px) {
        top: 30px;
        height: 60px;
      }

      @include betaSize {
        top: 30px;
      }
    }
    .overlapSelectedCircle {
      position: absolute;
      height: 30px;
      width: 55px;
      border-radius: 50%;
      right: -40px;
      z-index: 1;
      top: 30px;
      background-color: $white;

      @include betaSize {
        top: 30px;
      }

      @media screen and (min-width: 601px) and (max-width: 1480px) {
        top: 30px;
        height: 60px;
      }
    }
    .overlapSquare {
      position: absolute;
      height: 30px;
      width: 30px;
      right: -30px;
    }

    @include betaSize {
      font-size: 12px;
    }
  }
  .tabItem2 {
    @include bodyCopyLarge;
    font-weight: 600;
    padding: 15px 25px;
    border: none;
    position: relative;
    border-radius: 30px 30px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-family: 'Titillium Web', sans-serif;

    &.mobileWrapped {
      @include bodyCopy;
    }

    @include betaSize {
      font-size: 11px;
      padding: 10px;
    }
    .overlapElement2 {
      height: 15px;
      width: 90%;
      position: absolute;
      right: 0;
      bottom: -10px;
      background-color: $selectedColor;
    }
    .overlapCircle2 {
      position: absolute;
      height: 52px;
      width: 55px;
      border-radius: 50%;
      right: -55px;
      z-index: -1;
      bottom: 0;
      background-color: $circleColor;
      @include betaSize {
        display: none;
      }

      &.themeSkyBlue {
        background-color: $color-sky-blue-medium;
      }
    }
    .selectedOverlapCircle2 {
      display: none;
    }
    .overlapSquare2 {
      position: absolute;
      height: 30px;
      width: 30px;
      right: -30px;
      bottom: 0;
      z-index: -2;
      background-color: $unselectedColor;
      @include betaSize {
        display: none;
      }
    }
    .selectedOverlapSquare2 {
      display: none;
    }
  }
  .oneTabContainer {
    z-index: -999 !important;
    .overlapElement {
      height: 15px;
      width: 100%;
      position: absolute;
      bottom: -10px;
      background-color: $circleColor !important;
    }
    .overlapCircle {
      position: absolute;
      height: 52px;
      width: 55px;
      border-radius: 50%;
      right: -55px;
      z-index: 1;
      margin-top: -27px;
      background-color: $circleColor;
      cursor: auto;

      &.themeSkyBlue {
        background-color: $color-sky-blue-medium;
      }
    }
    .overlapSquare {
      position: absolute;
      height: 30px;
      width: 30px;
      right: -30px;
      bottom: 0;
      background-color: $white !important;
    }
  }

  .tabIcon {
    margin-right: 10px;
    min-width: 25px;

    @include gammaSize {
      width: 20px;
    }
  }
}

.tabNameCombined {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
