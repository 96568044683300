@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.scoresSection {
  margin-top: 40px;
  background-color: $color-putty-dark;
  border-radius: 30px;

  &.lightMode {
    background-color: $color-putty-light;
  }

  .wellbeingScoreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: $color-sky-blue-medium;
    border-radius: 30px;
    height: 250px;

    @include gammaSize {
      height: 250px;
    }

    &.lightMode {
      background-color: $color-putty-dark;

      h5 {
        color: $text-dark-grey;
      }

      p {
        color: $text-dark-grey;
      }
    }

    h5 {
      color: $white;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      padding-top: 20px;
      font-family: 'Titillium Web', sans-serif;
    }

    p {
      color: $white;
      text-align: center;
      font-size: 14px;
      margin-top: -20px;
      font-family: 'Titillium Web', sans-serif;
    }
  }

  .otherScoresContainer {
    background-color: $color-putty-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 30px;
    height: 250px;

    @include gammaSize {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: auto;
      margin-top: 20px;
    }

    &.lightMode {
      background-color: $color-putty-light;
    }

    .scoreContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h5 {
        color: $text-dark-grey;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Titillium Web', sans-serif;
      }
    }
  }

  .wellbeingScoreContainerLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: $color-sky-blue-medium;
    border-radius: 30px;
    height: 250px;
    gap: 10px;

    @include gammaSize {
      height: 250px;
      width: 100%;
    }

    &.lightMode {
      background-color: $color-putty-dark;
    }
  }

  .otherScoresContainerLoading {
    background-color: $color-putty-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 30px;
    height: 250px;
    gap: 4%;

    @media (min-width: 1700px) {
      gap: 9% !important;
    }

    @include gammaSize {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: auto;
      width: 90%;
      margin-left: 15px;
      padding-bottom: 40px;
      padding-top: 20px;
    }

    &.lightMode {
      background-color: $color-putty-light;
    }

    .scoreContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}
