.hsError {
  color: red;
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}

.inputHolder {
  margin: 20px 0;
}

.buttonHolder {
  margin: 20px 40px;
  button {
    width: 100%;
    justify-content: center;
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  .loadingItem {
    width: 100%;
    margin-bottom: 20px;
  }
}
