@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.mainContainer {
  min-height: 70vh;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include gammaSize {
      flex-direction: column;
    }

    .mainTitle {
      color: $text-dark-grey;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
    }

    .topSectionButtons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .selectionContainer {
    margin-top: 40px;

    h6 {
      margin-top: 40px;
      font-weight: 600;
      text-align: center;
      color: $text-dark-grey;
      margin-bottom: 100px;
    }
  }

  .autocompleteImageOption {
    width: 40px !important;
    margin-right: 10px !important;
  }

  .cardContainer {
    padding: 40px;
    border-radius: 30px;
    margin-top: 40px;
  }
}

li {
  span {
    font-weight: 600 !important;
  }

  .autocompleteImageOption {
    width: 40px;
    margin-right: 10px;
    background-color: $color-putty-medium;
  }
}

.loadingSpinner {
  margin-left: 10px;
}

.saveButton {
  text-align: right;
  margin-top: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999998;
  overflow: hidden;
}

.modalRoot {
  position: fixed;
  overflow-y: scroll;
  max-height: 95%;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  padding: 30px 36px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    right: 0px !important;
    left: 0px !important;
    top: 0 !important;
    width: 100%;
    max-height: 100%;
    transform: none;
  }
}

.modalRoot::-webkit-scrollbar {
  display: none;
}
