@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.sessionContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 36px;

  @include gammaSize {
    max-width: 100%;
    padding: 20px;
    margin-top: 25px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 1rem;

  @include gammaSize {
    flex-direction: column;
    gap: 10px;
  }

  h5 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: $text-dark-grey;

    @include gammaSize {
      font-size: 1.1rem;
    }
  }

  .viewAllSessions {
    color: $color-sky-blue-medium;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;

    @include gammaSize {
      font-size: 0.85rem;
    }
  }

  .viewAllIcon {
    width: 18px;
  }

  .viewAllSessions:disabled {
    color: $text-deactivated;
  }
}

.sessions {
  display: flex;
  flex-direction: column;
}

.sessionInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;

  @include gammaSize {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 30%;

    @include gammaSize {
      flex-direction: column;
      gap: 20px;
      width: auto;
    }

    .expertImage {
      width: 70px;
      border-radius: 100px;
    }

    span {
      color: $color-sky-blue-medium;
      font-weight: 600;
    }
  }

  .middle {
    margin-left: -80px;
    @include gammaSize {
      text-align: left;
      margin-left: 0;
    }

    .bookingContainer {
      display: table;
      border-radius: 4px;
      width: fit-content;
    }

    .row {
      display: table-row;
    }

    .cell {
      display: table-cell;
      padding: 2px 20px;
      border: 1px solid $color-putty-dark;
      vertical-align: middle;
      min-width: 200px;
      max-width: 220px;

      span {
        color: $text-dark-grey;
        text-transform: capitalize;
        font-size: 14px;

        @include gammaSize {
          font-size: 12px;
        }
      }

      @include gammaSize {
        width: 300px;
        min-width: 100%;
        max-width: 100%;
        padding: 2px 10px;
      }
    }

    .icon {
      margin-right: 10px;
      width: 20px;
      vertical-align: middle;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
    align-items: center;

    .topRightContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      margin-left: -15px;

      .cancelBtn {
        margin-top: 4px;
        padding: 10px 15px 10px 10px;
        color: $color-sky-blue-medium;
        cursor: pointer;

        .btnCloseIcon {
          margin-right: 10px;
        }
      }

      .cancelBtn:hover {
        color: $color-sky-blue-light;
        background-color: $white;
        border-radius: 24px;
      }

      @include gammaSize {
        margin-left: -20px;
      }
    }

    .joinSessionBtn {
      margin-left: 20px;

      @include gammaSize {
        margin-left: 0;
      }
    }

    @include gammaSize {
      margin-top: 0;
      justify-content: center;
    }
  }
}

.divider {
  margin-bottom: 30px;
  margin-top: 20px;
  border: none;
  height: 2px;
  background-color: $color-putty-dark;
}

.viewAllSessionsOnScroll {
  @include gammaSize {
    color: $color-sky-blue-medium;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
  }
}

.skeleton {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  border-radius: 20px !important;
  width: 100% !important;
  height: 120px !important;

  @include gammaSize {
    height: 300px !important;
  }
}

.modalRoot {
  position: fixed;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  min-width: 35%;
  max-width: 35%;
  min-height: 90%;
  max-height: 95%;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.547);
  box-sizing: border-box;

  @include mediaBetween(2399px, 1500px) {
    min-width: 30%;
    max-width: 30%;
    min-height: 90%;
    max-height: 90%;
  }

  @include mediaBetween(3199px, 2400px) {
    min-width: 25%;
    max-width: 25%;
    min-height: 70%;
    max-height: 70%;
  }

  @include mediaBetween(4200px, 3200px) {
    min-width: 20%;
    max-width: 20%;
    min-height: 60%;
    max-height: 60%;
  }

  @include gammaSize {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  @include gammaSize {
    width: 100%;
    max-width: 100%;
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }

    @include gammaSize {
      margin-bottom: 20px;
    }
  }

  .btnCloseIcon {
    font-size: 25px;
  }

  .modalContent {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
    cursor: default;

    h5 {
      margin-top: 20px;
      font-weight: 400;
      color: $text-dark-grey;
      margin-bottom: 20px;
    }

    .expertImage {
      width: 60px;
      border-radius: 100px;
      text-align: center;
    }

    .expertName {
      color: $color-sky-blue-medium;
      font-weight: 600;
    }

    .sessionDetails {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .sessionDetailsContainer {
        display: table;
        border-radius: 4px;
        width: fit-content;

        .row {
          display: table-row;
        }

        .cell {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 2px 20px;
          border: 1px solid $color-putty-dark;
          min-width: 210px;
          max-width: 230px;

          span {
            color: $text-dark-grey;
            text-transform: capitalize;
            font-size: 14px;
            flex-grow: 1;
            text-align: center;

            @include gammaSize {
              font-size: 12px;
            }
          }

          @include gammaSize {
            width: 300px;
            min-width: 100%;
            max-width: 100%;
            padding: 2px 10px;
          }
        }

        .icon {
          margin-right: 10px;
          width: 20px;
        }
      }
    }

    .noticeContainer {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .noticeItem {
        width: 90%;

        @include gammaSize {
          width: 100%;
        }
      }

      .notice {
        span {
          color: $error-red;
          font-weight: 600;
        }

        p {
          color: $error-red;
          font-size: 14px;
          margin-top: 10px;
        }
      }

      .noticeV2 {
        p {
          font-size: 14px;
          margin-top: 10px;
          color: $text-dark-grey;
        }
      }
    }

    .btnWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      margin-top: 30px;

      @include gammaSize {
        gap: 10px;
        flex-direction: column;
      }
    }
  }
}

.modalRoot::-webkit-scrollbar {
  width: 0;
}

.footerImageV1 {
  position: fixed;
  bottom: auto;
  width: 100%;
  height: 150px;
  background-image: url('../../../../assets/images/modal-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
  margin-left: -30px !important;
  z-index: -1;

  @media only screen and (max-device-width: 380px) and (max-device-height: 700px) {
    bottom: auto !important;
  }

  @include gammaSize {
    bottom: 0;
    height: 150px;
    margin-left: -50px !important;
  }

  @include mediaBetween(4200px, 1400px) {
    bottom: 0 !important;
  }
}

.joinSessionContainer {
  width: 100%;
  margin-right: 40px;
  margin-top: -10px;
  margin-bottom: 10px;

  @include gammaSize {
    margin-right: 0;
    margin-top: 0;
  }

  .joinSessionBtn {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;
    width: 100%;

    &:hover {
      background: $color-sky-blue-light;
      border: 2px solid $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }
  }
}
