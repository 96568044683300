@import '../../assets/styles/variables';

.hsStarWithLabels {
  z-index: 99;
  min-height: calc(100vh - 420px);

  .title {
    color: #505050;
    font-size: 24px;
    width: 220px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }

  table {
    tbody {
      tr {
        td {
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: $text-dark-grey;

            button {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
