@import '../../../assets/styles/variables';

.checkbox {
  display: flex;
  border: 2px solid #505050;
  border-radius: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;

  border-color: $color-calm-lilac-medium;

  input[type='checkbox'] {
    visibility: hidden;
  }
}

.error {
  border-color: red;
}
