@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.preMiddleWhiteBox1 {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  height: 160px;
  padding: 20px;

  @include gammaSize {
    height: auto;
  }

  .sliderCont {
    display: flex;
    flex-direction: row;
    gap: 5px;

    p {
      font-style: italic;
      font-weight: 400;
      color: $text-dark-grey;
      margin-top: 10px;
      font-size: 16px;
    }
    .quoteLeft {
      width: 10px;
      height: 10px;
      align-self: flex-start;
    }

    .quoteRight {
      width: 10px;
      height: 10px;
      align-self: flex-end;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  .attribution {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;

    p {
      font-weight: 400;
      color: $text-dark-grey;
      font-size: 16px;
    }
  }
}

.load {
  width: 100% !important;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  justify-content: flex-end;

  .navigationBtn {
    background-color: transparent;
    border: none;
    padding: 5px;

    &:disabled {
      opacity: 0.3;
    }

    .navigationIcon {
      width: 25px;
      height: 25px;
    }
  }
}
