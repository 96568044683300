@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.speakUpCard {
  border: 2.5px solid $color-sky-blue-medium;
  border-radius: 30px;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

  @include gammaSize {
    padding: 20px;
  }
}

.speakUpContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include gammaSize {
    flex-direction: column;
  }
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
}

.chatIcon {
  width: 80px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

  @include gammaSize {
    width: 65px;
    height: 50px;
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: $text-dark-grey;
}

.description {
  font-size: 16px;
  color: $text-dark-grey;
  margin-top: 5px;
}

.rightSection {
  display: flex;
  align-items: center;
}
