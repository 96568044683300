.swiper-button-next,
.swiper-button-prev {
  background-color: white !important;
  padding: 8px 16px !important;
  border-radius: 100% !important;
  border: 2px solid black !important;
  color: red !important;
}
.swiper-pagination-bullet {
  width: 40px !important;
  height: 40px !important;
  background-color: red !important;
}
