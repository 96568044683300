@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  margin-top: 40px;
  margin-bottom: 40px;

  .cardContainer {
    background-color: $white;
    border-radius: 24px;
    padding: 20px;
    height: 470px;

    .subTitle {
      font-weight: 600;
      color: $text-dark-grey;
      font-family: 'Titillium Web', sans-serif;
    }
    .subDescription {
      color: $text-dark-grey;
      font-size: 14px;
      height: 20px;
      font-family: 'Titillium Web', sans-serif;
    }

    .boostersList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      p {
        background-color: $color-calm-lilac-dark;
        padding: 10px 20px 10px 20px;
        color: $white;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
      }
    }

    .drainersList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      p {
        background-color: $color-sun-yellow-dark;
        padding: 10px 20px 10px 20px;
        color: $white;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
      }
    }

    .careerBoostersList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      p {
        background-color: $color-warm-orange-dark;
        padding: 10px 20px 10px 20px;
        color: $white;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
      }
    }

    .careerDrainersList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 40px;

      p {
        background-color: $color-sun-yellow-dark;
        padding: 10px 20px 10px 20px;
        color: $white;
        border-radius: 24px;
        font-family: 'Titillium Web', sans-serif;
      }
    }
  }
}

.loadingItem {
  border-radius: 24px !important;
}

.emptyState {
  text-align: center;
  margin-top: 50%;
  font-size: 20px;
  color: $text-dark-grey;
}
