.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999;
}

.root {
  position: fixed;
  z-index: 999999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 535px;
  max-width: 100%;
  padding: 30px 36px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    right: 0px !important;
    top: 20px !important;
    position: fixed;
    transform: none;
  }
}

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}

.btnCloseLabel {
  margin-right: 15px;
}

.btnCloseIcon {
  font-size: 25px;
}

.btnActionContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 50px;
}

.btnAction {
  background-color: #019df2;
  border-radius: 24px;
  padding: 9px 24px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
