@import './variables';

@mixin before-after {
  content: '';
  position: absolute;
}

//COMMON IMAGE
@mixin commonImage($objectFit: cover) {
  width: 100%;
  height: 100%;
  object-fit: $objectFit;
}

// MEDIA QUERY BREAK POINT IS 599PX
@mixin alphaSize {
  @media all and (max-width: $alpha) {
    @content;
  }
}

// MEDIA QUERY BREAK POINT IS 767PX
@mixin betaSize {
  @media all and (max-width: $beta) {
    @content;
  }
}

// MEDIA QUERY BREAK POINT IS 1023PX
@mixin gammaSize {
  @media all and (max-width: $gamma) {
    @content;
  }
}

// MEDIA QUERY BREAK POINT IS 1199PX
@mixin deltaSize {
  @media all and (max-width: $delta) {
    @content;
  }
}

// FOR CUSTOM MEDIA QUERY BREAK POINT - FROM UP
@mixin mediaFromUp($maxWidth) {
  @media all and (max-width: $maxWidth) {
    @content;
  }
}

// FOR CUSTOM MEDIA QUERY BREAK POINT - FROM DOWN
@mixin mediaFromDown($minWidth) {
  @media all and (min-width: $minWidth) {
    @content;
  }
}

// FOR CUSTOM MEDIA QUERY BREAK POINT - BETWEEN
@mixin mediaBetween($maxWidth, $minWidth) {
  @media all and (max-width: $maxWidth) and (min-width: $minWidth) {
    @content;
  }
}

//FOR TEXT TRUNCATE - WHEN USE THIS, WIDTH/MAX-WIDTH SHOULD BE DEFINED
@mixin textTruncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// TEXT MIXINS USED IN FIGMA LAYOUTS
@mixin heading1 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}

@mixin heading2 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
}

@mixin heading3 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

@mixin subHeading1 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@mixin subHeading2 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

@mixin subHeading3 {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

@mixin bodyCopyLarge {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@mixin bodyCopy {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@mixin caption {
  color: $text-dark-grey;
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

// FLEX CONTAINER
@mixin flexContainer(
  $direction: row,
  $flexWrap: nowrap,
  $justify: center,
  $align: center,
  $gap: 10px
) {
  display: flex;
  flex-wrap: $flexWrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

// ANIMATIONS
@mixin fadeInAnimation {
  animation: fadeIn 2s linear forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin flipAndRotateForwardsAnimation {
  animation: flipAndRotate 2s linear forwards;
  @keyframes flipAndRotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotateY(180deg) scale(1);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
}

@mixin flipAndRotateInfiniteAnimation {
  animation: flipAndRotate 2s linear infinite;
  @keyframes flipAndRotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotateY(180deg) scale(1);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
}

@function hexToRgba($color, $alpha) {
  @if (type-of($color) == 'color') {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
    @return rgba($r, $g, $b, $alpha);
  }
  @return null;
}
