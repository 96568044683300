@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

@function hexToRgba($color, $alpha) {
  @if (type-of($color) == 'color') {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
    @return rgba($r, $g, $b, $alpha);
  }
  @return null;
}

.container {
  margin-top: 30px;
}

.title {
  margin-bottom: 20px;
  h5 {
    color: $text-dark-grey;
  }
}
.loadingItem {
  border-radius: 20px !important;
  padding: 30px !important;
}

@mixin roundedSquareButton($theme-light-color, $theme-dark-color) {
  border: 1px solid hexToRgba($theme-dark-color, 0.7);
  border-radius: 20px;
  padding: 30px;
  background-color: $theme-light-color;
  text-align: center;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);

  &:hover {
    transform: translateY(-2px);
    background-color: hexToRgba($theme-light-color, 0.7);
  }

  @include gammaSize {
    padding: 10px;
  }
}

.roundedSquareButton {
  @include roundedSquareButton($color-putty-light, $color-putty-dark);

  &.sunYellow {
    @include roundedSquareButton($color-sun-yellow-light, $color-sun-yellow-dark);
  }

  &.aquaGreen {
    @include roundedSquareButton($color-aqua-green-light, $color-aqua-green-dark);
  }

  &.calmLilac {
    @include roundedSquareButton($color-calm-lilac-light, $color-calm-lilac-dark);
  }

  &.warmOrange {
    @include roundedSquareButton($color-warm-orange-light, $color-warm-orange-dark);
  }

  &.zingyGreen {
    @include roundedSquareButton($color-zingy-green-light, $color-zingy-green-dark);
  }

  &.skyBlue {
    @include roundedSquareButton($color-sky-blue-light, $color-sky-blue-dark);
  }
  .questionIcon {
    margin-top: 10px;
  }

  .questionText {
    font-weight: 600;
    color: $text-dark-grey;
    text-align: center;

    @include gammaSize {
      font-size: 14px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.questionContainer {
  animation: fadeIn 0.5s ease-out;
}
