@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.card {
  width: calc(33.333% - 20px);
  border-radius: 24px !important;
  overflow: hidden;
  position: relative;
  height: 360px !important;
  border: none !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
  background-color: $white;

  &.smallCard {
    width: calc(25% - 15px);
    height: 300px !important;

    @media screen and (max-width: 1200px) {
      width: calc(50% - 10px);
    }

    @media screen and (max-width: 768px) {
      width: calc(100%);
    }
  }

  @media screen and (max-width: 1200px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: 768px) {
    width: calc(100%);
  }
}

.card:hover {
  transform: translateY(-10px);
}

.cardImg {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.card .title {
  padding: 20px;
  background-color: #fff;
  text-align: left;
}

.card .title h2 {
  font-size: 18px;
  font-weight: 600;
  color: $text-dark-grey;
}

.arrowButton {
  position: relative;
  z-index: 4;
}

.arrowButton {
  display: block;
  width: 77px;
  height: 62px;
  opacity: 1;
  transform: translateY(0px);
  position: absolute !important;
  right: 0;
  bottom: 0;
}

.arrowButton svg {
  width: 87px;
  height: 72px;
}

.arrowButton.active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1.4s, transform 1.4s, border-color 0.3s, background-color 0.3s;
}

.arrowButton:hover {
  opacity: 0.8;
}

.cardItem:nth-child(6n + 1) .blogArrowBg {
  fill: #019df2 !important;
}

.cardItem:nth-child(6n + 2) .blogArrowBg {
  fill: #fcd300 !important;
}

.cardItem:nth-child(6n + 3) .blogArrowBg {
  fill: #06bf97 !important;
}

.cardItem:nth-child(6n + 4) .blogArrowBg {
  fill: #ff8a4a !important;
}

.cardItem:nth-child(6n + 5) .blogArrowBg {
  fill: #8eea36 !important;
}

.cardItem:nth-child(6n + 6) .blogArrowBg {
  fill: #beaaf3 !important;
}
