@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixns';

.focusSelectionSection {
  padding: 20px;
}

.headingContainer {
  margin-bottom: 30px;
  .title {
    font-weight: 400;
    color: $text-dark-grey;
    font-size: 20px;
    text-align: center;

    span {
      font-weight: 600;
    }
  }
}

.continueContainer {
  margin-bottom: 30px;
  margin-top: 40px;
  .title {
    font-weight: 400;
    color: $text-dark-grey;
    font-size: 20px;
    text-align: center;

    span {
      font-weight: 600;
    }
  }
}

.differentAreaContainer {
  margin-top: 50px;
  .title {
    font-weight: 400;
    color: $text-dark-grey;
    font-size: 20px;
    text-align: center;
  }
}

.returnToPlatformContainer {
  margin-top: 50px;
  .title {
    font-weight: 400;
    color: $text-dark-grey;
    font-size: 20px;
    text-align: center;
  }
}

.continueBtnContainer {
  text-align: center;
  margin-top: 40px;

  .continueBtnPrimary {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;
    width: 70%;
    text-transform: uppercase;

    &:hover {
      background: $color-sky-blue-light;
      border: 2px solid $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }

    @include alphaSize {
      width: 100%;
    }
  }
}

.secondaryBtnContainer {
  text-align: center;
  margin-top: 20px;

  .secondaryBtn {
    background: transparent;
    color: $color-sky-blue-medium;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;
    width: 70%;
    text-transform: uppercase;

    &:hover {
      border: 2px solid $color-sky-blue-light;
      color: $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }

    @include alphaSize {
      width: 100%;
    }
  }
}
