@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.card {
  border-radius: 20px;
  border: 1px solid $color-putty-dark;
  padding: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  background-color: $white;
  padding: 40px;

  @include gammaSize {
    margin: 20px;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;

    .emptyPlansImg {
      width: 40%;
      margin-top: -40px;
    }

    .texts {
      p {
        text-align: center;
        color: $text-dark-grey;
        font-size: 18px;
      }
    }

    .button {
      margin-top: 40px;
      text-align: center;
      margin-bottom: 40px;

      @include gammaSize {
        margin-bottom: 0px;
      }
    }
  }
}
