@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.card {
  display: flex;
  gap: 10px;
  background: #ffffff;
  border: 2px solid $color-sky-blue-medium;
  width: 100%;
  border-radius: 20px;
  min-height: 167px;
  padding: 20px;

  .cardWrapper {
    min-width: 100%;
    max-width: 100%;

    .date {
      display: flex;

      .dateWrapper {
        background-color: #828282;
        border-radius: 4px;
        padding: 3px 10px;
        margin: 0;
        font-size: 14px;
        color: white;
        width: 100px;
        justify-content: center;
        display: flex;
        margin-top: 10px;

        @include gammaSize {
          justify-content: center;
          align-items: center;
          align-self: center;
        }
      }

      @include gammaSize {
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-bottom: 10px;
      }
    }

    .middleContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      min-width: 100%;
      max-width: 100%;
      gap: 20px;

      @include gammaSize {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .leftItem {
        margin-top: 10px;

        &.btnEnabled {
          flex: 50;
        }

        &.btnNotEnabled {
          flex: 70;
        }

        h5 {
          color: $text-dark-grey;
          font-weight: 600;
          font-size: 22px;
          @include gammaSize {
            text-align: center;
          }

          @include betaSize {
            font-size: 18px !important;
          }
        }
        .truncateSection {
          font-family: 'Titillium Web', sans-serif;
          font-weight: 400;
          font-size: 16px;
          word-break: break-all;
          -ms-word-break: break-all;
          margin-top: 10px;
          display: inline;
          text-align: left;
          color: $text-dark-grey;

          .viewMore {
            font-weight: 600;
            cursor: pointer;
            margin-left: 10px;
            color: $color-sky-blue-medium;
          }

          @include gammaSize {
            display: none;
          }
        }
      }
      .rightItem {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .focusContainer {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin-top: -30px;
          justify-content: center;

          .scoresSection {
            width: 200px;
            .categoryLine {
              display: flex;
              flex-direction: row;
              gap: 10px;
              justify-content: left;

              p {
                color: $text-dark-grey;
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
              }
              span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 3px solid $color-sky-blue-medium;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                padding: 10px !important;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;

                @include betaSize {
                  font-size: 18px !important;
                }
              }
            }

            @include gammaSize {
              align-self: center;
              align-items: center;
            }
          }

          .truncateSection {
            font-family: 'Titillium Web', sans-serif;
            font-weight: 400;
            font-size: 16px;
            word-break: break-all;
            -ms-word-break: break-all;
            margin-top: 10px;
            display: inline;
            text-align: left;
            color: $text-dark-grey;
            display: none;

            .viewMore {
              font-weight: 600;
              cursor: pointer;
              margin-left: 10px;
              color: $color-sky-blue-medium;
            }

            @include gammaSize {
              display: block;
              align-self: center;
              text-align: center;
              min-width: 100%;
            }

            @include betaSize {
              font-size: 14px !important;
            }
          }

          @include gammaSize {
            margin-top: -10px;
          }
        }
        .inputContainer {
          align-self: center;
          margin-top: -30px;
          @include gammaSize {
            display: none;
          }
        }
      }
      .horizonalLine {
        width: calc(100% + 40px);
        border-top: 2px solid $color-sky-blue-medium;
        margin: 10px 0 20px -20px;
        opacity: 0.3;
        display: none;

        @include gammaSize {
          display: block;
        }
      }

      .inputContainerV2 {
        align-self: center;
        display: none;
        @include gammaSize {
          display: block;
        }
      }
    }

    .speakupResponseSection {
      .horizonalLineV2 {
        width: calc(100% + 40px);
        margin: 20px 0 20px -20px;
        border-top: 2px solid $black;
        opacity: 0.3;
      }

      .responseSectionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }

      .responseSection {
        margin-top: 10px;

        h5 {
          color: $text-dark-grey;
          font-weight: 600;
          font-size: 22px;
          @include gammaSize {
            text-align: center;
          }
        }
        .truncateSection {
          font-family: 'Titillium Web', sans-serif;
          font-weight: 400;
          font-size: 16px;
          word-break: break-all;
          -ms-word-break: break-all;
          margin-top: 10px;
          display: inline;
          text-align: left;
          color: $text-dark-grey;

          .viewMore {
            font-weight: 600;
            cursor: pointer;
            margin-left: 10px;
            color: $color-sky-blue-medium;
          }

          @include gammaSize {
            display: block;
            align-self: center;
            text-align: center;
            min-width: 100%;
          }
        }
      }
    }
  }
}
