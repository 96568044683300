@import '../variables';

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-Black.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-Black.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-Black.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-Black.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-Bold.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-Bold.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-Bold.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-BoldItalic.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-BoldItalic.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-BoldItalic.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-ExtraLight.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLight.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLight.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-ExtraLightItalic.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLightItalic.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLightItalic.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-Italic.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-Italic.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-Italic.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-Light.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-Light.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-Light.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-LightItalic.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-LightItalic.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-LightItalic.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-Regular.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-Regular.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-Regular.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-SemiBold.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBold.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBold.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('#{$hs-font-path}/TitilliumWeb-SemiBoldItalic.eot');
  src: url('#{$hs-font-path}/TitilliumWeb-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBoldItalic.woff2') format('woff2'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBoldItalic.woff') format('woff'),
    url('#{$hs-font-path}/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
