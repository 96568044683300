@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.container {
  overflow: auto;
}

.modalTitle {
  font-weight: 600;
  text-align: center;
  color: $text-dark-grey;
}

.hsError {
  color: red;
  margin-bottom: 0;
}

.inputHolder {
  margin: 20px 0;
}

.imagePreviewContainer {
  height: auto;
  height: 160px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: $color-putty-medium;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid $color-putty-dark;

  .image {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid $color-putty-dark;
  }
}

.addBtn {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.categoryList {
  margin-top: 30px;
  .categoryListTitle {
    font-weight: 600;
    color: $text-dark-grey;
  }
}

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseLabel {
  margin-right: 5px;
  color: $black;

  &:hover {
    font-weight: 600;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $black;
}

.thumbnailImage {
  width: 60px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  border: 1px solid $color-putty-dark;
}

.tableCell {
  font-weight: 600;
  color: $text-dark-grey;
}

.noData {
  text-align: center;
  margin-top: 20px;
}

.optIcon {
  width: 16px;
  cursor: pointer;
}

.deleteModal {
  .bodyMessage {
  }

  .error {
    span {
      color: $text-dark-grey !important;
    }
    color: $error-red !important;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    .confirmBtn {
      background-color: #dc3545;
      border-radius: 0.375rem;
      border: none;
      color: $white;
      padding: 10px 20px 10px 20px;
      &:hover {
        background-color: #bb2d3b;
      }

      &:disabled {
        background-color: $text-deactivated;
        opacity: 0.5;
      }
    }

    .cancelBtn {
      background-color: #6c757d;
      border-radius: 0.375rem;
      border: none;
      color: $white;
      padding: 10px 20px 10px 20px;
      &:hover {
        background-color: #5c636a;
      }
    }
  }
}

.uploadImgContainer {
  margin-top: 20px !important;
  margin-bottom: 10px;
}

.showCarouselTitle {
  font-weight: 600;
}
