@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

$colors: (
  skyBlue: $color-sky-blue-medium,
  calmLilac: $color-calm-lilac-medium,
  sunYellow: $color-sun-yellow-medium,
  zingyGreen: $color-zingy-green-medium,
  aquaGreen: $color-aqua-green-medium,
  warmOrange: $color-warm-orange-medium,
  torquoise: $color-turquoise-medium,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

$colorsLight: (
  skyBlue: $color-sky-blue-light,
  calmLilac: $color-calm-lilac-light,
  sunYellow: $color-sun-yellow-light,
  zingyGreen: $color-zingy-green-light,
  aquaGreen: $color-aqua-green-light,
  warmOrange: $color-warm-orange-light,
  torquoise: $color-turquoise-light,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

.exploreSectionDesktop {
  width: 100%;
  background-color: $color-sky-blue-dark;
  margin-top: -100px;
  border-radius: 0 30px 30px 30px !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 400px;
  padding-bottom: 20px;

  @include gammaSize {
    display: none;
  }

  .exploreBody {
    padding: 20px;
    width: 100%;

    .navSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      h5 {
        color: $white;
      }

      .navButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 26px;
        width: 80%;
        height: 100%;

        @media screen and (min-width: 1200px) {
          margin-top: 12px;
        }
      }
    }

    .chartSection {
      .top {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 10px;

        p {
          color: $white;
        }

        .tipsIcon {
          width: 30px;
          margin-top: -10px;
        }
      }
    }
  }

  .areaChartContainer {
    background-color: $white;
    padding: 20px;
    border-radius: 30px;
    height: 430px;
    max-height: 100%;
  }

  .areaChartContainerEmpty {
    background-color: $white;
    padding: 20px;
    border-radius: 30px;
    height: 430px;
    max-height: 100%;
    cursor: pointer;
  }
}

.hsSecondaryWhite {
  color: $text-dark-grey;
  padding: 10px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  text-decoration: none;
  border: none;
  width: 100%;
  min-width: 148px;
  height: 50px;

  &.selected {
    @each $color, $bg in $colorsLight {
      &.#{$color} {
        background: $bg;
        border: 3px solid $bg;
      }
    }
  }

  @each $color, $bg in $colors {
    &.#{$color} {
      background: white;
      border: 3px solid $bg;
    }
  }

  &:hover {
    @each $color, $bg in $colorsLight {
      &.#{$color} {
        background: $bg;
        border: 3px solid $bg;
      }
    }
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.loadBtn {
  display: inline-flex !important;
  justify-content: center !important;
  border-radius: 24px !important;
  min-width: 148px !important;
  min-height: 40px !important;
}

.emptyChart {
  padding: 20px;

  h3 {
    font-size: 20px;
    text-align: center;
    color: $text-dark-grey;
    span {
      color: $color-sky-blue-medium;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .graphImage {
    width: 100%;
  }
}

.loadingPilarBtn {
  border-radius: 30px !important;
}

.navButtonText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .btnText {
    margin-left: 6px;
  }

  .score {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    align-content: center;
    margin-top: -5px;

    span {
      font-size: 15px;
    }

    @each $color, $bg in $colorsLight {
      &.#{$color} {
        background: white;
        border: 3px solid $bg;
      }
    }
  }
}

.loadChart {
  height: 380px !important;
}
