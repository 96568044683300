@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.mainContainer {
  margin-bottom: 100px;

  .insideContainer {
    background-color: $color-calm-lilac-medium;
    padding: 40px;
    margin-top: 20px;
    border-radius: 20px;

    @include gammaSize {
      padding: 20px;
    }

    .expertProfileMain {
      text-align: center !important;

      h4 {
        font-weight: 600;
        color: $text-dark-grey;
        margin-bottom: 20px;
      }

      p {
        color: $text-dark-grey;
        margin-top: 20px;
        font-size: 18px;
      }

      .expertProfileImg {
        border-radius: 100px;
        width: 120px;
        height: 120px;
        box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
      }

      .name {
        @include subHeading2;
        color: $color-sky-blue-medium;
        margin-top: 20px;
      }

      .loadingItem {
        display: flex;
        justify-content: center;
      }
    }

    .expertProfileSub {
      .expertProfileAbout {
        background-color: $white;
        border-radius: 30px;
        padding: 20px;
        margin-top: 40px;
        margin-bottom: 40px;

        p {
          color: $text-dark-grey;
        }

        .qualificationContainer {
          margin-top: 20px;

          .qualification {
            margin-top: 20px;
          }
        }

        h4 {
          font-weight: 600;
          color: $text-dark-grey;
          margin-bottom: 20px;
        }
      }

      .expertProfileAreas {
        background-color: $white;
        border-radius: 30px;
        padding: 20px;
        margin-top: 40px;
        margin-bottom: 40px;

        p {
          color: $text-dark-grey;
        }

        h4 {
          font-weight: 600;
          color: $text-dark-grey;
        }
      }

      .expertProfileLanguages {
        background-color: $white;
        border-radius: 30px;
        padding: 20px;
        margin-top: 40px;
        margin-bottom: 40px;

        p {
          color: $text-dark-grey;
        }

        h4 {
          font-weight: 600;
          color: $text-dark-grey;
        }
      }
    }
  }

  .topBox {
    @include flexContainer(column, nowrap, space-between, normal, 0);
    background-color: $color-calm-lilac-dark;
    height: auto;
    min-height: 300px;
    overflow: hidden;
    padding: 60px 60px 0 60px;
    position: relative;
    z-index: -1;
    width: 100%;
  }

  .content {
    z-index: 99999;
    .titleText {
      @include heading2;
      color: $white;
      margin: 0;
    }

    .middleText {
      @include bodyCopy;
      color: $white;
      margin-top: 24px;
      width: 80%;
    }
  }

  .abstractImg {
    background-image: url('../../../assets/images/experts-abstract.svg');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right top;
    position: absolute;
    margin-left: 100px;
    bottom: -50px;
    margin-top: 130px;
    z-index: -1;

    @include deltaSize {
      bottom: -110px;
    }
  }

  @include betaSize {
    margin-bottom: 75px;
    .topBox {
      @include flexContainer(column, nowrap, space-between, normal, 0);
      min-height: 260px;
      padding: 20px 10px 0 10px;
      height: auto;

      .content {
        margin-top: 10px;
        .middleText {
          @include bodyCopy;
          color: $white;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    .abstractImg {
      bottom: -260px;
      transform: rotate(-20deg);
    }
  }
}

.expertProfileAvailability {
  background-color: $white;
  border-radius: 30px;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;

  p {
    color: $text-dark-grey;
  }
}

.navigateBack {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  transition: transform 0.3s ease-in-out;
  animation: fadeIn 0.5s ease-out;
  cursor: pointer;
  margin-top: 20px;

  h5 {
    color: $color-sky-blue-medium;
    font-weight: 600;

    &:hover {
      color: $color-sky-blue-light;
    }
  }

  .previous {
    width: 20px;
    margin-top: -5px;
  }

  &:hover {
    transform: translateY(-2px);
    color: $color-sky-blue-light;
  }
}
