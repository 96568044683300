@import "../../assets/styles/variables";

.hsScoreCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border: 1px solid $text-deactivated;
  border-radius: 30px;
  width: 310px;
  position: relative;

  span.score {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -10px;
    width: 45px;
    height: 45px;
    background: $white;
    color: $text-deactivated;
    border: 1px solid $text-deactivated;
    border-radius: 50%;
    font-weight: 600;
    font-size: 24px;
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    span.score {
      background: $color-calm-lilac-dark;
      color: $white;
      border-color: $color-calm-lilac-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-calm-lilac-dark;
              border-color: $color-calm-lilac-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    span.score {
      background: $color-sky-blue-dark;
      color: $white;
      border-color: $color-sky-blue-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-sky-blue-dark;
              border-color: $color-sky-blue-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    span.score {
      background: $color-sun-yellow-dark;
      color: $white;
      border-color: $color-sun-yellow-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-sun-yellow-dark;
              border-color: $color-sun-yellow-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    span.score {
      background: $color-zingy-green-dark;
      color: $white;
      border-color: $color-zingy-green-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-zingy-green-dark;
              border-color: $color-zingy-green-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    span.score {
      background: $color-aqua-green-dark;
      color: $white;
      border-color: $color-aqua-green-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-aqua-green-dark;
              border-color: $color-aqua-green-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    span.score {
      background: $color-warm-orange-dark;
      color: $white;
      border-color: $color-warm-orange-dark;
    }

    .round {
      input[type="checkbox"] {
        &:checked {
          + {
            label {
              background-color: $color-warm-orange-dark;
              border-color: $color-warm-orange-dark;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  label {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-grey;

    &.content {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 35px;

      i {
        border-radius: 50%;
        color: $white;
      }
    }
  }

  &.up {
    label {
      &.content {
        i {
          background: $go-green;
        }
      }
    }
  }

  &.down {
    label {
      &.content {
        i {
          background: $error-red;
        }
      }
    }
  }

  &.right {
    label {
      &.content {
        i {
          background: #828282;
        }
      }
    }
  }

  .round {
    position: relative;
    width: 20px;
    height: 20px;

    input[type="checkbox"] {
      visibility: hidden;

      + {
        label {
          background-color: $white;
          border: 1px solid $text-deactivated;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 0;
          &:after {
            content: "";
            width: 12px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 3px;
            transform: rotate(-45deg);
            opacity: 0;
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
          }
        }
      }
    }
  }
}

