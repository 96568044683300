@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.desktopOrder {
  display: block;

  @include gammaSize {
    display: none;
  }
}

.mobileOrder {
  display: none;

  @include gammaSize {
    display: block;
  }
}

.infoSection {
  background-color: #079ef1;
  padding: 30px 0 40px 0;
  margin: 0 0 45px 0;

  @include betaSize {
    padding-top: 20px;
  }

  .employeeTopContainer {
    width: 100%;
    .title {
      font-family: 'Titillium Web', sans-serif;
      color: white;
      font-weight: 600;
      font-size: 40px;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .actionNotificationContainer {
      display: flex;
      flex-direction: row;
      width: 100%;

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        background-color: $color-warm-orange-medium;
        padding: 10px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;

        .notificationIcon {
          width: 50px;
        }

        @include alphaSize {
          display: none;
        }
      }

      .bodyContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background-color: $white;
        align-items: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 20px;

        @include alphaSize {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          gap: 10px;
          flex-wrap: wrap;
        }

        .notificationMobile {
          display: none;

          @include alphaSize {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-warm-orange-medium;
            padding: 10px;
            border-radius: 100px;
            margin-top: 40px;

            .notificationIcon {
              width: 40px;
            }
          }
        }

        .text {
          flex: 2;
          font-size: 18px;

          @include alphaSize {
            flex: 1 !important;
            flex-basis: 70% !important;
          }
        }

        .button {
          flex: 1;
          text-align: right;
          margin-right: 20px;

          @include alphaSize {
            margin-top: 10px;
            width: 100% !important;
            margin-left: 70px;
            text-align: left;
          }

          div {
            animation: grow 0.2s ease-out;
          }
        }
      }
    }
  }
}

.progressSection {
  margin-top: 20px !important;
  width: 100%;

  @include alphaSize {
    margin-top: 10px !important;
  }

  .areaChartContainer {
    background-color: $white;
    padding: 20px;
    border-radius: 30px;
    height: 430px;
    max-height: 100%;
  }
}
@keyframes grow {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.hsPrimaryBtn {
  background: $color-sky-blue-medium;
  color: $white;
  padding: 9px 44px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;
  min-width: 148px;

  &:hover {
    background: $color-sky-blue-light;
    border: 2px solid $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.hsPrimaryWhite {
  background: $white;
  color: $white;
  padding: 9px 44px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  color: $color-sky-blue-medium;
  border: none;
  min-width: 148px;

  &:hover {
    background: $color-putty-medium;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.hsSecondaryWhite {
  background: $white;
  color: $white;
  padding: 9px 44px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  color: $color-sky-blue-medium;
  border: 2px solid $color-sky-blue-medium;
  width: 100%;
  min-width: 148px;

  &:hover {
    background: $color-putty-medium;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.loadUserWelcome {
}

.loadBtn {
  display: inline-flex !important;
  justify-content: center !important;
  border-radius: 24px !important;
  min-width: 148px !important;
  min-height: 40px !important;
}

.loadPie {
  display: flex !important;
  justify-content: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 40px !important;
  margin-top: 10px;
}

.loadSkeletonText {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-top: 20px !important;
}

.exploreSectionDesktop {
  width: 100%;
  background-color: $color-sky-blue-dark !important;
  margin-top: -100px;
  border-radius: 0 30px 30px 30px !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 400px;

  @include gammaSize {
    display: none;
  }

  .exploreBody {
    padding: 20px;
    width: 100%;

    .navSection {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      h5 {
        color: $white;
      }

      .navButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 80%;
        height: 100%;
      }
    }

    .chartSection {
      .top {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 10px;

        p {
          color: $white;
        }

        .tipsIcon {
          width: 30px;
          margin-top: -10px;
        }
      }
    }
  }
}

.blogPostSection {
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 10px;
  border: 1px solid rgba(236, 231, 220, 1);
  background-color: $white;
  margin-top: 40px;

  @include gammaSize {
    margin-top: 30px;
  }

  h5 {
    @include subHeading1;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .blogCardSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.expertSection {
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 10px;
  border: 1px solid rgba(236, 231, 220, 1);
  background-color: $white;
  margin-top: 40px;

  @include gammaSize {
    margin-top: 30px;
  }

  h5 {
    @include subHeading1;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .blogCardSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .noPostsText {
    margin-top: -10px;
  }
}

.responsesRoot {
  overflow: scroll;
  height: 500px;
  @include betaSize {
    padding: 10px;
  }
}

.responsesRoot::-webkit-scrollbar {
  display: none;
}

.responseCardRoot {
  margin-bottom: 20px;
}

.responseEmpty {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}

.bellSection {
  margin-top: 20px;
  border-radius: 30px;
  padding: 23px 29px;
  background-color: #0072b0;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.marginLeft {
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  .outStandingAc {
    font-size: 18px;
    font-weight: 600;
    color: $white;
  }

  .bellIcon {
    height: 60px;
  }
}

.blueTray {
  height: 94px;
}

.responseFlexContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  flex-wrap: wrap;
}

.responseCardRoot {
  flex-basis: 49%;
  margin-bottom: 5px;

  @include gammaSize {
    flex-basis: 100%;
  }
}

.noResponseContainer {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.noResponseText {
  font-size: 24px;
  font-weight: 600;
  margin-top: 40px;
  color: white;
  text-align: center;
}

.stackContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;

  @include gammaSize {
    flex-direction: column;
    gap: 20px;
  }
}

.responsesSection {
  h6 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.bottomBlogs {
  margin-bottom: 50px;
  margin-top: -60px;

  @include alphaSize {
    margin-top: -120px;
  }
}

.noPlansSection {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
  height: 500px;

  @include gammaSize {
    height: auto;
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .imageCont {
    width: 100%;
    text-align: center;
    .emptyPlansImg {
      width: 40%;
      margin-top: -40px;

      @include gammaSize {
        margin-top: 0px;
      }
    }
  }

  .texts {
    p {
      text-align: center;
      color: $text-dark-grey;
      font-size: 18px;
    }
  }

  .button {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 20px;

    @include gammaSize {
      margin-bottom: 0px;
    }
  }
}

.hsSecondaryBtn {
  background: $white;
  color: $color-sky-blue-medium;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;

  &:hover {
    border: 2px solid $color-sky-blue-light;
    color: $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.hsStartIcon {
  width: 20px;
  margin-left: 5px;
}

.marginTopHorizontal {
  margin-top: 20px !important;

  @include gammaSize {
    margin-top: 10px !important;
  }
}

.marginTopHorizontalGammaSize {
  margin-top: 0;

  @include gammaSize {
    margin-top: 10px !important;
  }
}

.postsTitle {
  margin-bottom: 46px;
  margin-top: 91px;
  font-weight: 600;
  font-size: 32px;
  color: $text-dark-grey;

  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
}

.postsBtnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 72px;

  .moreLearningText {
    text-align: center;
    cursor: pointer;
    border-radius: 24px;
    padding: 9px 44px;
    background-color: #0072b0;
    font-weight: 600;
    color: $white;
    text-decoration: none !important;

    &:hover {
      background: $color-sky-blue-medium;
    }
  }
}
