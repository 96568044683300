.border-calm-lilac {
  border-color: $color-calm-lilac-medium;
}

.border-sky-blue {
  border-color: $color-sky-blue-medium;
}

.border-sun-yellow {
  border-color: $color-sun-yellow-medium;
}

.border-zingy-green {
  border-color: $color-zingy-green-medium;
}

.border-aqua-green {
  border-color: $color-aqua-green-medium;
}

.border-warm-orange {
  border-color: $color-warm-orange-medium;
}


.bg-calm-lilac {
  background-color: $color-calm-lilac-medium;
}

.bg-sky-blue {
  background-color: $color-sky-blue-medium;
}

.bg-sun-yellow {
  background-color: $color-sun-yellow-medium;
}

.bg-zingy-green {
  background-color: $color-zingy-green-medium;
}

.bg-aqua-green {
  background-color: $color-aqua-green-medium;
}

.bg-warm-orange {
  background-color: $color-warm-orange-medium;
}


.color-calm-lilac {
  color: $color-calm-lilac-medium;
}

.color-sky-blue {
  color: $color-sky-blue-medium;
}

.color-sun-yellow {
  color: $color-sun-yellow-medium;
}

.color-zingy-green {
  color: $color-zingy-green-medium;
}

.color-aqua-green {
  color: $color-aqua-green-medium;
}

.color-warm-orange {
  color: $color-warm-orange-medium;
}
