@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.inventoryCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 30px;
  width: 100%;

  .topHeader {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    align-content: center;

    .cardTitle {
      color: $text-dark-grey;
      font-weight: 600;
      margin-top: 10px;
    }

    .helpIcon {
      margin-top: 2px;
    }
  }

  .barContainer {
    position: relative;
    margin-top: 12px;
    width: 100%;

    .bar {
      display: flex;
      height: 38px;
      border-radius: 4px;
      overflow: hidden;
    }

    .segment {
      flex: 1;
    }

    .segment1 {
      background-color: #fff7c0;
    }

    .segment2 {
      background-color: #ffeb78;
    }

    .segment3 {
      background-color: #ffd441;
    }

    .segment4 {
      background-color: #ffa52d;
    }

    .segment5 {
      background-color: #f25a3c;
    }

    .segment6 {
      background-color: #f13e27;
    }

    .segment7 {
      background-color: #e30000;
    }

    .indicator {
      position: absolute;
      bottom: -35px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .arrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid black;
      rotate: 180deg;
      cursor: pointer;
    }

    .value {
      font-size: 16px;
    }
  }

  .indicatorContainer {
    margin-top: 30px;
    cursor: default;

    .indicators {
      display: flex;
      flex-direction: column;

      .indicator {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: -10px;

        .indicatorIcon {
          width: 18px;
          margin-top: 6px;
        }

        p {
          font-size: 18px;

          .inventionTitle {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.loadingContainer {
  .top {
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
  }

  .body {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    margin-top: 20px !important;
  }
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .blueTrayImg {
    height: 94px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}
