.cardContainer {
  width: 730px;
  margin: 20px auto;

  .registrationContentContainer {
    width: 100%;
    padding: 20px 26px;
    display: flex;
    align-content: center;
    justify-content: center;

    .organizationLogoHolder {
      width: 100%;
      padding: 10px 20px;
    }

    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #505050;
      margin-bottom: 40px;
    }

    .registrationDetails {
      width: 80%;
      margin: 0 auto;
    }

    .backContainer {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    width: 95%;

    .registrationContentContainer {
      padding: 0;

      .organizationLogoHolder {
        width: 60%;
        text-align: center;
        margin: auto;
      }

      h1 {
        font-size: 34px;
      }

      p {
        text-align: center;
      }

      .registrationDetails {
        width: 95%;
      }
    }
  }
}
