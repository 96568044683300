@import '../../assets/styles/variables';

.root {
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ask {
    cursor: pointer;
  }
}

.input {
  width: 100%;
  padding: 13px 15px 14px;
  background: $white;
  border: 2px solid $color-putty-dark;
  border-radius: 10px;
  margin-top: 16px;

  &:disabled {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: 0;
  }
}

.sliceAndDice {
  border-radius: 55px;
  border: 3px solid $color-sky-blue-medium;
  height: 40px;
  color: $color-sky-blue-medium;
  font-weight: 600;
  text-transform: uppercase;
}

.error {
  border-color: red;
}

.card {
  padding: 30px;
  width: 437px;
  background: rgba($white, 90%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  position: absolute;
  bottom: -150px;
  z-index: 999;

  .icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }

  .close {
    margin-right: 11px;
  }

  .titleCard {
    font-weight: 600;
    font-size: 24px;
  }
  .descriptionCard {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
  }
  .space {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .empty {
      display: flex;
      flex: 1;
    }
    .button {
      border: 3px solid transparent;
      background: $color-sky-blue-medium;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 50px;
      margin-top: 50px;
      cursor: pointer;
      border-radius: 24px;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.5s;
      line-height: 22px;
      text-decoration: none;
    }
  }
}

label {
  font-weight: 600;
  font-size: 16px;
  color: $text-dark-grey;
}
