@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.cardContainer {
  width: 730px;
  margin: 20px auto;

  .contentContainer {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    align-content: center;
    justify-content: center;

    .content {
      width: 100%;
    }

    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #505050;
      margin-bottom: 40px;
    }

    .message {
      text-align: center;
    }
  }
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -60px;
}
.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color-go-green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  background-color: $color-go-green;
}
.checkmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px $color-go-green;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    background-color: $color-go-green;
  }
}

.loader {
  border-radius: 40px !important;
  width: 730px !important;
}
