.hsQuestionItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  &RadarContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  &Radar {
    width: 500px;
    height: 440px;
  }
}
