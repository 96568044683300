@import "../../assets/styles/variables";

.hsPageTitle {
  color: $text-dark-grey;

  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}
