.FirstStarMessageContent {
  div {
    display: flex;
    padding: 5px;
  }

  p {
    margin-bottom: 0;
  }

  img {
    width: 20px;
  }

  .btnContainer {
    justify-content: flex-end;
    margin-bottom: 30px;

    button {
      margin: 30px 10px;
    }
  }
}

@media (max-width: 768px) {
  .FirstStarMessageContent {
    .btnContainer {
      justify-content: center;

      button {
        margin: 30px 10px;
        padding: 9px 10px;
      }
    }
  }
}
