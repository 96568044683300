@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include betaSize {
    gap: 5px;
  }

  .formLabel {
    font-weight: 600;
    font-size: 16px;
    color: #505050;
    margin-bottom: 15px;
  }
}

.manageableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  .formContainer {
    width: 100%;
  }

  .manage {
    color: $color-sky-blue-medium;
    cursor: pointer;
    font-weight: 600;
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .manage:hover {
    color: $color-sky-blue-light;
  }
}
