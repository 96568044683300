.otpGroup {
  display: flex;
  width: 100%;
}

.otpInput {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 48px;
  padding: 0px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  margin: 5px;
}

.error {
  border-color: red;
}
