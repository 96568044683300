@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsRoot {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.hsEmpty {
  font-size: 30px;
  color: white;
  display: flex;
  height: 85%;
  justify-content: center;
  align-items: center;
}

.hsActionError {
  width: 100%;
  display: flex;
  color: red;
  font-size: 20px;
  justify-content: center;
  margin: 10px 0;
}

.hsNoDataAction {
  font-size: 30px;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 25px;
  }
}

.hsNoData {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}

.hsCategoryContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    width: 190px;
    justify-content: center;
  }
}

.actionCard {
  overflow-y: scroll;
  height: 450px;
}
.actionCard::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.focusContainer {
  overflow-y: scroll;
  min-height: 300px;
  max-height: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 25px 100px 25px;
  }
}

.focusContainer::-webkit-scrollbar {
  display: none;
}

.hsAddCategoryContainer {
  margin-top: 20px;
}

.hsSpeakToCoachContainer {
  background-color: $color-sky-blue-medium;
  background-image: url('../../assets/images/speak-to-coach.svg');
  background-size: 100%;
  border-radius: 50px;
  margin-top: 41px !important;
  color: $white;

  .hsSpeakToCoachTextContainer {
    padding: 54px 80px;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  .hsSelectedAreasContainer {
    padding: 30px;

    .hsSelectedAreas {
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      border-radius: 30px;
      padding: 24px 31px;

      label {
        background: $white;
        color: $text-dark-grey;

        + {
          label {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.hsActionsEmergency {
  background-color: $color-putty-medium;
  border: 4px solid $error-red;
  border-radius: 50px;
  margin-top: 41px !important;
  color: $text-dark-grey;

  .hsActionsEmergencyTextContainer {
    padding: 54px 80px;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  .hsActionsEmergencyDetailsContainer {
    padding: 72px 67px;

    ul {
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: 1px solid $text-deactivated;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        .hsDetailsCountry {
          font-weight: 600;
        }

        + {
          li {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hsCategoryContainer {
    flex-wrap: wrap;
    justify-content: end;
    gap: 10px;
  }

  .hsSpeakToCoachContainer {
    border-radius: unset;
    background-image: unset;
    margin-top: 0 !important;

    .hsSpeakToCoachTextContainer {
      padding: 30px 30px 0;
    }

    .hsSelectedAreasContainer {
      padding: 30px;

      .hsSelectedAreas {
        button {
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }

  .hsActionsEmergency {
    margin: 20px 20px 45px !important;

    .hsActionsEmergencyTextContainer {
      padding: 30px 30px 0;
    }

    .hsActionsEmergencyDetailsContainer {
      padding: 30px;
    }
  }
}

.cardContainer {
  margin-top: 20px !important;
  margin-bottom: 10px;

  @include betaSize {
    margin-top: 0 !important;
    margin-bottom: 30px;
  }

  h4 {
    color: $text-dark-grey;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h5 {
    color: $text-dark-grey;
    text-align: left;
    margin-top: 20px;
    font-size: 18px;
  }
}

// new styles starts here
.actionContainer {
  .coloredBoxContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .coloredBox {
      width: 50%;

      @include alphaSize {
        width: 100%;
      }
    }

    .footerContainer {
      position: relative;
      overflow: hidden;
      margin-right: -30px;
      margin-bottom: -150px;

      @include alphaSize {
        margin-bottom: -100px !important;
      }
    }

    .footerImage {
      float: right;
      clear: both;
      width: 300px;
      height: 300px;
      background-image: url('../../assets/images/score-action.svg');
      background-size: cover;
    }
  }
}

.noFocusContainer {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  height: 80%;

  .blueTray {
    height: 94px;
  }

  .noChosenAreaText {
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
    color: white;
    text-align: center;
  }
}
