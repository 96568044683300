@import '../../assets/styles/variables';

.hsScoreButtons {
  display: flex;
  justify-content: center;
  gap: 4px;

  div {
    input[type='radio'] {
      display: none;
      + {
        label {
          color: $text-dark-grey;
          border: 1px solid $color-putty-dark;
          background: $white;
          width: 31px;
          height: 31px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          line-height: 19px;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.5s ease-in-out;

          &:hover {
            border-color: $color-sky-blue-medium;
          }
        }
      }

      &:checked {
        + {
          label {
            background-color: $color-sky-blue-medium;
            color: $white;
            border-color: $color-sky-blue-medium;
          }
        }
      }
    }
  }
}
