@import '../../../assets/styles/variables';

.hsMobileFooter {
  width: 100%;
  padding: 14px 0.5px 18px 0.5px;
  background: $white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  z-index: 11;

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    gap: 10px;

    li {
      flex: 1 1 50px;
      width: 50px;

      &:last-child {
        flex: 0 0 10px;
        margin-right: -8px;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: $text-dark-grey;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.moreMenuIcon {
  margin-top: 10px;
}
