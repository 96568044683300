.selectContainer {
  .labelContainer {
    display: flex;
    justify-content: space-between;

    label {
      margin-bottom: 5px;
    }

    .tooltipContainer {
      display: flex;
      gap: 10px;
    }
  }

  .selectGroup {
    display: flex;
    justify-content: space-between;

    .select {
      width: 45%;
    }
  }
}

.positionTop {
  flex-direction: column;
  gap: 5px;
}

.positionBottom {
  flex-direction: column-reverse;
  gap: 5px;
}

.positionRight {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.positionLeft {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
