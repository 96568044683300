@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsTitleRoot {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}

.actionCard {
  overflow-y: scroll;
  height: 450px;
}

.actionCard::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hsNoDataAction {
  font-size: 30px;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subtitle {
  font-size: 32px;
  font-weight: 600;
  margin-top: 39px;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

.firstStarMessageTitle {
  text-align: left;
}

.firstStarMessageTitleM {
  text-align: center;
}

.categoryScoreError {
  height: 40vh;
  font-size: 30px;
  margin-top: 50px;
}

.scoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  // padding: auto;

  .historicalDataLeftArrow {
    flex: auto;

    .leftArrowIcon {
      height: 40px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        height: 30px;
      }
    }
  }

  .wingDetailsContainer {
    flex: auto;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .gaugeContainer {
    flex: auto;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 992px) {
      width: 33.3333%;
      display: flex;
    }
  }

  .categoryScoreContainer {
    flex: auto;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .historicalDataRightArrow {
    flex: auto;
    margin-left: 20px;

    .rightArrowIcon {
      height: 40px;
      cursor: pointer;
      transform: rotate(180deg);

      @media screen and (max-width: 768px) {
        height: 30px;
      }
    }
  }
}

.areaContainer {
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
}

.areaError {
  height: 40vh;
  font-size: 30px;
  margin-top: 50px;
}

.imgHeight94 {
  height: 94px;
}

.actionsCompletedText {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
  color: $white;
  text-align: center;
}

.noActionsContainer {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: $white;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .noActionsText {
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
    color: $white;
    text-align: center;
  }
}

.width40 {
  width: 40px;
}

.completedStar {
  width: 35px;
}

.fullWidth {
  width: 100% !important;
  max-width: 100% !important;
}

.arrowCont {
  width: 40px;
}

.actionTabContainer {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  width: 98%;

  @include gammaSize {
    width: 100% !important;
  }

  @include betaSize {
    width: 100% !important;
  }
}

.actionTabSection {
  width: 100% !important;
  padding: 8px;

  @include gammaSize {
    width: 100% !important;
    padding: 20px;
  }

  @include betaSize {
    width: 100% !important;
    padding: 0 !important;
  }
}
