@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'iconly';
@import 'global';
@import 'presets';
@import './variables';
@import './_utilities/typography';

* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: $color-putty-light;
  font-family: 'Titillium Web', sans-serif !important;
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
div,
span,
typography,
text,
canvas,
a,
blockquote,
li,
ul,
ol,
button,
label,
input,
textarea,
select,
strong,
em,
th,
td,
.mui-typography-root,
.mui-button-root,
.mui-textfield-root,
.mui-card-root,
.mui-chip-root {
  font-family: 'Titillium Web', sans-serif !important;
}

p,
b,
i {
  color: $text-dark-grey;
}
#root {
  height: 100%;
}

.boxed {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 49px 68px 40px;
  min-height: calc(100vh - 339.69px);

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 156.8px);
  }
}

.boxedNew {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.small_container {
  max-width: 367px;

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
}

.react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 34px;

  li.react-tabs__tab {
    border: 1px solid $white;
    display: block;
    width: 200px;
    height: 40px;
    background: $color-putty-dark;
    color: $text-deactivated;
    text-decoration: none;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease;
    outline: unset;

    &:hover {
      background: $color-putty-medium;
      color: $text-dark-grey;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }

    &.react-tabs__tab--selected {
      background: $color-sun-yellow-medium;
      color: $text-dark-yellow;
    }
  }
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  height: 488px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-tabs__tab-panel::-webkit-scrollbar {
  display: none;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  overflow-y: scroll !important;
  z-index: 9999;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 642px;
  min-height: 300px;
  margin: 0 auto;
  background: #ffffff !important;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 30px !important;
  border: none !important;
  padding: 0 !important;
  overflow: hidden !important;
  inset: unset !important;
  outline: unset !important;
  left: 0 !important;
  right: 0 !important;
  top: 10% !important;
}

@media screen and (max-width: 768px) {
  .boxed {
    width: 100%;
    padding: 25px 0 0 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgb(255, 255, 255);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  border-radius: 100%;
  opacity: 0.3;
  width: 30px !important;
  height: 30px !important;
}

.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-prev {
  background-image: url('../images/ios-back.svg');
  background-size: 20px;
  width: 25px;
  height: 25px;
  padding: 10px;
  margin-left: -10px;
}

.swiper-button-next {
  background-image: url('../images/ios-next.svg');
  background-size: 20px;
  width: 25px;
  height: 25px;
  padding: 10px;
  margin-right: -10px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '';
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: rgb(29, 29, 29);
  margin-bottom: -12px !important;
}

.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  position: absolute !important;
}

.react-multiple-carousel__arrow::before {
  color: $color-sky-blue-dark !important;
  font-weight: bolder !important;
  font-size: 24px !important;
  text-shadow: 1px 1px 2px rgba(63, 185, 252, 0.5);
}

.react-multiple-carousel__arrow:hover::before {
  color: $color-sky-blue-dark !important;
}

.react-multiple-carousel__arrow--right {
  background-color: transparent !important;
  right: calc(-5% + 1px);

  @include gammaSize {
    background-color: rgba(255, 255, 255, 0.7) !important;
    right: calc(3% + 1px) !important;
  }
}

.react-multiple-carousel__arrow--right:hover {
  background-color: rgba(63, 185, 252, 0.2) !important;

  @include gammaSize {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}

.react-multiple-carousel__arrow--left {
  background-color: transparent !important;
  left: calc(-5% + 1px);

  @include gammaSize {
    background-color: rgba(255, 255, 255, 0.7) !important;
    left: calc(3% + 1px) !important;
  }
}

.react-multiple-carousel__arrow--left:hover {
  background-color: rgba(63, 185, 252, 0.2) !important;

  @include gammaSize {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}

.ai-suggestions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.aiPlan {
  color: $color-sky-blue-dark;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 12px;
  border: 1px solid $color-sky-blue-dark;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.aiPlan:hover {
  background-color: $color-sky-blue-dark;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.ai-learnings-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: center;
  padding: 20px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ai-learning {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  max-width: 100%;
  min-height: 150px;
}

.ai-learning:hover {
  transform: translateY(-2px) scale(1.1);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.ai-learning-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.ai-learning-title:hover {
  color: $color-sky-blue-medium;
}

.ai-learning-title {
  font-size: 12px;
  font-weight: 400;
  color: $text-dark-grey;
  text-align: center;
  padding: 8px 10px;
  word-wrap: break-word;
}
