@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.modalContainer {
  h3 {
    @include subHeading1;
  }
  p {
    margin-top: 20px;
    @include bodyCopy;

    span {
      font-weight: 600;
    }
  }

  .btnActionContainer {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 50px;
  }

  .btnAction {
    background-color: #019df2;
    border-radius: 24px;
    padding: 9px 24px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
  }
}

.actionBtnContainer {
  text-decoration: none;
  color: inherit;

  .actionButton {
    @include flexContainer(none, nowrap, center, center, 7px);
    background-color: $color-sky-blue-medium;
    color: $white;
    font-weight: 600;
    font-size: 14px;
    border-radius: 24px;
    padding: 9px 16px 9px 16px;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    height: 40px;
    min-width: 100%;

    &:hover {
      background-color: $color-sky-blue-light;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @include betaSize {
      flex: 1 0 calc(50% - 5px);
      min-width: 100%;
    }
  }
}
