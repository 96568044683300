@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  border: none;
  padding: 0;
  background-color: transparent;
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $black;
  cursor: pointer !important;
}

.modalRoot {
  z-index: 9999999999 !important;
  width: 60%;
  max-height: 95%;
  max-width: 100%;
  overflow-y: auto;
  //   background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 20px;
    min-width: 90%;
    overflow-x: hidden;
    max-height: 100%;
  }

  .modalBody {
    .modalTitle {
      font-weight: 600 !important;
      @include bodyCopyLarge;
      margin-top: -25px;
      font-weight: 500;
      font-size: 24px;
    }
    .btnArea {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.addBtn {
  margin-bottom: 20px;
  margin-right: 10px;
}

.listItemActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .actionItem {
    color: $color-sky-blue-medium;
    cursor: pointer;
    font-weight: 600;
  }

  .actionItem:hover {
    color: $color-sky-blue-light;
  }
}

.listItemText {
  color: $text-dark-grey;
}

.actionItemAdd {
  color: $color-sky-blue-medium;
  cursor: pointer;
  font-weight: 600;
  margin-left: 10px;
}

.actionItemAdd:hover {
  color: $color-sky-blue-light;
}
