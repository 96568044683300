@import "../../../assets/styles/variables";

.hsCreateAction {
  &Label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      color: $text-dark-grey;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  textarea {
    width: 100%;
    border: 2px solid $color-putty-dark;
    border-radius: 10px;
    resize: none;
    height: 100px;
    margin-top: 20px;
    padding: 13px 15px;
  }

  p.hsCharCounter {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: $text-dark-grey;
    margin: -4px 6px 0 0;
  }

  &Suggestions {
    margin-bottom: 70px;

    &Title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $text-dark-grey;
    }
  }

  .hsSuggestion {
    padding: 9px 24px;
    background: #FFFFFF;
    border: 3px solid transparent;
    border-radius: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;

    + {
      .hsSuggestion {
        margin-top: 10px;
      }
    }
  }

  .hsButtonContainer {
    margin-top: 100px;
    text-align: right;
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }

    .hsSuggestion {
      border-color: $color-calm-lilac-medium;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-sky-blue-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-sky-blue-medium;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-sun-yellow-medium;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-zingy-green-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-zingy-green-medium;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-aqua-green-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-aqua-green-medium;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-warm-orange-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-warm-orange-medium;
    }
  }
}
