@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.mainContainer {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 20px;

  .cardTitle {
    color: $text-dark-grey;
    font-weight: 600;
    margin-top: 10px;
  }

  .awaitingResContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: nowrap;
    flex: 1;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: thin;
    margin-top: 20px;
    padding: 10px;

    .commentCard {
      flex: 1;
      max-width: 100%;
      @include gammaSize {
        max-width: 100% !important;
      }
    }

    @include gammaSize {
      flex-direction: column;
      width: 100%;
    }
  }

  .noDataSection {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .blueTrayImg {
      height: 94px;
    }

    p {
      font-size: 24px;
      font-weight: 400;
      margin-top: 40px;
      color: $text-dark-grey;
      text-align: center;
    }
  }
}

.backdropText {
  color: $white;
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  margin-top: 30px !important;

  .loadingItem {
    border-radius: 24px !important;
  }
}
