@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.contentContainer {
  cursor: default;

  @include gammaSize {
    padding: 20px;
    padding-bottom: 60px;
  }
  .topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .navigateBack {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      align-content: center;
      transition: transform 0.3s ease-in-out;
      animation: fadeIn 0.5s ease-out;
      cursor: pointer;

      @include gammaSize {
        margin-top: 6px;
      }

      h5 {
        color: $color-sky-blue-medium;
        font-weight: 600;

        @include gammaSize {
          font-size: 14px;
        }

        &:hover {
          color: $color-sky-blue-light;
        }
      }

      .previous {
        width: 20px;
        margin-top: -5px;

        @include gammaSize {
          width: 15px;
        }
      }

      &:hover {
        transform: translateY(-2px);
        color: $color-sky-blue-light;
      }
    }
  }

  .mainTitle {
    color: $text-dark-grey;
    font-weight: 600;
  }

  .participantUniqueValue {
    margin-top: 22px;
  }
}

.errorContainer {
  text-align: center;
  margin-top: 100px;
  min-height: calc(100vh - 460px);

  h4 {
    color: $text-dark-grey;
    margin-bottom: 40px;
  }
}
