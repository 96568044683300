@import '../../../assets/styles/variables';

.react-select-container {
  [class$='-control'] {
    width: 100%;
    border: 2px solid $color-putty-dark;
    border-radius: 10px;
    min-height: 38px;
    padding: 7.5px 15px;

    &:focus {
      border: 2px solid #3fb9fc;
    }

    &:focus-visible {
      outline: 0;
    }

    &:hover {
      border: 2px solid $color-putty-dark;
    }

    &:active {
      border: 2px solid $color-putty-dark;
      box-shadow: none;
    }
  }

  [class$='-ValueContainer'] {
    padding: 0px;
  }
}

.error {
  [class$='-control'] {
    border: 2px solid red;
  }
}
