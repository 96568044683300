.hsAddFocusArea {
  padding: 10px 20px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }

  .hsAddFocusAreaContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      border: 1px #ddd solid;
      background-color: #fcfbf7;
      border-radius: 15px;
      padding: 15px;
      margin-bottom: 30px;
    }

    .hsCheckboxContainer {
      width: 120px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    + {
      .hsAddFocusAreaContainer {
        margin-top: 10px;
      }
    }
  }

  .hsButtonContainer {
    margin-top: 100px;
    text-align: right;
  }
}
