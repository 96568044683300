@import "../../assets/styles/variables";

.hsButton {
  display: inline-flex;
  border: 3px solid transparent;
  background: transparent;
  border-radius: 24px;
  padding: 0 24px;
  font-size: 18px;
  height: 46px;
  min-width: 200px;
  font-weight: 600;
  transition: all .5s;
  text-decoration: none;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px){
    justify-content: center;
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
  }

  i {
    font-size: 25px;
  }

  &.hsPrimary, &.hsIconButton {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;

    &:hover {
      background: $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: .3;
    }
  }

  &.hsIconButton {
    align-items: center;
    gap: 11px;
    padding: 9px 24px;
  }

  &.hsSecondary, &.hsShowMoreButton {
    background: $white;
    border-color: $color-sky-blue-medium;
    color: $color-sky-blue-medium;

    &:hover {
      border-color: $color-sky-blue-light;
      color: $color-sky-blue-light;
    }

    &:disabled {
      border-color: $text-deactivated;
      color: $text-deactivated;
      opacity: .3;
    }
  }

  &.hsShowMoreButton {
    align-items: center;
    gap: 11px;
    padding: 9px 10px 9px 20px;
  }

  &.hsTertiary, &.hsSmall {
    background: $white;
    border-color: $color-sky-blue-medium;
    height: 35px;
    min-width: 100px;
    color: $color-sky-blue-medium;

    &:hover {
      color: $color-sky-blue-light;
    }

    &:disabled {
      color: $text-deactivated;
    }
  }

  &.hsSmall {
    font-size: 16px;
  }

  &.hsSmallText {
    font-size: 13px;

  }

  &.hsClose {
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    gap: 11px;
    color: $text-dark-grey;
  }
}
