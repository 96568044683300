@import "../../../assets/styles/variables";

.hsMobileFooter {
  width: 100%;
  padding: 5px 7.5px 15.8px 7.5px;
  background: $white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  z-index: 11;

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    gap: 10px;

    li {
      width: 65px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: $text-dark-grey;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
