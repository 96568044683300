@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.contentContainer {
  width: 100%;
  padding: 2rem;
  margin: 0 auto;
  min-height: calc(100vh - 356px);

  @include gammaSize {
    margin-bottom: 0;
  }

  h5 {
    color: $text-dark-grey;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.searchContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.autoComplete {
  width: 100%;
}

.resultContainer {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.noData {
  text-align: center;
  margin-top: 2rem;
}
