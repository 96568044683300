@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.container {
  h4 {
    font-weight: 600;
    color: $text-dark-grey;
  }
}

.topSubSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @include gammaSize {
    flex-direction: column;
  }

  .legend {
    display: flex;
    flex-direction: row;
    gap: 3px;

    .notAvailable {
      padding: 10px;
      background-color: $color-putty-dark;
      width: 120px;
      text-align: center;
      border-radius: 4px;

      @include gammaSize {
        width: 100%;
      }
    }

    .available {
      padding: 10px;
      background-color: $color-zingy-green-medium;
      width: 120px;
      text-align: center;
      border-radius: 4px;

      @include gammaSize {
        width: 100%;
      }
    }
  }
}

.bookingCalendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;

    @include gammaSize {
      margin-bottom: 40px;
    }

    .headerPrevious {
      display: none;

      @include gammaSize {
        display: block;
        rotate: -90deg;
        cursor: pointer;
      }
    }

    .headerNext {
      display: none;

      @include gammaSize {
        display: block;
        rotate: -90deg;
        cursor: pointer;
      }
    }

    .timezone {
      @include gammaSize {
        flex: 1;
      }
    }
  }

  .calendar {
    display: grid;
    grid-template-columns: 100px repeat(7, 1fr);
    grid-template-rows: 50px repeat(20, 40px);
    gap: 20px;
    min-width: 100%;

    .times {
      grid-column: 1 / 2;
      grid-row: 2 / -1;
      display: flex;
      flex-direction: column;

      .time {
        background-color: $color-sun-yellow-medium;
        text-align: right;
        padding: 0.5rem;
        border: 1px solid $white;
        text-align: center;
      }
    }

    .dates {
      grid-column: 2 / -1;
      grid-row: 1 / 2;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .date {
      flex: 1;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background-color: $color-sun-yellow-medium;
      text-align: center;
      border: 1px solid $white;
      height: 100%;

      @include gammaSize {
        height: auto;
        margin-top: -20px;
      }
    }

    .slots {
      grid-column: 2 / -1;
      grid-row: 2 / -1;
      display: flex;

      .day {
        flex: 1;
        display: flex;
        flex-direction: column;

        .slot {
          background-color: $color-putty-dark;
          text-align: center;
          padding: 0.5rem;
          border: 1px solid $white;

          &.available {
            cursor: pointer;
            background-color: $color-zingy-green-medium;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bookingCalendar .calendar {
    grid-template-columns: 100px repeat(3, 1fr);
  }
}

.slotsLoading {
  grid-column: 2 / -1 !important;
  grid-row: 2 / -1 !important;
  display: flex !important;

  .dayLoading {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 1px !important;

    .slotLoading {
      text-align: center !important;
      height: 42px !important;
      border-bottom: 2px solid $white !important;
    }
  }
}

.scrollButton {
  text-align: center;
  .timePaginationIcon {
    width: 50px;
    margin-top: 2px;
    cursor: pointer;
  }

  .timePaginationIconNext {
    width: 50px;
    margin-top: 15px;
    cursor: pointer;
  }
}

.scrollButtonDisabled {
  text-align: center;
  opacity: 0.4;
  .timePaginationIcon {
    width: 50px;
    margin-top: 2px;
    cursor: auto;
  }

  .timePaginationIconNext {
    width: 50px;
    margin-top: 15px;
    cursor: auto;
  }
}

.autocomplete {
  width: 300px;

  .autocompleteTextfield {
    border-radius: 40px;
  }
}

.hsSecondaryBtn {
  @include gammaSize {
    display: none;
  }

  background: $white;
  border: 3px solid $color-sky-blue-medium;
  color: $color-sky-blue-medium;
  display: inline-flex;
  background: transparent;
  justify-content: center;
  border-radius: 24px;
  padding: 10px 15px;

  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  height: 50px;
  min-width: 150px !important;

  &:hover {
    border-color: $color-sky-blue-light;
    color: $color-sky-blue-light;
  }

  &:disabled {
    border-color: $text-deactivated;
    color: $text-deactivated;
    opacity: 0.3;
  }
}

.creditsExpiredSection {
  .creditsExpiredSectionContent {
    text-align: center;
    margin-top: 40px;

    .warningIcon {
      width: 50px;
      margin-bottom: 40px;
    }

    .content {
      margin-bottom: 60px;
    }
  }
}
