@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.recommendationsCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 30px;
  margin-top: 20px;

  .topHeader {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    align-content: center;

    .cardTitle {
      color: $text-dark-grey;
      font-weight: 600;
      margin-top: 10px;
    }

    .helpIcon {
      margin-top: 2px;
    }
  }

  .skeletonContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
  }

  .insightsContent {
    .summary {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 12px;
      margin-top: 20px;
      color: $text-dark-grey;
    }

    .highlights {
      list-style: disc;
      padding-left: 20px;

      li {
        font-size: 0.95rem;
        color: $text-dark-grey;
        margin-bottom: 8px;
      }
    }
  }

  .noDataSection {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .blueTrayImg {
      height: 94px;
    }
    p {
      font-size: 24px;
      font-weight: 400;
      margin-top: 40px;
      color: $text-dark-grey;
      text-align: center;
    }
  }
}
