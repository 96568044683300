@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  border: none;
  padding: 0;
  background-color: transparent;
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $black;
  cursor: pointer !important;
}

.modalRootMod {
  min-height: 570px;
}

.modalRoot {
  z-index: 999999 !important;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60%;
  max-width: 100%;
  min-height: 550px;
  max-height: 610px;
  overflow-y: auto;
  background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 20px;
    min-width: 90%;
    overflow-x: hidden;
    max-height: 100%;
  }

  .modalBody {
    .modalTitle {
      font-weight: 600 !important;
      @include bodyCopyLarge;
      margin-top: -25px;
      font-weight: 500;
      font-size: 24px;
    }
    .btnArea {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.chatContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 380px;
  overflow-y: auto;
  background-color: $color-putty-light;
  border-radius: 10px;
  padding: 16px;
  flex: 1;
}

.messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.messageBubble {
  padding: 10px 16px;
  border-radius: 10px;
  max-width: 50%;
  font-size: 18px;
  line-height: 1.4;
  position: relative;

  @include gammaSize {
    max-width: 80%;
    font-size: 14px;
  }
}

.userBubble {
  background-color: #0072b0;
  color: $white;
  align-self: flex-end;
  border-top-right-radius: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.systemBubble {
  background-color: #019ef22d;
  color: $text-dark-grey;
  align-self: flex-start;
  border-top-left-radius: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.errorInTimestamp {
  font-size: 12px;
  color: $color-warm-orange-light;
  margin-top: 4px;
  text-align: right;

  @include gammaSize {
    font-size: 10px;
  }
}

.sendingTimestamp {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  color: $white;
  margin-top: 4px;
  text-align: right;

  @include gammaSize {
    font-size: 10px;
  }
}

.timeStampUser {
  font-size: 12px;
  color: $white;
  margin-top: 4px;
  text-align: right;

  @include gammaSize {
    font-size: 10px;
  }
}

.timeStampSystem {
  font-size: 12px;
  color: $text-dark-grey;
  margin-top: 4px;
  text-align: right;

  @include gammaSize {
    font-size: 10px;
  }
}

.messageInputArea {
  padding: 8px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  .shareMyIdentityInner {
    display: flex;
    flex-direction: column;

    p {
      color: $text-dark-grey;
      font-size: 12px;
      margin-top: -10px;
    }
  }
}

.messageInputContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.messageInput {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  outline: none;
  background-color: #f0f0f0;
}

.sendButton {
  background-color: #04bc00;
  border: none;
  padding: 8px;
  margin-left: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .sendIcon {
    color: $white;
  }
}

.sendButton:disabled {
  background-color: $text-deactivated;
  cursor: default;
}

.loadingText {
  min-width: 250px !important;
  max-width: 300px !important;

  @include gammaSize {
    min-width: 150px !important;
    max-width: 200px !important;
  }
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  .blueTrayImg {
    height: 94px;
  }

  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}

.responseLimitExceededContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;

  p {
    color: $text-dark-grey;
    text-align: center;

    span {
      color: $color-sky-blue-medium;
      cursor: pointer;
      font-weight: 600;
    }

    span:hover {
      color: $color-sky-blue-light;
    }
  }
}
