@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.responsesRoot {
  overflow: scroll;
  max-height: 100% !important;
  @include betaSize {
    padding: 10px;
  }
}

.topHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 20px;

  .buttonCreate {
    margin-top: -30px;

    @include gammaSize {
      margin-top: -30px;
      margin-bottom: 30px;
      align-self: center;
    }
  }

  @include gammaSize {
    flex-direction: column;
    align-items: flex-start;
  }
}

.responsesRoot::-webkit-scrollbar {
  display: none;
}

.responseCardRoot {
  margin-bottom: 20px;
}

.responseEmpty {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}

.bellSection {
  margin-top: 20px;
  border-radius: 30px;
  padding: 23px 29px;
  background-color: #0072b0;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.marginLeft {
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  .outStandingAc {
    font-size: 18px;
    font-weight: 600;
    color: $white;
  }

  .bellIcon {
    height: 60px;
  }
}

.blueTray {
  height: 94px;
}

.responseFlexContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  flex-wrap: wrap;
}

.responseCardRoot {
  flex-basis: 49%;
  margin-bottom: 5px;

  @include gammaSize {
    flex-basis: 100%;
  }
}

.noResponseContainer {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.noResponseText {
  font-size: 24px;
  font-weight: 600;
  margin-top: 40px;
  color: white;
  text-align: center;
}

.stackContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;

  @include gammaSize {
    flex-direction: column;
    gap: 20px;
  }
}

.responsesSection {
  h6 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    color: $text-dark-grey;
  }
}

.bottomBlogs {
  margin-bottom: 50px;
  margin-top: -40px;
}
