/*!
 *  Iconly icon font. Generated by Iconly: https://iconly.io/
 */

@font-face {
  font-display: auto;
  font-family: "Iconly";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/hs-icons/iconly.eot?1664351076416");
  src: url("../fonts/hs-icons/iconly.eot?#iefix") format("embedded-opentype"), url("../fonts/hs-icons/iconly.woff2?1664351076416") format("woff2"), url("../fonts/hs-icons/iconly.woff?1664351076416") format("woff"), url("../fonts/hs-icons/iconly.ttf?1664351076416") format("truetype"), url("../fonts/hs-icons/iconly.svg?1664351076416#Iconly") format("svg");
}

[class="icon"], [class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  font-family: "Iconly", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.icon-zoom-out:before {
  content: "\e000";
}

.icon-zoom-in:before {
  content: "\e001";
}

.icon-zap:before {
  content: "\e002";
}

.icon-zap-off:before {
  content: "\e003";
}

.icon-youtube:before {
  content: "\e004";
}

.icon-x:before {
  content: "\e005";
}

.icon-x-square:before {
  content: "\e006";
}

.icon-x-octagon:before {
  content: "\e007";
}

.icon-x-circle:before {
  content: "\e008";
}

.icon-wind:before {
  content: "\e009";
}

.icon-wifi:before {
  content: "\e00a";
}

.icon-wifi-off:before {
  content: "\e00b";
}

.icon-watch:before {
  content: "\e00c";
}

.icon-volume:before {
  content: "\e00d";
}

.icon-volume-x:before {
  content: "\e00e";
}

.icon-volume-2:before {
  content: "\e00f";
}

.icon-volume-1:before {
  content: "\e010";
}

.icon-voicemail:before {
  content: "\e011";
}

.icon-video:before {
  content: "\e012";
}

.icon-video-off:before {
  content: "\e013";
}

.icon-users:before {
  content: "\e014";
}

.icon-user:before {
  content: "\e015";
}

.icon-user-x:before {
  content: "\e016";
}

.icon-user-plus:before {
  content: "\e017";
}

.icon-user-minus:before {
  content: "\e018";
}

.icon-user-check:before {
  content: "\e019";
}

.icon-upload:before {
  content: "\e01a";
}

.icon-upload-cloud:before {
  content: "\e01b";
}

.icon-unlock:before {
  content: "\e01c";
}

.icon-underline:before {
  content: "\e01d";
}

.icon-umbrella:before {
  content: "\e01e";
}

.icon-type:before {
  content: "\e01f";
}

.icon-twitter:before {
  content: "\e020";
}

.icon-twitch:before {
  content: "\e021";
}

.icon-tv:before {
  content: "\e022";
}

.icon-truck:before {
  content: "\e023";
}

.icon-triangle:before {
  content: "\e024";
}

.icon-trending-up:before {
  content: "\e025";
}

.icon-trending-down:before {
  content: "\e026";
}

.icon-trello:before {
  content: "\e027";
}

.icon-trash:before {
  content: "\e028";
}

.icon-trash-2:before {
  content: "\e029";
}

.icon-tool:before {
  content: "\e02a";
}

.icon-toggle-right:before {
  content: "\e02b";
}

.icon-toggle-left:before {
  content: "\e02c";
}

.icon-thumbs-up:before {
  content: "\e02d";
}

.icon-thumbs-down:before {
  content: "\e02e";
}

.icon-thermometer:before {
  content: "\e02f";
}

.icon-terminal:before {
  content: "\e030";
}

.icon-target:before {
  content: "\e031";
}

.icon-tag:before {
  content: "\e032";
}

.icon-tablet:before {
  content: "\e033";
}

.icon-sunset:before {
  content: "\e034";
}

.icon-sunrise:before {
  content: "\e035";
}

.icon-sun:before {
  content: "\e036";
}

.icon-stop-circle:before {
  content: "\e037";
}

.icon-star:before {
  content: "\e038";
}

.icon-square:before {
  content: "\e039";
}

.icon-speaker:before {
  content: "\e03a";
}

.icon-smile:before {
  content: "\e03b";
}

.icon-smartphone:before {
  content: "\e03c";
}

.icon-sliders:before {
  content: "\e03d";
}

.icon-slash:before {
  content: "\e03e";
}

.icon-slack:before {
  content: "\e03f";
}

.icon-skip-forward:before {
  content: "\e040";
}

.icon-skip-back:before {
  content: "\e041";
}

.icon-sidebar:before {
  content: "\e042";
}

.icon-shuffle:before {
  content: "\e043";
}

.icon-shopping-cart:before {
  content: "\e044";
}

.icon-shopping-bag:before {
  content: "\e045";
}

.icon-shield:before {
  content: "\e046";
}

.icon-shield-off:before {
  content: "\e047";
}

.icon-share:before {
  content: "\e048";
}

.icon-share-2:before {
  content: "\e049";
}

.icon-settings:before {
  content: "\e04a";
}

.icon-server:before {
  content: "\e04b";
}

.icon-send:before {
  content: "\e04c";
}

.icon-search:before {
  content: "\e04d";
}

.icon-scissors:before {
  content: "\e04e";
}

.icon-save:before {
  content: "\e04f";
}

.icon-rss:before {
  content: "\e050";
}

.icon-rotate-cw:before {
  content: "\e051";
}

.icon-rotate-ccw:before {
  content: "\e052";
}

.icon-rewind:before {
  content: "\e053";
}

.icon-repeat:before {
  content: "\e054";
}

.icon-refresh-cw:before {
  content: "\e055";
}

.icon-refresh-ccw:before {
  content: "\e056";
}

.icon-radio:before {
  content: "\e057";
}

.icon-printer:before {
  content: "\e058";
}

.icon-power:before {
  content: "\e059";
}

.icon-pocket:before {
  content: "\e05a";
}

.icon-plus:before {
  content: "\e05b";
}

.icon-plus-square:before {
  content: "\e05c";
}

.icon-plus-circle:before {
  content: "\e05d";
}

.icon-play:before {
  content: "\e05e";
}

.icon-play-circle:before {
  content: "\e05f";
}

.icon-pie-chart:before {
  content: "\e060";
}

.icon-phone:before {
  content: "\e061";
}

.icon-phone-outgoing:before {
  content: "\e062";
}

.icon-phone-off:before {
  content: "\e063";
}

.icon-phone-missed:before {
  content: "\e064";
}

.icon-phone-incoming:before {
  content: "\e065";
}

.icon-phone-forwarded:before {
  content: "\e066";
}

.icon-phone-call:before {
  content: "\e067";
}

.icon-percent:before {
  content: "\e068";
}

.icon-pen-tool:before {
  content: "\e069";
}

.icon-pause:before {
  content: "\e06a";
}

.icon-pause-circle:before {
  content: "\e06b";
}

.icon-paperclip:before {
  content: "\e06c";
}

.icon-package:before {
  content: "\e06d";
}

.icon-octagon:before {
  content: "\e06e";
}

.icon-navigation:before {
  content: "\e06f";
}

.icon-navigation-2:before {
  content: "\e070";
}

.icon-music:before {
  content: "\e071";
}

.icon-move:before {
  content: "\e072";
}

.icon-mouse-pointer:before {
  content: "\e073";
}

.icon-more-vertical:before {
  content: "\e074";
}

.icon-more-horizontal:before {
  content: "\e075";
}

.icon-moon:before {
  content: "\e076";
}

.icon-monitor:before {
  content: "\e077";
}

.icon-minus:before {
  content: "\e078";
}

.icon-minus-square:before {
  content: "\e079";
}

.icon-minus-circle:before {
  content: "\e07a";
}

.icon-minimize:before {
  content: "\e07b";
}

.icon-minimize-2:before {
  content: "\e07c";
}

.icon-mic:before {
  content: "\e07d";
}

.icon-mic-off:before {
  content: "\e07e";
}

.icon-message-square:before {
  content: "\e07f";
}

.icon-message-circle:before {
  content: "\e080";
}

.icon-menu:before {
  content: "\e081";
}

.icon-meh:before {
  content: "\e082";
}

.icon-maximize:before {
  content: "\e083";
}

.icon-maximize-2:before {
  content: "\e084";
}

.icon-map:before {
  content: "\e085";
}

.icon-map-pin:before {
  content: "\e086";
}

.icon-mail:before {
  content: "\e087";
}

.icon-log-out:before {
  content: "\e088";
}

.icon-log-in:before {
  content: "\e089";
}

.icon-lock:before {
  content: "\e08a";
}

.icon-loader:before {
  content: "\e08b";
}

.icon-list:before {
  content: "\e08c";
}

.icon-linkedin:before {
  content: "\e08d";
}

.icon-link:before {
  content: "\e08e";
}

.icon-link-2:before {
  content: "\e08f";
}

.icon-life-buoy:before {
  content: "\e090";
}

.icon-layout:before {
  content: "\e091";
}

.icon-layers:before {
  content: "\e092";
}

.icon-key:before {
  content: "\e093";
}

.icon-italic:before {
  content: "\e094";
}

.icon-instagram:before {
  content: "\e095";
}

.icon-info:before {
  content: "\e096";
}

.icon-inbox:before {
  content: "\e097";
}

.icon-image:before {
  content: "\e098";
}

.icon-home:before {
  content: "\e099";
}

.icon-hexagon:before {
  content: "\e09a";
}

.icon-help-circle:before {
  content: "\e09b";
}

.icon-heart:before {
  content: "\e09c";
}

.icon-headphones:before {
  content: "\e09d";
}

.icon-hash:before {
  content: "\e09e";
}

.icon-hard-drive:before {
  content: "\e09f";
}

.icon-grid:before {
  content: "\e0a0";
}

.icon-globe:before {
  content: "\e0a1";
}

.icon-gitlab:before {
  content: "\e0a2";
}

.icon-github:before {
  content: "\e0a3";
}

.icon-git-pull-request:before {
  content: "\e0a4";
}

.icon-git-merge:before {
  content: "\e0a5";
}

.icon-git-commit:before {
  content: "\e0a6";
}

.icon-git-branch:before {
  content: "\e0a7";
}

.icon-gift:before {
  content: "\e0a8";
}

.icon-frown:before {
  content: "\e0a9";
}

.icon-framer:before {
  content: "\e0aa";
}

.icon-folder:before {
  content: "\e0ab";
}

.icon-folder-plus:before {
  content: "\e0ac";
}

.icon-folder-minus:before {
  content: "\e0ad";
}

.icon-flag:before {
  content: "\e0ae";
}

.icon-filter:before {
  content: "\e0af";
}

.icon-film:before {
  content: "\e0b0";
}

.icon-file:before {
  content: "\e0b1";
}

.icon-file-text:before {
  content: "\e0b2";
}

.icon-file-plus:before {
  content: "\e0b3";
}

.icon-file-minus:before {
  content: "\e0b4";
}

.icon-figma:before {
  content: "\e0b5";
}

.icon-feather:before {
  content: "\e0b6";
}

.icon-fast-forward:before {
  content: "\e0b7";
}

.icon-facebook:before {
  content: "\e0b8";
}

.icon-eye:before {
  content: "\e0b9";
}

.icon-eye-off:before {
  content: "\e0ba";
}

.icon-external-link:before {
  content: "\e0bb";
}

.icon-edit:before {
  content: "\e0bc";
}

.icon-edit-3:before {
  content: "\e0bd";
}

.icon-edit-2:before {
  content: "\e0be";
}

.icon-droplet:before {
  content: "\e0bf";
}

.icon-dribbble:before {
  content: "\e0c0";
}

.icon-download:before {
  content: "\e0c1";
}

.icon-download-cloud:before {
  content: "\e0c2";
}

.icon-dollar-sign:before {
  content: "\e0c3";
}

.icon-divide:before {
  content: "\e0c4";
}

.icon-divide-square:before {
  content: "\e0c5";
}

.icon-divide-circle:before {
  content: "\e0c6";
}

.icon-disc:before {
  content: "\e0c7";
}

.icon-delete:before {
  content: "\e0c8";
}

.icon-database:before {
  content: "\e0c9";
}

.icon-crosshair:before {
  content: "\e0ca";
}

.icon-crop:before {
  content: "\e0cb";
}

.icon-credit-card:before {
  content: "\e0cc";
}

.icon-cpu:before {
  content: "\e0cd";
}

.icon-corner-up-right:before {
  content: "\e0ce";
}

.icon-corner-up-left:before {
  content: "\e0cf";
}

.icon-corner-right-up:before {
  content: "\e0d0";
}

.icon-corner-right-down:before {
  content: "\e0d1";
}

.icon-corner-left-up:before {
  content: "\e0d2";
}

.icon-corner-left-down:before {
  content: "\e0d3";
}

.icon-corner-down-right:before {
  content: "\e0d4";
}

.icon-corner-down-left:before {
  content: "\e0d5";
}

.icon-copy:before {
  content: "\e0d6";
}

.icon-compass:before {
  content: "\e0d7";
}

.icon-command:before {
  content: "\e0d8";
}

.icon-columns:before {
  content: "\e0d9";
}

.icon-coffee:before {
  content: "\e0da";
}

.icon-codesandbox:before {
  content: "\e0db";
}

.icon-codepen:before {
  content: "\e0dc";
}

.icon-code:before {
  content: "\e0dd";
}

.icon-cloud:before {
  content: "\e0de";
}

.icon-cloud-snow:before {
  content: "\e0df";
}

.icon-cloud-rain:before {
  content: "\e0e0";
}

.icon-cloud-off:before {
  content: "\e0e1";
}

.icon-cloud-lightning:before {
  content: "\e0e2";
}

.icon-cloud-drizzle:before {
  content: "\e0e3";
}

.icon-clock:before {
  content: "\e0e4";
}

.icon-clipboard:before {
  content: "\e0e5";
}

.icon-circle:before {
  content: "\e0e6";
}

.icon-chrome:before {
  content: "\e0e7";
}

.icon-chevrons-up:before {
  content: "\e0e8";
}

.icon-chevrons-right:before {
  content: "\e0e9";
}

.icon-chevrons-left:before {
  content: "\e0ea";
}

.icon-chevrons-down:before {
  content: "\e0eb";
}

.icon-chevron-up:before {
  content: "\e0ec";
}

.icon-chevron-right:before {
  content: "\e0ed";
}

.icon-chevron-left:before {
  content: "\e0ee";
}

.icon-chevron-down:before {
  content: "\e0ef";
}

.icon-check:before {
  content: "\e0f0";
}

.icon-check-square:before {
  content: "\e0f1";
}

.icon-check-circle:before {
  content: "\e0f2";
}

.icon-cast:before {
  content: "\e0f3";
}

.icon-camera:before {
  content: "\e0f4";
}

.icon-camera-off:before {
  content: "\e0f5";
}

.icon-calendar:before {
  content: "\e0f6";
}

.icon-briefcase:before {
  content: "\e0f7";
}

.icon-box:before {
  content: "\e0f8";
}

.icon-bookmark:before {
  content: "\e0f9";
}

.icon-book:before {
  content: "\e0fa";
}

.icon-book-open:before {
  content: "\e0fb";
}

.icon-bold:before {
  content: "\e0fc";
}

.icon-bluetooth:before {
  content: "\e0fd";
}

.icon-bell:before {
  content: "\e0fe";
}

.icon-bell-off:before {
  content: "\e0ff";
}

.icon-battery:before {
  content: "\e100";
}

.icon-battery-charging:before {
  content: "\e101";
}

.icon-bar-chart:before {
  content: "\e102";
}

.icon-bar-chart-2:before {
  content: "\e103";
}

.icon-award:before {
  content: "\e104";
}

.icon-at-sign:before {
  content: "\e105";
}

.icon-arrow-up:before {
  content: "\e106";
}

.icon-arrow-up-right:before {
  content: "\e107";
}

.icon-arrow-up-left:before {
  content: "\e108";
}

.icon-arrow-up-circle:before {
  content: "\e109";
}

.icon-arrow-right:before {
  content: "\e10a";
}

.icon-arrow-right-circle:before {
  content: "\e10b";
}

.icon-arrow-left:before {
  content: "\e10c";
}

.icon-arrow-left-circle:before {
  content: "\e10d";
}

.icon-arrow-down:before {
  content: "\e10e";
}

.icon-arrow-down-right:before {
  content: "\e10f";
}

.icon-arrow-down-left:before {
  content: "\e110";
}

.icon-arrow-down-circle:before {
  content: "\e111";
}

.icon-archive:before {
  content: "\e112";
}

.icon-aperture:before {
  content: "\e113";
}

.icon-anchor:before {
  content: "\e114";
}

.icon-align-right:before {
  content: "\e115";
}

.icon-align-left:before {
  content: "\e116";
}

.icon-align-justify:before {
  content: "\e117";
}

.icon-align-center:before {
  content: "\e118";
}

.icon-alert-triangle:before {
  content: "\e119";
}

.icon-alert-octagon:before {
  content: "\e11a";
}

.icon-alert-circle:before {
  content: "\e11b";
}

.icon-airplay:before {
  content: "\e11c";
}

.icon-activity:before {
  content: "\e11d";
}
