@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.gd-carousel {
  position: unset !important;
}

.carouselContainer {
  height: 100% !important;
  position: relative !important;
}

.carouselItemMulti {
  padding-right: 20px !important;
  transition: background-color 0.3s ease-in-out !important;
}

.carouselItemMulti:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.expertItem {
  border-radius: 24px !important;
  overflow: hidden;
  position: relative;
  border: 1px solid $color-putty-dark;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  background-color: $white;
  padding: 20px;
  height: 200px;
  cursor: pointer;

  .expertItemContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .expertImg {
      width: 100px;
      height: 100px;
      border-radius: 100%;
    }

    .expertTextContainer {
      display: flex;
      flex-direction: column;

      .expertTitle {
        @include subHeading3;
      }

      .expertDescription {
        @include bodyCopy;
        font-weight: 300;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        max-height: calc(1.5em * 5);
      }

      .roleContainer {
        background-color: #82828235;
        border-radius: 30px;
        padding: 2px;
        width: 50%;
        text-align: center;
        @include bodyCopy;
        color: $text-dark-grey;
        font-size: 14px;
      }
    }
  }
  .arrowButton {
    position: relative;
    z-index: 4;
  }

  .arrowButton {
    display: block;
    width: 67px;
    height: 52px;
    opacity: 1;
    transform: translateY(0px);
    position: absolute !important;
    right: 0;
    bottom: 0;
  }

  .arrowButton svg {
    width: 77px;
    height: 62px;
  }

  .arrowButton.active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.4s, transform 1.4s, border-color 0.3s, background-color 0.3s;
  }

  .arrowButton:hover {
    opacity: 0.8;
  }

  :nth-child(6n + 1) .blogArrowBg {
    fill: #019df2 !important;
  }
}
