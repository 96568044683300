.hsOnboardingLayout {

  .hsOnboardingLayoutContent {
    width: 1440px;
    max-width: 100%;
    height: 100%;
    margin: 46px auto 0;

    @media screen and (max-width: 768px) {
      margin: 20px auto 0;
    }
  }
}
