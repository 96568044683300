@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.actionsCard {
  background-color: $white;
  border-radius: 20px;
  padding: 22px;
  padding-bottom: 50px;
  margin-top: 30px;

  .topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .leftSection {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;

      .cardTitle {
        color: $text-dark-grey;
        font-weight: 600;
        margin-top: 10px;
      }

      .helpIcon {
        width: 25px;
        cursor: pointer;
      }
    }

    .rightSection {
      margin-top: 10px;
    }
  }

  .tabSection {
    background-color: $color-putty-light;
    padding: 5px 20px 20px 20px;
    border-radius: 12px;
    margin-top: 20px;

    .tabsContainer {
      position: relative;
      z-index: 1;
      margin-top: -20px;

      @include gammaSize {
        display: none;
      }
    }

    .tabContentContainer {
      background-color: $white;
      border-radius: 0 30px 30px 30px;
      padding-top: 30px;
      position: relative;
      z-index: 2;

      @include gammaSize {
        border-radius: 0 0 30px 30px;
      }
    }

    .actionTitle {
      color: $text-dark-grey;
      font-weight: 600;
    }
  }
}

.noActionsFound {
  padding: 40px;
  text-align: center;
  opacity: 0.8;
  font-size: 18px;
}

.hsSubNavigation {
  display: none;

  @include gammaSize {
    display: block;

    ul {
      list-style-type: none;
      flex-direction: column;
      padding: 37px 30px;
      background: $color-sky-blue-dark;
      position: absolute;
      width: 95%;
      z-index: 12;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 15px;

      .show {
        opacity: 0;
      }

      li {
        width: 100%;
        color: $white;
        border-bottom: none;
        cursor: pointer;
        transition: all 0.5s;

        a {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          font-family: 'Titillium Web', sans-serif;
        }

        a {
          padding: 14px 0;
        }

        &.active {
          background: transparent;
          border-bottom: none;

          a {
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: $color-sun-yellow-medium;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.loadingSection {
  margin-top: 20px !important;
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .blueTrayImg {
    height: 94px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}
