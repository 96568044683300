.modalBody {
  min-height: 400px;
}

.statusRoot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading,
.error {
  margin-top: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999998;
  overflow: hidden;
}

.modalRoot {
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 60%;
  max-height: 95%;
  max-width: 100%;
  padding: 30px 36px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px 0px #00000040;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    right: 0px !important;
    left: 0px !important;
    top: 0 !important;
    width: 100%;
    max-height: 100%;
    transform: none;
  }

  .modalHeader {
    display: flex;
    flex-direction: column;

    .btnClose {
      width: 100%;
      display: inline-flex;
      justify-content: end;
      align-items: center;
      cursor: pointer;
      border: none;
      padding: 0;
      background-color: transparent;
      margin-top: -30px;
    }

    .btnCloseIcon {
      font-size: 25px;
    }
  }

  .footerContainer {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .hrLine {
    margin-top: 40px;
  }
}
