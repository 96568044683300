@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.root {
  background-color: #0072b0;
  padding: 23px 30px;
  border-radius: 30px;

  .sliceAndDiceSection {
    .formTextInputStyles {
      label {
        color: white;
        margin-bottom: 10px;
      }

      [class$='-control'] {
        width: 100%;
        border: 3px solid $color-sky-blue-medium;
        border-radius: 55px;
        height: 40px;
        padding: 7.5px 15px;
        align-content: center;
        font-weight: 600;
        font-family: 'Titillium Web', sans-serif;

        &:focus {
          border: 2px solid $color-sky-blue-light;
        }

        &:focus-visible {
          outline: 0;
        }

        &:hover {
          border: 3px solid $color-sky-blue-light;
        }

        &:active {
          border: 3px solid $color-sky-blue-light;
          box-shadow: none;
        }
      }

      [class$='-ValueContainer'] {
        padding: 0px;
      }
      [class$='-menu'] {
        color: $color-sky-blue-light;
      }
      [class$='-indicators'] {
        color: $white;
      }
    }

    .sliceAndDiceFlex {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 30px;

      > div {
        flex: 1;
        min-width: calc(15% - 20px);
        max-width: calc(15% - 20px);

        @include mediaBetween(1520px, 900px) {
          min-width: calc(20% - 20px);
          max-width: calc(20% - 20px);
        }

        @include gammaSize {
          min-width: calc(30% - 5px);
          max-width: calc(30% - 5px);
        }

        @include alphaSize {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.buttonGroup {
  display: flex;
  gap: 15px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.customDatePicker {
  margin-top: 100px;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;

  p {
    color: $white;
    margin-bottom: -5px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;

    @include gammaSize {
      margin-bottom: -25px;
    }
  }
}
