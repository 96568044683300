@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

$actionCardColors: (
  skyBlue: $color-sky-blue-medium,
  calmLilac: $color-calm-lilac-medium,
  sunYellow: $color-sun-yellow-medium,
  zingyGreen: $color-zingy-green-medium,
  aquaGreen: $color-aqua-green-medium,
  warmOrange: $color-warm-orange-medium,
  torquoise: $color-turquoise-medium,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

.improveContainer {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
  height: 500px;

  @include gammaSize {
    height: auto;
  }

  .topSection {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include gammaSize {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .plans {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 10px;

    @include gammaSize {
      margin-right: auto;
      margin-left: 0;
      flex-wrap: wrap;
    }

    .planItem {
      padding: 10px;
      border-radius: 24px;
      font-size: 14px;
      &.sunYellow {
        background-color: $color-sun-yellow-medium;
      }

      &.aquaGreen {
        background-color: $color-aqua-green-medium;
      }

      &.calmLilac {
        background-color: $color-calm-lilac-medium;
      }

      &.warmOrange {
        background-color: $color-warm-orange-medium;
      }

      &.zingyGreen {
        background-color: $color-zingy-green-medium;
      }

      &.skyBlue {
        background-color: $color-sky-blue-medium;
      }
    }
  }

  .progressSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    .progressBar {
      width: 100%;
      margin-top: 5px;
    }

    span {
      font-weight: 600;
      color: $text-dark-grey;
    }
  }

  .alreadyCompletedText {
    font-size: 18px;
    margin-top: 20px;
  }

  .plansContent {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .planItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      padding: 5px 10px;
      border-radius: 5px;

      .improvePlanQuestionName {
        margin-right: 10px;
        color: $color-sky-blue-light;
        font-weight: 600;
        text-align: left !important;
        margin-left: 10px;
        cursor: pointer;
      }

      .overdueSection {
        min-width: 130px !important;
        max-width: 130px;
        text-align: center;
        border-radius: 4px;
        color: $white;
        font-size: 15px;
        font-weight: 400;
        align-self: flex-end;
        justify-content: flex-end;
        margin-bottom: 10px !important;

        @include gammaSize {
          align-self: flex-start;
          margin-left: 10px;
          margin-top: 10px;
        }

        &.overdue {
          background-color: $color-putty-dark;
          color: $text-dark-grey;
        }

        &.ontime {
          background-color: $go-green;
        }
      }

      .checkBoxContainer {
        padding: 5px;
        border-radius: 100px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        @each $color, $bg in $actionCardColors {
          &.#{$color} {
            background: $bg;
          }
        }

        .checkbox {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #000;
          border-radius: 100px;
          background-color: transparent;
          position: relative;
          outline: none;
          cursor: pointer;
        }

        .checkbox:checked::before {
          content: '\2713';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
        }
      }
    }
  }
}

.planWithOverdue {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @include gammaSize {
    flex-direction: column;
    justify-content: left;
  }
}

.viewAllBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: $color-sky-blue-medium;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
}

.loadPlan {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;

  .plan {
    border-radius: 24px !important;
    height: 40px !important;
    width: 100px !important;
  }
}

.loadSpan {
  margin-top: 5px !important;
}

.loadPlanItems {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

.completedPlansSection {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;

    .texts {
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        text-align: center;
        color: $text-dark-grey;
        font-size: 18px;
        font-weight: 600;
        margin-top: -20px;
      }

      span {
        text-align: center;
        color: $text-dark-grey;
        margin-top: -10px;
      }
    }

    .button {
      margin-top: 20px;
      text-align: center;
    }

    $duration: 2000;

    @function randomNum($min, $max) {
      $rand: random();
      $randomNum: $min + floor($rand * (($max - $min) + 1));

      @return $randomNum;
    }

    .confetti {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 50%;
      height: 200px;
      overflow: hidden;
      margin-top: -40px;
    }

    .confettiPiece {
      position: absolute;
      width: 8px;
      height: 16px;
      background: $color-sun-yellow-medium;
      top: 0;
      opacity: 0;

      @for $i from 1 through 13 {
        &:nth-child(#{$i}) {
          left: $i * 7%;
          transform: rotate(#{randomNum(-80, 80)}deg);
          animation: makeItRain $duration * 1ms infinite ease-out;
          animation-delay: #{randomNum(0, $duration * 0.5)}ms;
          animation-duration: #{randomNum($duration * 0.7, $duration * 1.2)}ms;
        }
      }

      &:nth-child(odd) {
        background: $color-sky-blue-medium;
      }

      &:nth-child(even) {
        z-index: 1;
      }

      &:nth-child(4n) {
        width: 5px;
        height: 12px;
        animation-duration: $duration * 2ms;
      }

      &:nth-child(3n) {
        width: 3px;
        height: 10px;
        animation-duration: $duration * 2.5ms;
        animation-delay: $duration * 1ms;
      }

      &:nth-child(4n-7) {
        background: $color-calm-lilac-medium;
        height: 32px;
      }

      &:nth-child(5n) {
        background: $color-zingy-green-medium;
      }

      &:nth-child(6n) {
        background: $color-aqua-green-medium;
      }

      &:nth-child(7n) {
        background: $color-warm-orange-medium;
      }
    }

    @keyframes makeItRain {
      from {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      to {
        transform: translateY(200px);
      }
    }
  }
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  height: 100%;
}

.urlNotEnabled {
  color: $text-dark-grey !important;
  cursor: default !important;
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color-go-green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  background-color: $color-go-green;
}

.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px $color-go-green;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  margin-top: 15px;
}
.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    background-color: $color-go-green;
  }
}

.checkContainer {
  margin-top: 20px;
}

.roundedCheckbox {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: $color-putty-dark;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-top: -4px;
}

.roundedCheckbox:before {
  content: '\2713';
  font-size: 16px;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roundedCheckbox:checked {
  clip-path: circle(50% at 50% 50%);
  background-color: $color-sky-blue-medium;
}

.roundedCheckbox:checked:before {
  color: $white;
}
