@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsStepWizardHeader {
  &TitleImage {
    position: absolute;
    top: 60px;
    left: 0;
  }
}

.button {
  display: flex;
  width: 100%;
  border-radius: 24px;
  padding: 9px 84px;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #019df2;
  margin-left: 16px;
}

.hsStepWizardHeader {
  .prevButtonContainer {
    margin-left: 10px;
    margin-right: 10px;

    @include betaSize {
      margin-top: 12px;
    }
  }
  .tooltipContainer {
    position: absolute;
    right: 0;
    padding: 10px;
    top: 50px;
    font-size: 14px;
    z-index: 99;
    background-color: rgb(80, 80, 80);
    color: white;
    border-radius: 10px;
  }

  .beginContainer {
    display: flex;
    align-items: center;
    background: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 10px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    z-index: 99;
  }

  .circleIconContainer {
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid #019df2;
  }

  .absoluteTopRightContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
    top: 0;
    align-items: flex-start;
  }

  .absoluteTopSpaceBetweenContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    top: 20px;
  }
}
