@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.card {
  border-radius: 20px;
  border: 2px solid $color-calm-lilac-medium;
  padding: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.cardActionImageCont {
  width: 150px;
  max-width: 150px;
  background-color: $color-putty-light;
  margin: -20px !important;
  background-position: center;
  background-size: contain;
  object-fit: fill;
  z-index: -1;
  flex-basis: 20%;

  @include gammaSize {
    display: none;
  }
}

.cardContentDesktop {
  flex-basis: 100%;
  @include gammaSize {
    display: none;
  }
}

.cardHeaderSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .cardTitle {
    @include subHeading2;
    font-size: 18px;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-weight: 200;
    cursor: pointer;
    margin-top: -20px;
    i {
      margin-top: 2px;
      font-weight: 200;
    }

    &:hover {
      font-weight: 500;
    }
  }
}

.cardMiddleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  .description {
    @include bodyCopy;
    flex-basis: 65%;
  }

  .btnContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-basis: 35%;
    justify-content: flex-end;
  }
}

.tooltipCont {
  cursor: pointer;
  max-width: 160px !important;

  &.disabled {
    cursor: not-allowed;
  }
}

.cardFooterSection {
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 25px;

  .dueSectionSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2px;
    background-color: $color-go-green;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    max-width: 120px;
    min-width: 120px;
  }

  .dueSectionError {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2px;
    background-color: $color-putty-dark;
    border-radius: 4px;
    color: $text-dark-grey;
    font-size: 14px;
    max-width: 120px;
    min-width: 120px;
  }

  .setDueDate {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2px;
    background-color: $color-sky-blue-medium;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    max-width: 120px;
    min-width: 120px;
  }

  .calendarBtn {
    background-color: $color-sky-blue-medium;
    padding: 3px;
    width: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .calendar {
      width: 15px;
    }
  }

  .calendarBtn:hover {
    background-color: $color-sky-blue-light;
  }
}

.hsPrimaryBtn {
  background: $color-sky-blue-medium;
  color: $white;
  padding: 5px 15px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;
  height: 36px;
  min-width: 100px;
  text-align: center !important;
  &:hover {
    background: $color-sky-blue-light;
    border: 2px solid $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }

  &.fullWidth {
    width: 100%;
  }

  &.discardFullWidth {
    width: auto !important;
    text-align: center !important;

    @include gammaSize {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include gammaSize {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  span {
    @include gammaSize {
      text-align: right !important;
      flex-basis: 50%;
      margin-left: 20px;
    }
  }

  .hsStartIcon {
    @include gammaSize {
      display: flex;
      justify-content: flex-end !important;
      margin-right: -5px !important;
    }
  }
}

.hsSecondaryBtn {
  background: $white;
  border: 3px solid $color-sky-blue-medium;
  color: $color-sky-blue-medium;
  display: inline-flex;
  background: transparent;
  justify-content: center;
  border-radius: 24px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  height: 36px;
  min-width: 100px;

  &:hover {
    border-color: $color-sky-blue-light;
    color: $color-sky-blue-light;
  }

  &:disabled {
    border-color: $text-deactivated;
    color: $text-deactivated;
    opacity: 0.3;
  }
}

.hsStartIcon {
  width: 20px;
  margin-left: 5px;
}

.hsCalendarIcon {
  width: 15px;
  margin-left: 5px;
  margin-top: 3px;
}

.reccomendationSection {
  margin-top: 25px;

  .topContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .navigationButtons {
      display: flex;
      flex-direction: row;
      .navigationBtn {
        background-color: transparent;
        border: none;
        padding: 5px;

        &:disabled {
          opacity: 0.3;
        }

        .navigationIcon {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .reccomendedSectionTitle {
    @include subHeading2;
    font-size: 18px;
  }
}

.carouselItemsContainer {
  display: flex;
  transition: transform 0.3s ease;
}

.carouselItem {
  border-radius: 24px !important;
  overflow: hidden;
  position: relative;
  border: 1px solid $color-putty-dark;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
  background-color: $white;
  padding: 20px;
  height: 200px;

  .carouselItemContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .carouselImg {
      width: 100px;
      height: 100px;
      border-radius: 100%;
    }

    .carouselTextContainer {
      display: flex;
      flex-direction: column;
      .carouseTitle {
        @include subHeading3;
      }

      .carouseDescription {
        @include bodyCopy;
        font-weight: 300;
        font-size: 14px;
      }

      .roleContainer {
        background-color: #82828235;
        border-radius: 30px;
        padding: 2px;
        width: 50%;
        text-align: center;
        @include bodyCopy;
        color: $text-dark-grey;
        font-size: 14px;
      }
    }
  }
  .arrowButton {
    position: relative;
    z-index: 4;
  }

  .arrowButton {
    display: block;
    width: 67px;
    height: 52px;
    opacity: 1;
    transform: translateY(0px);
    position: absolute !important;
    right: 0;
    bottom: 0;
  }

  .arrowButton svg {
    width: 77px;
    height: 62px;
  }

  .arrowButton.active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.4s, transform 1.4s, border-color 0.3s, background-color 0.3s;
  }

  .arrowButton:hover {
    opacity: 0.8;
  }

  :nth-child(6n + 1) .blogArrowBg {
    fill: #019df2 !important;
  }
}

.carouselItem:hover {
  transform: translateY(-10px);
}

.cardContentMobile {
  display: none;

  @include gammaSize {
    display: block;
    margin-bottom: 10px;
  }

  .cardActionImageMobile {
    width: 100%;
    min-width: 94vw !important;
    height: 150px;
    margin: -20px;
    background-color: $color-putty-light;
    background-position: center center;
    background-size: cover;
    margin-bottom: 10px;
    background-repeat: no-repeat;
  }
}

.mobileCardContentContainer {
  background-color: $white !important;
  margin: 0px -20px -30px -20px;

  .mobileMiddleContainer {
    padding: 20px;
  }
  .mobileCardTitle {
    @include subHeading2;
  }

  .mobileCardDescription {
    @include bodyCopy;
  }

  .mobileButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobileCardFooter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-top: 30px;

    .mobileDueSection {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 70%;

      .dueSectionSuccess {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: $color-go-green;
        padding: 4px;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        max-width: auto;
        min-width: 100px;
        text-align: center;
      }

      .dueSectionError {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: $color-putty-dark;
        padding: 4px;
        border-radius: 4px;
        color: $text-dark-grey;
        font-size: 14px;
        max-width: auto;
        min-width: 100px;
        text-align: center;
      }

      .setDueDate {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 4px;
        background-color: $color-sky-blue-medium;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        max-width: auto;
        min-width: 100px;
        text-align: center;
      }

      .calendarBtn {
        background-color: $color-sky-blue-medium;
        padding: 3px;
        width: 40px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .calendar {
          width: 15px;
        }
      }
    }
  }
}
.mobileContinue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    margin-left: auto;
  }
}

.mobileContinue > .hsStartIcon {
  margin-left: auto !important;
}

.datePickerModal {
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    position: fixed;
  }
}

.removeModal {
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 535px;
  max-width: 100%;
  padding: 30px 36px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;

  @media screen and (max-width: 768px) {
    width: 90%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}

.icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 10px;
}

.close {
  margin-right: 11px;
}

.hsSecondaryBtn {
  background: $white;
  color: $color-sky-blue-medium;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;

  &:hover {
    border: 2px solid $color-sky-blue-light;
    color: $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }
}

.removeModalContent {
  .btnContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
  }
}

.noOptions {
  cursor: none !important;
}

.removeMobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;

  .removeIcon {
    cursor: default;
    font-size: 18px;
    margin-top: 2px;
  }
}
