@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.overlay {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999998;
  overflow: hidden;
}

.helpRoot {
  position: fixed;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  min-width: 90%;
  max-width: 90%;
  min-height: 95%;
  max-height: 95%;
  padding-left: 10px;
  padding-top: 30px;
  padding-right: 10px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.547);
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include mediaBetween(2399px, 1500px) {
    min-width: 70%;
    max-width: 70%;
    min-height: 90%;
    max-height: 90%;
  }

  @include mediaBetween(3199px, 2400px) {
    min-width: 70%;
    max-width: 70%;
    min-height: 70%;
    max-height: 70%;
  }

  @include mediaBetween(4200px, 3200px) {
    min-width: 70%;
    max-width: 70%;
    min-height: 60%;
    max-height: 60%;
  }

  @include gammaSize {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }
  }

  .helpAndSpeakUpModalBody {
    padding: 10px;
  }

  .skipButton {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .btnCloseIcon {
    font-size: 25px;
  }
}

.modalRoot {
  position: fixed;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  min-width: 35%;
  max-width: 35%;
  min-height: 95%;
  max-height: 95%;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.547);
  box-sizing: border-box;

  @include alphaSize {
    background-image: none;
  }

  @include mediaBetween(2399px, 1500px) {
    min-width: 30%;
    max-width: 30%;
    min-height: 90%;
    max-height: 90%;
  }

  @include mediaBetween(3199px, 2400px) {
    min-width: 25%;
    max-width: 25%;
    min-height: 70%;
    max-height: 70%;
  }

  @include mediaBetween(4200px, 3200px) {
    min-width: 20%;
    max-width: 20%;
    min-height: 60%;
    max-height: 60%;
  }

  @include gammaSize {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .btnClose {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
    button {
      border: none;
      padding: 0;
      display: inline-flex;
      background-color: transparent;
    }
    :hover {
      font-weight: 600;
    }

    @include gammaSize {
      margin-bottom: 20px;
    }
  }

  .btnCloseIcon {
    font-size: 25px;
  }
}

.modalRoot::-webkit-scrollbar {
  width: 0;
}

.feelsImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -30px;

  .feelsImage {
    width: 50px;
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: wave 2s infinite;
  }
}

.mt20 {
  margin-top: 5px;
}

.cursorEnabled {
  cursor: pointer;
}

.cursorDisable {
  cursor: not-allowed !important;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shortStarContainer {
  margin-bottom: -10px !important;
}

.footerImageV1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 150px;
  background-image: url('../../../assets/images/modal-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
  margin-left: -30px !important;
  z-index: -1;
  vertical-align: bottom;

  &.footerImageV1Welcome {
    bottom: auto;
    scroll-padding-bottom: revert;
  }

  @include alphaSize {
    margin-left: -50px !important;
  }
}

.footerImageV2 {
  position: fixed;
  bottom: auto;
  width: 100%;
  height: 150px;
  background-image: url('../../../assets/images/modal-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
  margin-left: -30px !important;
  z-index: -1;

  @include gammaSize {
    bottom: 0;
  }

  @include alphaSize {
    display: none;
    bottom: auto !important;
    margin-left: -50px !important;
  }

  @include mediaBetween(4200px, 1800px) {
    bottom: 0 !important;
  }
}

.loadingCont {
  margin-top: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .loadingText {
    width: 80% !important;
    height: 40px !important;
  }

  .loadingFaces {
    display: flex !important;
    flex-direction: row !important;
    gap: 30px !important;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 50px !important;

    .loadingFace {
      width: 60px !important;
      height: 60px !important;

      @include alphaSize {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

.starForceSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: -100px;

  .hsStarImage {
    width: 100px;
  }
}
