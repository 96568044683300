@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

@function hexToRgba($color, $alpha) {
  @if (type-of($color) == 'color') {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
    @return rgba($r, $g, $b, $alpha);
  }
  @return null;
}

.container {
  margin-top: 40px;

  .title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    align-content: center;
    cursor: pointer;
    width: fit-content;
    transition: transform 0.3s ease-in-out;
    animation: fadeIn 0.5s ease-out;
    margin-bottom: 16px;

    h5 {
      color: $color-sky-blue-medium;
      font-weight: 600;

      &:hover {
        color: $color-sky-blue-light;
      }
    }

    .next {
      width: 20px;
      margin-top: -5px;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.carouselContainer {
  height: 100%;
}

.learningItem {
  width: auto;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
  background-color: $white;
  border-radius: 20px;
  margin-bottom: 10px;
  border: 1px solid $color-putty-medium;

  .learningItemInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;

    .learningItemInnerImage {
      height: 200px;
      position: relative;

      .image {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .learningItemInnerContent {
      height: 75px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.learningItem:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.next {
  width: 20px;
  height: 20px;
}

.gd-carousel {
  position: unset;
}

.carouselItem {
  padding-right: 40px;
  transition: background-color 0.3s ease-in-out;
}

.carouselItem:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
