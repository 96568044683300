.whatIsHapstar {
    width: 50%;
    margin: 70px;
}

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.rightPosition {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.marginLeft2 {
    width: 50%;
    margin-left: 70px
}

.flexRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.marginLeft {
    width: 50%;
    margin-left: 200px
}

.marginTop {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 100px
}

.marginHorizontal {
    width: 50%;
    margin: 0 70px;
}

.flexEnd {
    width: 100%;
    display: flex;
    justify-content: end;
}

.center {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}


@media screen and (max-width: 768px) {
    .whatIsHapstar {
        width: 70%;
        margin: 0 30px;
    }
    
    .container {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .rightPosition {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        height: 300px;
    }
    
    .marginLeft2 {
        width: 70%;
        margin: 0 30px;
        margin-left: 0;
    }
    
    .flexRow {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 30px;
    }
    
    .marginLeft {
        width: 100%;
        margin-left: 0;
    }
    
    .marginTop {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 100px
    }
    
    .marginHorizontal {
        width: 70%;
        margin: 0 30px;
    }
    
    .flexEnd {
        width: 100%;
        display: flex;
        justify-content: end;
    }
    
    .center {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .img {
        height: 100px
    }
  }