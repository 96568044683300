@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.noDataContainer {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
  h3 {
    @include heading2;
    text-align: center;
  }
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 40px !important;
  margin-top: 30px !important;
  margin-bottom: 100px !important;

  .loadingDesc {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    margin-top: -20px;
  }
}
