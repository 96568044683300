@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  padding-top: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseLabel {
  margin-right: 5px;
  color: $black;

  &:hover {
    font-weight: 600;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $black;
}

.modalRoot {
  z-index: 999999 !important;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60%;
  max-width: 100%;
  min-height: 50%;
  max-height: 95%;
  overflow-y: auto;
  background-color: $white;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 20px;
    min-width: 90%;
    overflow-x: hidden;
    max-height: 100%;
  }

  .modalBody {
    .modalTitle {
      @include bodyCopyLarge;
      margin-top: -25px;
      font-weight: 500;
      font-size: 24px;
    }
    .btnArea {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
