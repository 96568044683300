@import '../../../assets/styles/variables';

$colors: (
  skyBlue: $color-sky-blue-dark,
  calmLilac: $color-calm-lilac-dark,
  sunYellow: $color-sun-yellow-dark,
  zingyGreen: $color-zingy-green-dark,
  aquaGreen: $color-aqua-green-dark,
  warmOrange: $color-warm-orange-dark,
  torquoise: $color-turquoise-dark,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-dark,
  white: $white,
);

.chip {
  height: 25px !important;
  color: $white !important;
  font-weight: 400 !important;
  @each $color, $itemColor in $colors {
    &.#{$color} {
      background-color: $itemColor;
    }
  }
}
