@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.hsCreateAction {
  &Label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      color: $text-dark-grey;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  textarea {
    width: 100%;
    border: 2px solid $color-putty-dark;
    border-radius: 10px;
    resize: none;
    height: 100px;
    margin-top: 20px;
    padding: 13px 15px;
  }

  p.hsCharCounter {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: $text-dark-grey;
    margin: -4px 6px 0 0;
  }

  &Suggestions {
    margin-bottom: 70px;

    &Title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $text-dark-grey;
    }
  }

  .hsSuggestion {
    padding: 9px 24px;
    background: #ffffff;
    border: 3px solid transparent;
    border-radius: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;

    + {
      .hsSuggestion {
        margin-top: 10px;
      }
    }
  }

  .hsButtonContainer {
    margin-top: 100px;
    text-align: right;
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }

    .hsSuggestion {
      border-color: $color-calm-lilac-medium;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-sky-blue-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-sky-blue-medium;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-sun-yellow-medium;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-zingy-green-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-zingy-green-medium;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-aqua-green-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-aqua-green-medium;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsCreateActionLabel {
      span {
        border-color: $color-warm-orange-medium;
      }
    }
    .hsSuggestion {
      border-color: $color-warm-orange-medium;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999998;
  overflow: hidden;
}

.modalRoot {
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 99999999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  min-width: 30%;
  max-width: 30%;
  padding: 30px 36px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.4rem;
  box-shadow: 1px 1px 4px 0px #00000040;

  @include gammaSize {
    max-width: 100%;
    right: 0px !important;
    left: 0px !important;
    top: 0 !important;
    width: 100%;
    max-height: 100%;
    transform: none;
  }

  .modalHeader {
    display: flex;
    flex-direction: column;

    .btnClose {
      width: 100%;
      display: inline-flex;
      justify-content: end;
      align-items: center;
      cursor: pointer;
      border: none;
      padding: 0;
      background-color: transparent;
      margin-top: -30px;
    }

    .btnCloseIcon {
      font-size: 25px;
    }
  }

  .footerContainer {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
  }

  .hrLine {
    margin-top: 20px;
  }

  .warningBox {
    background-color: #fff3cd;
    border: 1px solid #ffe69c;
    border-radius: 0.375rem;
    padding: 15px;
    margin-bottom: 15px;
  }
}
