@import '../../../assets/styles/mixns';

.hsAreaChart {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  max-width: 100% !important;
  min-width: 100%;
}

.arrowContLeft {
  height: 40px;
  margin-bottom: 25px;
  cursor: pointer;
}

.arrowContRight {
  height: 40px;
  margin-bottom: 25px;
  cursor: pointer;
  rotate: 180deg;
}

.eChart {
  min-width: 95%;
  max-width: 100%;
  height: 550px !important;

  @include betaSize {
    min-width: 95%;
    max-width: 100%;
    height: 450px !important;
  }
}
