@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.radarBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.topTextSection {
  width: 98%;

  @include alphaSize {
    width: 100%;
  }
  p {
    font-size: 18px;
    color: $text-dark-grey;
  }
}
