@import '../../../assets/styles/variables';

.input {
  width: 100%;
  padding: 13px 15px 14px;
  background: $white;
  border: 2px solid $color-putty-dark;
  border-radius: 10px;
  min-height: 38px;

  &:disabled {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: 0;
  }
}

.error {
  border-color: red;
}
