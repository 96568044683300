@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-top: 20px;
  @include subHeading2;
  font-size: 18px;

  @media only screen and (max-device-width: 667px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 16px !important;
    margin-top: 0px !important;
  }

  @media only screen and (max-device-width: 399px) and (max-device-height: 990px) {
    font-size: 16px !important;
    margin-top: 0px !important;
  }

  @media only screen and (max-device-width: 399px) and (max-device-height: 990px) {
    font-size: 16px !important;
    margin-top: 0px !important;
  }

  @media only screen and (min-device-width: 400px) and (max-device-height: 990px) {
    margin-top: 5px !important;
    font-size: 18px !important;
  }

  @include alphaSize {
    font-size: 16px;
    margin-top: 0px;
  }
}

.sliderContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 10px;
  margin-top: 5px;

  .label {
    font-size: 16px;
    font-weight: 600;
    margin-top: 2px;
    color: $color-sky-blue-medium;
  }

  .sliderWrapper {
    width: 100%;
  }
}
