.tokenContainer {
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  button {
    width: 100%;
  }
}

.resendText {
  margin-top: 10px;
}
