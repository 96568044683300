@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.cardContainer {
  width: 730px;
  margin: 20px auto;

  .contentContainer {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    align-content: center;
    justify-content: center;

    .content {
      width: 100%;

      .buttonHolder {
        margin: 20px 40px;
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }

    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #505050;
      margin-bottom: 40px;
    }
  }
}

.loader {
  border-radius: 40px !important;
  width: 730px !important;
}
