@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsWelcomePageContainer {
  overflow: hidden;
  width: 100%;

  .hsWelcomePageSubContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto 0;

    @include alphaSize {
      width: 100%;
      margin: 20px;
    }

    @include betaSize {
      width: 100%;
      margin: 10px;
    }

    @include mediaBetween(1023px, 763px) {
      margin: 10px;
    }

    .hsWelcomeCardContainer {
      max-width: 860px;
      width: 100%;
      padding: 52px 75px 75px 75px;
      border-radius: 40px;
      position: relative;

      @include alphaSize {
        width: 100%;
        padding: 20px;
        margin: 10px;
      }

      @include betaSize {
        width: 100%;
        padding: 20px;
        margin: 10px;
      }

      &:after {
        content: url('../../assets/images/welcome-shapes-right-bottom.svg');
        position: absolute;
        z-index: -1;
        right: -205px;
        top: 140px;

        @include gammaSize {
          content: none;
        }
      }

      &:before {
        content: url('../../assets/images/welcome-shapes-left-top.svg');
        position: absolute;
        z-index: -1;
        top: -300px;
        left: -292px;

        @include gammaSize {
          content: none;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 40px;
        text-align: center;
        color: $text-dark-grey;
        padding: 0 112px;
        line-height: 48px;
        width: 100%;

        @include alphaSize {
          padding: 0;
          font-size: 30px;
        }

        @include betaSize {
          padding: 0;
          font-size: 30px;
        }

        @include gammaSize {
          padding: 0 60px;
          font-size: 30px;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        line-height: 24px;

        &:first-of-type {
          margin-top: 53px;
        }

        + {
          p {
            margin-top: 24px;
          }
        }
      }

      .terms {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 10px;

        @include betaSize {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        margin: 0 0 50px 0;
      }
    }
  }
}
