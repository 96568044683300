@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

$actionCardColors: (
  skyBlue: $color-sky-blue-medium,
  calmLilac: $color-calm-lilac-medium,
  sunYellow: $color-sun-yellow-medium,
  zingyGreen: $color-zingy-green-medium,
  aquaGreen: $color-aqua-green-medium,
  warmOrange: $color-warm-orange-medium,
  torquoise: $color-turquoise-medium,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-medium,
  white: $white,
);

$darkColors: (
  skyBlue: $color-sky-blue-dark,
  calmLilac: $color-calm-lilac-dark,
  sunYellow: $color-sun-yellow-dark,
  zingyGreen: $color-zingy-green-dark,
  aquaGreen: $color-aqua-green-dark,
  warmOrange: $color-warm-orange-dark,
  torquoise: $color-turquoise-dark,
  red: $error-red,
  goGreen: $go-green,
  putty: $color-putty-dark,
  white: $white,
);

.desktopContainer {
  margin-bottom: 100px;
  @include gammaSize {
    display: none;
  }
}

.mobileContainer {
  display: none;
  @include gammaSize {
    display: block;
    margin-bottom: 50px;
  }
}

.topSectionTitleContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  .improveTitle {
    @include heading3;
    color: $text-dark-grey;
  }
  .secondaryTitle {
    color: $text-dark-grey;
    font-weight: 500;
    margin-top: 10px;
  }
}

.focusAreaContainer {
  .focusAreaCard {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    padding: 30px 30px;
    border-radius: 25px;

    @include gammaSize {
      padding: 30px 20px;
    }

    .focusAreaCardHeader {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .cardTitle {
        @include subHeading3;
        font-size: 18px;
      }

      .editBtn {
        font-size: 16px;
        font-weight: 500;
        color: $color-sky-blue-medium;
        cursor: pointer;

        &:hover {
          color: $color-sky-blue-light;
        }
      }
    }

    .focusAreaCardBody {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;

      .focusAreaCardItem {
        background-color: $color-aqua-green-medium;
        padding: 5px 15px 5px 15px;
        border-radius: 40px;
        cursor: default;

        span {
          font-size: 14px;
          color: $white;
        }

        &:hover {
          background: $color-aqua-green-light;
        }
      }
    }
  }
}

.focusCardContainer {
  .focusCard {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 30px 30px;
    border-radius: 0px 25px 25px 25px;
    position: relative;
    z-index: 2;

    @include gammaSize {
      padding: 30px 20px;
    }

    .focusCardHeader {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .cardTitle {
        @include heading3;
        font-weight: 500;
        font-size: 30px;
        font-weight: 600;
      }

      .cardHeaderButtons {
        display: flex;
        flex-direction: row;
        gap: 10px;

        span {
          font-size: 16px;
          font-weight: 500;
          color: $color-sky-blue-medium;
          cursor: pointer;
          margin-top: 5px;

          &:hover {
            color: $color-sky-blue-light;
          }
        }
      }
    }

    .focusAreaDescription {
      margin-top: 20px;
      color: $text-dark-grey;
      font-weight: 300px;
      font-size: 17px;
      opacity: 0.8;
    }

    .focussingTextContainer {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 20px;
      align-items: center;

      span {
        font-weight: 600;
        color: $text-dark-grey;
      }

      .focusRoundedItem {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 40px;
        padding: 10px;
        color: $white;
        font-size: 14px;
        height: 40px;
        text-align: center;

        @each $color, $bg in $darkColors {
          &.#{$color} {
            background: $bg;
          }
        }
      }
    }
  }

  .progressSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    .progressBar {
      width: 100%;
      margin-top: 5px;
    }

    span {
      font-weight: 600;
      color: $text-dark-grey;
    }
  }

  .actionSection {
    margin-top: 30px;
    margin-bottom: 50px;
    .actionCard {
      border-radius: 20px;
      border: 2px solid $color-calm-lilac-medium;
      padding: 20px 20px 20px 20px;
      margin-bottom: 30px;
      overflow: hidden;

      @each $color, $borderColor in $actionCardColors {
        &.#{$color} {
          border: 2px solid $borderColor;
        }
      }
    }
  }
}

.hsPrimaryBtn {
  background: $color-sky-blue-medium;
  color: $white;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;

  &:hover {
    background: $color-sky-blue-light;
    border: 2px solid $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }

  &.fullWidthMobile {
    @include gammaSize {
      width: 100% !important;
    }
  }
}

.hsSecondaryBtn {
  background: $white;
  color: $color-sky-blue-medium;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  line-height: 22px;
  text-decoration: none;
  border: 2px solid $color-sky-blue-medium;

  &:hover {
    border: 2px solid $color-sky-blue-light;
    color: $color-sky-blue-light;
  }

  &:disabled {
    background: $text-deactivated;
    opacity: 0.3;
    border: 2px solid $text-deactivated;
  }

  &.fullWidthMobile {
    width: 100%;
  }
}

.tabsContainer {
  position: relative;
  z-index: 1;
  margin-top: -20px;

  @include gammaSize {
    display: none;
  }
}

.mobileContainer {
  .mobileTitle {
    @include subHeading2;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .progressSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    .progressBar {
      width: 100%;
      margin-top: 5px;
    }

    span {
      font-weight: 600;
      color: $text-dark-grey;
    }
  }

  .focussingTextContainerMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    span {
      font-weight: 600;
      color: $text-dark-grey;
    }

    .subFocusAreas {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .focuses {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
    }

    .focusRoundedItem {
      border-radius: 40px;
      padding: 10px;
      color: $white;
      font-size: 14px;
      height: 40px;
      text-align: center;
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      @each $color, $bg in $darkColors {
        &.#{$color} {
          background: $bg;
        }
      }
    }
  }

  .focusAreaDescription {
    margin-top: 20px;
    color: $text-dark-grey;
    font-weight: 300px;
    font-size: 17px;
    opacity: 0.8;
  }

  .actionSection {
    margin-top: 30px;
    margin-bottom: 40px;
    .actionCard {
      border-radius: 20px;
      border: 2px solid $color-calm-lilac-medium;
      padding: 20px 20px 20px 20px;
      margin-bottom: 30px;
      overflow: hidden;

      @each $color, $borderColor in $actionCardColors {
        &.#{$color} {
          border: 2px solid $borderColor;
        }
      }
    }
  }
}

.floatingActionButton {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: $color-sky-blue-medium;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  text-align: center;

  span {
    margin-top: -3px;
  }
}

.floatingActionButton:hover {
  background-color: $color-sky-blue-light;
}

@include gammaSize {
  .hsSubNavigation {
    ul {
      list-style-type: none;
      flex-direction: column;
      padding: 37px 30px;
      background: $color-sky-blue-dark;
      position: absolute;
      width: 100%;
      z-index: 12;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 15px;

      .show {
        opacity: 0;
      }

      li {
        width: 100%;
        color: $white;
        border-bottom: none;
        cursor: pointer;
        transition: all 0.5s;

        a {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }

        a {
          padding: 14px 0;
        }

        &.active {
          background: transparent;
          border-bottom: none;

          a {
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: $color-sun-yellow-medium;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.blogPostSection {
  box-shadow: 0px 0px 15px 0px rgba(236, 231, 220, 0.5);
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 10px;
  border: 1px solid rgba(236, 231, 220, 1);
  background-color: $white;

  h5 {
    @include subHeading1;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .blogCardSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.expertSection {
  box-shadow: 0px 0px 15px 0px rgba(236, 231, 220, 0.5);
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 10px;
  border: 1px solid rgba(236, 231, 220, 1);
  margin-top: 40px;
  background-color: $white;

  h5 {
    @include subHeading1;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .blogCardSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .noPostsText {
    margin-top: -10px;
  }
}

.noActionsText {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.skeletonActionCard {
  border-radius: 20px !important;
  height: 150px !important;
  margin-bottom: 20px !important;

  @include gammaSize {
    height: 350px !important;
  }
}

.filterPlansBtn {
  justify-self: flex-end !important;
  margin-left: auto;
  .hsStartIcon {
    width: 20px;
    margin-left: 5px;
  }

  @include gammaSize {
    display: block !important;
  }
}

.hideOnMobile {
  display: none !important;
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 40px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.mobileTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  height: auto;
}

.mobileTopButtons {
  display: none;
  @include gammaSize {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;

    .primaryFullWidthBtn {
      width: 100%;
    }
  }
}

.noPostsText {
  margin-top: 20px;

  @include gammaSize {
    margin-top: -20px;
  }
}
