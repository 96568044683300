@import '../../../../assets//styles/variables';
@import '../../../../assets//styles/mixns';

.preMiddleWhiteBox2 {
  background-color: $white;
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  height: 160px;
  background-repeat: no-repeat;
  overflow: hidden;

  @include gammaSize {
    height: 350px;
  }

  .cardContent {
    display: flex !important;
    flex-direction: row !important;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      margin-top: 10px;

      h5 {
        font-size: 18px;
      }

      @include gammaSize {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @include gammaSize {
      flex-direction: column !important;
    }

    .middle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      margin-top: -10px;
      gap: 10px;

      p {
        font-size: 16px;
      }

      @include gammaSize {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .musicBg {
      background-color: $color-sky-blue-medium;
      background-image: url('../../../../assets/images/music-bg.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-width: 200px;
      height: 160px;
    }
  }
}

.musicControl {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid $color-sky-blue-light;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin-left: 20px;

  .controlBtnPlay {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
  .controlBtnPause {
    width: 25px;
    height: 25px;
  }
}

.audioDuration {
  color: $text-dark-grey;
  font-size: 14px !important;
  margin-right: -20px;
  margin-left: 8px;
  width: 100px;
  padding-left: 10px;

  @include gammaSize {
    padding-top: 10px;
  }
}

.progressSec {
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.progressBar {
  width: 50%;
  height: 3px;
  background-color: #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.progressBarNone {
  width: 100%;
  height: 3px;
  background-color: transparent;
  border-radius: 5px;
  margin-top: 5px;
}

.progressFill {
  height: 100%;
  background-color: $color-sky-blue-light;
  border-radius: 5px;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  margin-top: -10px;

  

  .navigationBtn {
    background-color: transparent;
    border: none;
    padding: 5px;

    &:disabled {
      opacity: 0.3;
    }

    .navigationIcon {
      width: 25px;
      height: 25px;
    }
  }

  @include gammaSize {
    display: none;
  }
}

.navigationButtonsMobile {
  display: none;

  @include gammaSize {
    display: flex;
    justify-content: center;
    margin-top: -35px;
    margin-bottom: 10px;

    .navigationBtn {
      background-color: transparent;
      border: none;
      padding: 5px;

      &:disabled {
        opacity: 0.3;
      }

      .navigationIcon {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.musicBgLoading {
  min-width: 200px;
  min-height: 100%;
}

.loadCircle {
  margin-left: 10px !important;
  margin-top: 10px !important;
}
