@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.blogBannerLoading {
  height: 480px !important;

  @include gammaSize {
    height: 300px !important;
  }
}

.contentLoadingContainer {
  background-color: white !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 20px !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.02) !important;
  column-count: 1 !important;
  column-gap: 30px !important;
  line-height: 26px !important;
  margin-top: 40px !important;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.articleTitle {
  h1 {
    font-weight: 600;
  }
}
