@import '../../assets/styles/variables';
@import '../../assets/styles/mixns';

.hsIntroPageContainer {
  overflow: hidden;
  .hsIntroPageSubContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 60px;

    @include alphaSize {
      align-items: flex-start;
      margin: 20px;
    }

    @include betaSize {
      align-items: flex-start;
      margin: 10px;
    }

    @include mediaBetween(1023px, 763px) {
      margin: 10px;
    }

    .hsIntroCardContainer {
      max-width: 860px;
      width: 100%;
      padding: 52px 75px 75px 75px;
      border-radius: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include alphaSize {
        padding: 20px;
        width: 100%;
      }

      @include betaSize {
        padding: 20px;
        width: 100%;
      }

      &:after {
        content: url('../../assets/images/introduction-top-right.svg');
        position: absolute;
        top: -155px;
        right: -150px;
        z-index: -1;

        @include betaSize {
          content: none;
        }
      }

      &:before {
        content: url('../../assets/images/introduction-bottom-left.svg');
        position: absolute;
        top: 200px;
        left: -224px;
        z-index: -1;

        @include betaSize {
          content: none;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 40px;
        text-align: center;
        color: $text-dark-grey;
        padding: 0 29px;
        line-height: 48px;

        @include betaSize {
          font-size: 30px;
        }

        span {
          display: block;

          + {
            span {
              margin-top: 40px;
              @include betaSize {
                margin-top: 20px;
              }
            }
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        line-height: 24px;

        &:first-of-type {
          margin-top: 39px;
        }

        + {
          p {
            margin-top: 16px;
          }
        }

        span {
          color: $color-sky-blue-medium;
          cursor: pointer;
          font-weight: 600;

          &:hover {
            color: $color-sky-blue-light;
          }
        }
      }

      a {
        display: flex;
        width: 200px;
        justify-content: center;
        margin: 40px auto 0;
      }
    }
  }
}

.modalRoot {
  z-index: 999999 !important;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90%;
  max-width: 90%;
  min-height: 95%;
  max-height: 95%;
  overflow-y: auto;
  background-color: #fcfbf7;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 0px #00000040;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: 768px) {
    z-index: 999999;
    max-width: 100%;
    border-radius: 0px;
    min-width: 100%;
    overflow-x: hidden;
    max-height: 100%;
  }
}

.btnClose {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: $color-sky-blue-medium;
  padding-top: 10px;
  padding-right: 20px;
  margin-bottom: -2px;

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 10px;
  }
}

.btnCloseLabel {
  margin-right: 15px;
  color: $white;

  &:hover {
    font-weight: 600;
  }
}

.btnCloseIcon {
  font-size: 25px;
  color: $white;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    @include heading2;
    text-align: center;
  }
}

.loadingContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 40px !important;
  margin-top: 30px !important;
  margin-bottom: 100px !important;

  .loadingDesc {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    margin-top: -20px;
  }
}
