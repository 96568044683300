.checkboxContainer {
  display: flex;
}

.positionTop {
  flex-direction: column;
  gap: 5px;
}

.positionBottom {
  flex-direction: column-reverse;
  gap: 5px;
}

.positionRight {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.positionLeft {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
