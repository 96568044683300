.selectContainer {
  display: flex;

  label {
    width: 50%;
  }

  .selectBlock {
    width: 100%;
    position: relative;

    .tooltipContainer {
      display: flex;
      gap: 10px;
      position: absolute;
      right: 0px;
      top: -30px;
    }
  }

  .error {
    border-color: red;
  }

  .tooltipContainer {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0px;
  }
}

.positionTop {
  flex-direction: column;
  gap: 5px;
}

.positionLeft {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
