@import '../../../../assets/styles/mixns';
@import '../../../../assets/styles/variables';

.progressBarContainer {
  display: flex;
  flex-direction: row;
  gap: 3px;
  min-width: 100%;
  margin: -40px;
  margin-bottom: 10px;

  @include gammaSize {
    margin-top: -20px;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.progressBar {
  padding: 3px;
  height: 100%;
  flex: 1;
  border-radius: 5px;
}

.progressBar.active {
  background-color: $color-aqua-green-medium;
}

.progressBar.inactive {
  background-color: $color-putty-dark;
}

.categoryContainer {
  margin-top: 40px;

  @include gammaSize {
    margin-top: 40px;
  }

  h1 {
    font-weight: 600;
    color: $text-dark-grey;
    font-size: 32px;
    margin-bottom: -40px;

    @media only screen and (max-width: 380px) and (min-device-width: 100px) {
      margin-top: -30px;
    }

    @media only screen and (max-width: 767px) and (min-device-width: 381px) {
      margin-top: -20px;
    }

    @include gammaSize {
      text-align: center;
      margin-bottom: 0;
    }
  }
}

.sliderContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 120px;

  .sliderWrapper {
    width: 50%;

    @include gammaSize {
      width: 100%;
    }
  }
}

.hsQuestionsContainerSidebar {
  position: fixed !important;
  top: 74px;
  right: 0;
  height: calc(100vh - 150px);
  z-index: 9;
}

.hsQuestionsContainerSidebar {
  + {
    .hsQuestionsContainerSidebarOverlay {
      display: block;
      position: absolute;
      left: 0;
      top: 74px;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      -webkit-transition: width, left, right, 300ms;
      transition: width, left, right, 300ms;
    }
  }

  &.Collapsed {
    + {
      .hsQuestionsContainerSidebarOverlay {
        display: none;
      }
    }
  }
}
