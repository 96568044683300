@import '../../assets/styles/variables';

.hsColoredBox {
  padding: 34px 24px;
  border-radius: 50px;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      font-feature-settings: 'calt' off;
      color: $white;
      text-decoration: none;

      &:hover {
        color: $white;
      }
    }
  }

  &.green {
    background: $color-aqua-green-dark;
  }

  &.blue {
    background: $color-sky-blue-dark;
  }
}

.hsColoredBoxTitle {
  margin: 1rem 0;
}

.hsActionButton {
  color: #019df2;
  border: 3px solid #3be1ce;
  padding: 0 34px;
  background-color: white;
  cursor: pointer;
  border-radius: 24px;
  font-size: 18px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hsColoredBox {
    border-radius: unset;
  }
}
