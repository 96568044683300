@import '../../assets/styles/variables';

.hsModal {
  &Close {
    text-align: right;
  }

  &Content {
    width: 100%;
    padding: 40px 30px;

    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  &Background {
    .hsModalContent {
      background-image: url('../../assets/images/modal-bg.svg');
      background-repeat: no-repeat;
      background-position: bottom left;
    }
  }

  &Title {
    margin-top: 22px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: $text-dark-grey;

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  &Subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 54px;
    text-align: center;
    color: $text-dark-grey;
  }
}
