@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.button {
  background: $white;
  border: 2px solid $color-putty-dark;
  border-radius: 10px;
  min-height: 38px;
  align-items: center !important;

  &:disabled {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: 0;
  }

  .iconLeft {
    margin-right: 2px;
    width: 20px;
    height: 20px;

    @include betaSize {
      margin-right: -2px;
    }
  }

  .iconRight {
    margin-left: 2px;
    width: 20px;
    height: 20px;

    @include betaSize {
      margin-left: -2px;
    }
  }

  i {
    font-size: 25px;
  }

  &.hsPrimary,
  &.hsIconButton {
    background: $color-sky-blue-medium;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-medium;

    &:hover {
      background: $color-sky-blue-light;
      border: 2px solid $color-sky-blue-light;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsDarken {
    background-color: $color-sky-blue-dark;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $color-sky-blue-dark;

    &:hover {
      background: $color-sky-blue-medium;
      border: 2px solid $color-sky-blue-medium;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
    }

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsIconButton {
    align-items: center;
    gap: 11px;
    padding: 9px 24px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
  }

  &.hsSecondary,
  &.hsShowMoreButton {
    background: $white;
    border: 3px solid $color-sky-blue-medium;
    color: $color-sky-blue-medium;
    display: inline-flex;
    background: transparent;
    justify-content: center;
    border-radius: 24px;
    padding: 9px 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;

    &:hover {
      border-color: $color-sky-blue-light;
      color: $color-sky-blue-light;
    }

    &:disabled {
      border-color: $text-deactivated;
      color: $text-deactivated;
      opacity: 0.3;
    }

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsRed {
    background: $red;
    color: $white;
    padding: 9px 44px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $red;

    &:hover {
      background: $error-red;
      border: 2px solid $error-red;
    }

    &:disabled {
      background: $text-deactivated;
      opacity: 0.3;
      border: 2px solid $text-deactivated;
    }

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsShowMoreButton {
    align-items: center;
    gap: 11px;
    padding: 9px 10px 9px 20px;
    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsTertiary,
  &.hsSmall {
    color: $color-sky-blue-medium;

    &:hover {
      color: $color-sky-blue-light;
    }

    &:disabled {
      color: $text-deactivated;
    }
  }

  &.hsSmall {
    font-size: 16px;
    font-weight: 400;

    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;

    @include gammaSize {
      font-size: 16px;
    }
  }

  &.hsClose {
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    gap: 11px;
    color: $text-dark-grey;

    display: inline-flex;
    justify-content: center;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    line-height: 22px;
    text-decoration: none;

    @include gammaSize {
      font-size: 16px;
    }
  }
}

.error {
  border-color: red;
}
