/*
ColorNames
 */

$color-sky-blue-light: #3fb9fc;
$color-sky-blue-medium: #019df2;
$color-sky-blue-dark: #0072b0;
$color-sky-blue-darker: #004b74;

$color-calm-lilac-light: #d4c6f9;
$color-calm-lilac-medium: #beaaf3;
$color-calm-lilac-dark: #6048a1;

$color-sun-yellow-light: #ffe665;
$color-sun-yellow-medium: #fcd300;
$color-sun-yellow-dark: #d4b202;

$color-zingy-green-light: #bbf484;
$color-zingy-green-medium: #8eea36;
$color-zingy-green-dark: #4c8f0c;
$color-go-green: #04bc00;

$color-aqua-green-light: #3be1ce;
$color-aqua-green-medium: #06bf97;
$color-aqua-green-dark: #069b7b;

$color-warm-orange-light: #ffaf83;
$color-warm-orange-medium: #ff8a4a;
$color-warm-orange-dark: #d96525;

$color-turquoise-light: #4ff1de;
$color-turquoise-medium: #47dcc5;
$color-turquoise-dark: #2fc2ab;

$color-putty-light: #fcfbf7;
$color-putty-medium: #f4f2ec;
$color-putty-dark: #ece7dc;

$text-dark-grey: #505050;
$text-darker-grey: #667080;
$text-deactivated: #828282;
$text-dark-yellow: #6d580c;

$secondary-color: #344054;

$go-green: #04bc00;
$error-red: #dc0808;
$white: #ffffff;
$black: #000000;
$red: #ff0200;

// MEDIA QUERY BREAK POINTS
$alpha: 599px;
$beta: 767px;
$gamma: 1023px;
$delta: 1199px;

// FONTS PATH
$hs-font-path: '../../../assets/fonts/titiliumWebFonts' !default;
